import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Container, ProgressBar, Modal, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore, storage, firebaseApp as firebase } from '../utils/firebase';
import { getAuth } from 'firebase/auth';
import Select from 'react-select';
import { Timestamp } from "firebase/firestore";

const AddEquipmentTraining = () => {
    const [file, setFile] = useState(null);
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentNumber, setEquipmentNumber] = useState(null);
    const [date, setDate] = useState('');
    const [documentDescription, setDocumentDescription] = useState('');
    const [showModal, setShowModal] = useState(false); // New state variable for showing/hiding modal
    const [inputKey, setInputKey] = useState(Math.random().toString(36)); // New state variable for resetting file input
    const navigate = useNavigate();
    const location = useLocation();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const fileRef = React.useRef(null);




    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                    const company = userSnapshot.data().company;

                    const companyRef = firestore.collection('companies').doc(company);
                    const equipmentSnapshot = await companyRef.collection('equipment').get();

                    const equipments = equipmentSnapshot.docs.map((doc) => ({
                        value: doc.id, // Use 'doc.id' as the value
                        label: doc.data().equipmentNumber,
                    }));

                    setEquipmentList(equipments);
                }
            } catch (error) {
                console.error("Error fetching equipments:", error);
            }
        };

        fetchEquipments();
    }, []);


    const handleSelectChange = (selectedOption) => {
        setEquipmentNumber(selectedOption);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const companyName = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(companyName);

                const trainingDocumentsRef = companyRef.collection('trainingDocuments');

                const storageRef = storage.ref();
                const fileRef = storageRef.child(file.name);

                const uploadTask = fileRef.put(file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Set upload progress
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error("Upload Error:", error);
                    },
                    async () => {
                        const fileURL = await fileRef.getDownloadURL();

                        const documentRecord = {
                            documentDescription,
                            date: Timestamp.fromDate(new Date()), // use current date/time
                            equipmentNumber: equipmentNumber.value,
                            documentUrl: fileURL,
                            fileType: file.type.split('/')[1] === 'pdf' ? 'pdf' : 'video',
                            title: `${equipmentNumber.value}-${documentDescription}`  // <--- Added line
                        }

                        await trainingDocumentsRef.doc(`${equipmentNumber.value}-${documentDescription}`).set(documentRecord);
                        setShowModal(true); // Show the modal after a successful upload
                        setFile(null);
                        setEquipmentNumber(null);
                        setDocumentDescription('');
                        setUploadProgress(0);
                        setInputKey(Math.random().toString(36)); // Reset the file input

                        setIsLoading(false);  // Moved inside the async callback function
                    }
                );

            } catch (error) {
                console.error(error);
                alert('Failed to upload the document. Please try again.');
                setUploadProgress(0); // Reset upload progress in case of an error
                setIsLoading(false); // Stop the spinner in case of an error
            }
        }
    };

    const handleUploadAnother = () => {
        setShowModal(false); // Hide the modal
    };

    const handleBackToLessons = () => {
        navigate('/trainingmaterials'); // Replace this with the actual path
        setShowModal(false); // Hide the modal
    };


    return(

        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>  
            <h1 className="mb-5">Add Equipment Training Material</h1>

            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Select
                            value={equipmentNumber}
                            onChange={(selectedOption) => setEquipmentNumber(selectedOption)}
                            options={equipmentList}
                            isSearchable
                            required
                        />
                    </Col>
                </Row>
                <Form.Group className="mb-3" controlId="documentDescription">
                    <Form.Label>Document Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={documentDescription}
                        onChange={(e) => setDocumentDescription(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="file">
                    <Form.Label>Upload Document</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        required
                    />
                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                    )}
                    {isLoading && <Spinner animation="border" />}

                </Form.Group>
                <Button variant="success" className="mt-3" type="submit">Submit</Button>
                <Button variant="outline-success" className="mt-3" onClick={() => navigate('/trainingmaterials')} style={{ marginLeft: '10px' }}>
                    Back
                </Button>
            </Form>
            <Modal show={showModal} onHide={handleUploadAnother}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Successful!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your document was successfully uploaded!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUploadAnother}>
                        Upload Another
                    </Button>
                    <Button variant="success" onClick={handleBackToLessons}>
                        Back to Lessons
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AddEquipmentTraining;
