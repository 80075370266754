import React, { useState, useEffect } from 'react';
import { firestore, storage } from '../utils/firebase';
import { Button, Table, Container, Spinner, Modal, Form, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore'; // import Timestamp from firebase


const RejectedMaintenance = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const { role, hasRole } = useRole();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [comment, setComment] = useState(''); // Add this line to declare a state variable for the comment
    const [editModalInfo, setEditModalInfo] = useState({ show: false, record: null });
    const [loadingId, setLoadingId] = useState(null);
    const [isResubmitting, setIsResubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState({ show: false, recordId: null });
    const [loadingResubmitId, setLoadingResubmitId] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downtime, setDowntime] = useState({ quantity: '', unit: 'days' });
    const [maintenanceDuration, setMaintenanceDuration] = useState({ quantity: '', unit: 'days' });



    useEffect(() => {
        if (!currentUser) {
            console.log("No user logged in, redirecting to login page...");
            navigate('/login');
        } else {
            console.log(`Logged in user: ${currentUser.email}`);
            fetchRejectedRecords();
            fetchUserDetails();
        }
    }, []);

    const fetchUserDetails = async () => {
        try {
            if (currentUser) {
                const userRef = firestore.collection('users').doc(currentUser.uid);

                userRef.get().then((doc) => {
                    if (doc.exists) {
                        const userCompany = doc.data().company;
                        console.log(`Logged in user: ${currentUser.email}, Company ID: ${userCompany}`);
                        console.log('User data:', doc.data());
                        console.log('Updating role:', doc.data().role);
                        currentUser.company = userCompany; // Add this line to store the company ID in the currentUser object
                    } else {
                        console.log('No such document!');
                    }
                });
            }
        } catch (error) {
            console.log("Fetch User Details Error:", error.message);
        }
    };

    const fetchRejectedRecords = async () => {
        setLoading(true); // Add this line
        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;
                const companyRef = firestore.collection('companies').doc(company);
                const maintenanceLogsRef = companyRef.collection('maintenanceLogs');
                const snapshot = await maintenanceLogsRef
                    .where('rejected', '==', true)
                    .get();
                const fetchedRecords = snapshot.docs.map(doc => {
                    let record = doc.data();
                    if (record.plannedDate?.toDate) {
                        record.plannedDate = record.plannedDate.toDate().toLocaleDateString();
                    }
                    return { ...record, id: doc.id };
                });
                setRecords(fetchedRecords);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false); // Add this line
    };


    const openEditModal = (record) => {
        setEditModalInfo({ show: true, record });
    };

    const closeEditModal = () => {
        setEditModalInfo({ show: false, record: null });
    };

    const handleEdit = async () => {
        if (editModalInfo.record) {
            setIsEditing(true);

            const companyRef = firestore.collection('companies').doc(currentUser.company);
            const recordRef = companyRef.collection('maintenanceLogs').doc(editModalInfo.record.id);

            const equipmentNumber = editModalInfo.record.equipmentNumber;
            const activity = editModalInfo.record.activity;
            const date = editModalInfo.record.loggedDate; // Assuming you want to use loggedDate as the date

            const storageRef = storage.ref();
            const fileRef = storageRef.child(`${equipmentNumber}-${activity}-${date}`);
            const uploadTask = fileRef.put(file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadProgress(progress);
                },
                (error) => console.error("Upload Error:", error),
                async () => {
                    const proofUrl = await fileRef.getDownloadURL();
                    let newDate = new Date(editModalInfo.record.loggedDate);
                    let timestamp = Timestamp.fromDate(newDate);

                    await recordRef.update({
                        date: timestamp,
                        vendor: editModalInfo.record.vendor,
                        rejected: false,
                        proofUrl, // Adding the URL for the uploaded document
                        downtime: {
                            quantity: downtime.quantity,
                            unit: downtime.unit,
                        },
                        maintenanceDuration: {
                            quantity: maintenanceDuration.quantity,
                            unit: maintenanceDuration.unit,
                        },
                        // Add other fields to update here...
                    });

                    setIsEditing(false);
                    closeEditModal();
                    fetchRejectedRecords();
                }
            );
        }
    };


    const resubmitRecord = async (id, comment) => {
        setIsResubmitting(true);  // Start loading when resubmitting starts
        setLoadingResubmitId(id);  // Set the loadingResubmitId when starting the resubmit operation
        try {
            if (currentUser) {
                console.log(`Resubmitting record ${id}`);
                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('maintenanceLogs').doc(id);
                await recordRef.update({
                    rejected: false,
                    comment,
                });
                fetchRejectedRecords();
                setModalInfo({ show: false, recordId: null }); // Close the modal after successful resubmission
            }
        } catch (error) {
            console.error("Resubmit Record Error:", error.message);
        } finally {
            setLoadingResubmitId(null);  // Clear the loadingResubmitId when the resubmit operation is complete
            setIsResubmitting(false);  // Stop loading when resubmitting finishes
        }
    };

    const handleResubmit = (id, comment) => {
        resubmitRecord(id, comment);
        setModalInfo({ show: false, recordId: null });
    };


    const deleteRecord = async (id) => {
        try {
            if (currentUser) {
                setLoadingId(id);  // Set the loadingId when starting the delete operation
                console.log(`Deleting record ${id}`);
                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('maintenanceLogs').doc(id);
                await recordRef.delete();
                fetchRejectedRecords();
            }
        } catch (error) {
            console.error("Delete Record Error:", error.message);
        } finally {
            setLoadingId(null);  // Clear the loadingId when the delete operation is complete
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">Rejected Maintenance</h1>
            <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
                <Table striped bordered hover className="my-3">
                    <thead>
                        <tr>
                            <th>Equipment Number</th>
                            <th>Date</th>
                            <th>Maintenance Type</th>
                            <th>Activity</th>
                            <th>Downtime</th>
                            <th>Maintenance Duration</th>
                            <th>Vendor/Contractor</th>
                            <th>Comment</th>
                            <th>Edit</th>
                            <th>Resubmit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.equipmentNumber}</td>
                                    <td>{record.date ? record.date.toDate().toLocaleDateString() : 'N/A'}</td>
                                    <td>{record.maintenanceType}</td>
                                    <td>{record.activity}</td>
                                    <td>{record.vendor}</td>
                                    <td>{record.downtime ? `${record.downtime.quantity} ${record.downtime.unit}` : 'N/A'}</td>
                                    <td>{record.maintenanceDuration ? `${record.maintenanceDuration.quantity} ${record.maintenanceDuration.unit}` : 'N/A'}</td>
                                    <td>{record.comment}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => openEditModal(record)}>
                                            Edit
                                        </Button>
                                    </td>

                                    <td>
                                        <Button variant="success" onClick={() => setModalInfo({ show: true, recordId: record.id })}>
                                            Resubmit
                                        </Button>
                                    </td>
                                    <td>
                                        <Button variant="danger" onClick={() => deleteRecord(record.id)} disabled={loadingId === record.id}>
                                            {loadingId === record.id ? <Spinner animation="border" size="sm" /> : 'Delete'}
                                        </Button>
                                    </td>



                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={11}>No Rejected Records</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')}>
                Back to Maintenance
            </Button>


            <Modal show={editModalInfo.show} onHide={closeEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="editLoggedDate">
                        <Form.Label>Logged Date (Date must be edited)</Form.Label>
                        <Form.Control
                            type="date"
                            defaultValue={editModalInfo.record?.loggedDate}
                            onChange={(e) =>
                                setEditModalInfo((prev) => ({
                                    ...prev,
                                    record: { ...prev.record, loggedDate: e.target.value },
                                }))
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="editVendor">
                        <Form.Label>Vendor/Contractor</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={editModalInfo.record?.vendor}
                            onChange={(e) =>
                                setEditModalInfo((prev) => ({
                                    ...prev,
                                    record: { ...prev.record, vendor: e.target.value },
                                }))
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="downtime" className="my-3">
                        <Form.Label>Downtime</Form.Label>
                        <div style={{ display: 'flex' }}>
                            <Form.Control
                                type="number"
                                min="0"
                                step="1"
                                style={{ marginRight: '10px' }}
                                value={downtime.quantity}
                                onChange={e => setDowntime({ ...downtime, quantity: e.target.value })}
                                required
                            />
                            <Form.Control
                                as="select"
                                value={downtime.unit}
                                onChange={e => setDowntime({ ...downtime, unit: e.target.value })}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="hours">Hours</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="maintenanceDuration" className="my-3">
                        <Form.Label>Duration of Maintenance</Form.Label>
                        <div style={{ display: 'flex' }}>
                            <Form.Control
                                type="number"
                                min="0"
                                step="1"
                                style={{ marginRight: '10px' }}
                                value={maintenanceDuration.quantity}
                                onChange={e => setMaintenanceDuration({ ...maintenanceDuration, quantity: e.target.value })}
                                required
                            />
                            <Form.Control
                                as="select"
                                value={maintenanceDuration.unit}
                                onChange={e => setMaintenanceDuration({ ...maintenanceDuration, unit: e.target.value })}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="hours">Hours</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="editSupportingFile">
                        <Form.Label>Upload Supporting Document</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="fileUploadProgress">
                        <Form.Label>File Upload Progress</Form.Label>
                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEdit} disabled={isEditing}>
                        {isEditing ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalInfo.show} onHide={() => setModalInfo({ show: false, recordId: null })}>
                <Modal.Header closeButton>
                    <Modal.Title>Resubmit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="resubmitComment">
                        <Form.Label>Resubmit Comment</Form.Label>
                        <Form.Control type="text" placeholder="Enter a comment..." onChange={(e) => setComment(e.target.value)} required />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => resubmitRecord(modalInfo.recordId, comment)} disabled={loadingResubmitId === modalInfo.recordId}>
                        {loadingResubmitId === modalInfo.recordId ? <Spinner animation="border" size="sm" /> : 'Resubmit'}
                    </Button>
                </Modal.Footer>

            </Modal>
        </Container>
    );

};

export default RejectedMaintenance;
