import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Button, Form, Container, Spinner, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import logoImage from '../../Nucleus Logo.svg';
import ReactGA4 from 'react-ga4';

const logoStyle = {
    maxWidth: '100px', // Adjust the size as needed
    margin: '0',
    padding: '0',
};


const SignIn = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA4.initialize('G-FGPF61TG81');
        ReactGA4.send('page_view'); // Record the initial pageview
    }, []);

    useEffect(() => {
        // Combine pathname and search into a single string
        const pagePath = `${location.pathname}${location.search}`;
        ReactGA4.send({ hitType: 'page_view', page_path: pagePath });
    }, [location.pathname, location.search]);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const signInWithEmail = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setTimeout(() => {
                setIsLoading(false);
                navigate("/dashboard");
            }, 3000);
            ReactGA4.event({
                category: 'User',
                action: 'Sign In',
            });
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message.replace('Firebase:', ''));
            setShowErrorModal(true);
        }
    }

    const handleErrorModalClose = () => setShowErrorModal(false);

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-4">Sign In</h1>
            <Form onSubmit={signInWithEmail}>
                <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" required onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" required onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Button variant="success" className="w-100 mt-3" type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Sign In'}
                </Button>
                <Button variant="outline-success" className="w-100 mt-3" onClick={() => navigate("/")}>
                    Back to Homepage
                </Button>
            </Form>
            <Modal show={showErrorModal} onHide={handleErrorModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 text-center">Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleErrorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default SignIn;
