import React, { useState, useEffect } from 'react';
import { firestore } from '..//utils/firebase';   // Assuming you exported firestore from firebase.js
import { Button, Table, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '..//hooks/useRole';

const ApproveRecords = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const role = useRole();

    const fetchUnapprovedRecords = async () => {
        try {
            // fetch unapproved maintenance records
            const maintenanceSnapshot = await firestore.collection('maintenanceRecords').where('approved', '==', false).get();
            const maintenanceRecords = maintenanceSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'maintenance' }));

            // fetch unapproved fault records
            const faultSnapshot = await firestore.collection('faultRecords').where('approved', '==', false).get();
            const faultRecords = faultSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, type: 'fault' }));

            setRecords([...maintenanceRecords, ...faultRecords]);
        } catch (error) {
            console.error(error);
        }
    };

    const approveRecord = async (id, type) => {
        try {
            await firestore.collection(type + 'Records').doc(id).update({
                approved: true
            });
            fetchUnapprovedRecords();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUnapprovedRecords();
    }, []);

    return (
        <Container>
            <Table striped bordered hover className="mt-3">

                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Equipment Number</th>
                        <th>Fault</th>
                        <th>Solution</th>
                        {role === 'Admin' && <th>Approve</th>}
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={index}>
                            <td>{record.type}</td>
                            <td>{record.equipmentNumber}</td>
                            <td>{record.fault}</td>
                            <td>{record.solution}</td>
                            {role === 'Admin' && (
                                <td><Button variant="success" onClick={() => approveRecord(record.id, record.type)}>Approve</Button></td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button variant="outline-success" onClick={() => navigate('/dashboard')}>
                Back to Dashboard
            </Button>
        </Container>
    );
};

export default ApproveRecords;
