import React, { useState, useEffect, useRef } from 'react';
import { firestore, storage } from '../utils/firebase';
import { Button, Table, Container, Spinner, Modal, Form, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";
import { Document, Page, pdfjs } from 'react-pdf';
import { Timestamp } from 'firebase/firestore'; // import Timestamp from firebase
import './ConfirmMaint.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ConfirmMaintenance = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const { role, hasRole } = useRole();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectRecordId, setRejectRecordId] = useState(null);
    const [approvingId, setApprovingId] = useState(null);
    const [approvingAllId, setApprovingAllId] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(0);
    const modalBodyRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [originalAspectRatio, setOriginalAspectRatio] = useState(0);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [noAttachmentRecordId, setNoAttachmentRecordId] = useState(null);
    const [showNoAttachmentModal, setShowNoAttachmentModal] = useState(false);
    const [recordHasDocument, setRecordHasDocument] = useState({});
    const [showCantApproveModal, setShowCantApproveModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [modalInfo, setModalInfo] = useState({ show: false, recordId: null });
    const [loadingResubmitId, setLoadingResubmitId] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downtime, setDowntime] = useState({ quantity: '', unit: 'days' });
    const [maintenanceDuration, setMaintenanceDuration] = useState({ quantity: '', unit: 'days' });
    const [editModalInfo, setEditModalInfo] = useState({ show: false, record: null });
    const [rescheduleModalInfo, setRescheduleModalInfo] = useState({ show: false, record: null, newDate: '' });

    const fetchDocumentUrl = async (equipmentNumber, activity, date, shouldSetState = false) => {
        const storage = getStorage();
        const dateObj = date.toDate();
        const dateString = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
        const fileName = (`${equipmentNumber}-${activity}-${dateString}`);
        console.log("Fetching file with name:", fileName); // Log the file name

        const storageRef = ref(storage, fileName);

        try {
            const url = await getDownloadURL(storageRef);
            const metadata = await getMetadata(storageRef);
            if (shouldSetState) {
                setSelectedDocumentUrl({ url, mimeType: metadata.contentType });
            }
            return true;  // Document found
        } catch (error) {
            console.error("Error fetching document URL:", error);
            if (shouldSetState) {
                setShowNoAttachmentModal(true);
                setNoAttachmentRecordId({ equipmentNumber, activity, date });
            }
            return false;  // Document not found
        }
    };

    const closeEditModal = () => {
        setEditModalInfo({ show: false, record: null });
    };

    const closeRescheduleModal = () => {
        setRescheduleModalInfo({ show: false, record: null, newDate: '' });
    };

    const handleEdit = async () => {
        if (editModalInfo.record) {
            setIsEditing(true);

            const companyRef = firestore.collection('companies').doc(currentUser.company);
            const recordRef = companyRef.collection('maintenanceLogs').doc(editModalInfo.record.id);

            const equipmentNumber = editModalInfo.record.equipmentNumber;
            const activity = editModalInfo.record.activity;
            const date = editModalInfo.record.loggedDate; // Assuming you want to use loggedDate as the date

            const storageRef = storage.ref();
            const fileRef = storageRef.child(`${equipmentNumber}-${activity}-${date}`);
            const uploadTask = fileRef.put(file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadProgress(progress);
                },
                (error) => console.error("Upload Error:", error),
                async () => {
                    const proofUrl = await fileRef.getDownloadURL();
                    let newDate = new Date(editModalInfo.record.loggedDate);
                    let timestamp = Timestamp.fromDate(newDate);

                    await recordRef.update({
                        date: timestamp,
                        vendor: editModalInfo.record.vendor,
                        rejected: false,
                        proofUrl, // Adding the URL for the uploaded document
                        downtime: {
                            quantity: downtime.quantity,
                            unit: downtime.unit,
                        },
                        maintenanceDuration: {
                            quantity: maintenanceDuration.quantity,
                            unit: maintenanceDuration.unit,
                        },
                        Confirmed: true, // Set Confirmed to true
                        // Add other fields to update here...
                    });

                    setIsEditing(false);
                    closeEditModal();
                    fetchConfirmRecords();
                }
            );
        }
    };

    const handleReschedule = async () => {
        if (rescheduleModalInfo.record) {
            const companyRef = firestore.collection('companies').doc(currentUser.company);
            const recordRef = companyRef.collection('maintenanceLogs').doc(rescheduleModalInfo.record.id);
            let newDate = new Date(rescheduleModalInfo.newDate);
            let timestamp = Timestamp.fromDate(newDate);

            await recordRef.update({
                date: timestamp,
            });

            closeRescheduleModal();
            fetchConfirmRecords();
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setPdfWidth(modalBodyRef.current.offsetWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!currentUser) {
            console.log("No user logged in, redirecting to login page...");
            navigate('/login');
        } else {
            console.log(`Logged in user: ${currentUser.email}`);
            fetchConfirmRecords();
            fetchUserDetails();
        }
    }, []);

    const fetchUserDetails = async () => {
        try {
            if (currentUser) {
                const userRef = firestore.collection('users').doc(currentUser.uid);
                const doc = await userRef.get();

                if (doc.exists) {
                    const userCompany = doc.data().company;
                    console.log(`Logged in user: ${currentUser.email}, Company ID: ${userCompany}`);
                    console.log('User data:', doc.data());
                    console.log('Updating role:', doc.data().role);
                    currentUser.company = userCompany;
                } else {
                    console.log('No such document!');
                }
            }
        } catch (error) {
            console.log("Fetch User Details Error:", error.message);
        }
    };

    const fetchConfirmRecords = async () => {
        setLoading(true);

        try {
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(company);
                const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

                const snapshotOne = await maintenanceLogsRef
                    .where('approved', '==', false)
                    .where('scheduled', '==', true)
                    .where('fullyApproved', '==', false)
                    .where('logmaintenance', '==', true)
                    .where('rejected', '==', false)
                    .where('Confirmed', '==', false)
                    .where('ScheduledApproved', '==', true)
                    .get();

                const snapshotTwo = await maintenanceLogsRef
                    .where('approved', '==', false)
                    .where('scheduled', '==', true)
                    .where('fullyApproved', '==', false)
                    .where('logmaintenance', '==', true)
                    .where('ScheduledApproved', '==', true)
                    .where('rejected', '==', false)
                    .where('Confirmed', '==', false)
                    .get();

                const fetchedRecordsOne = snapshotOne.docs.map(doc => {
                    let record = doc.data();
                    if (record.plannedDate?.toDate) {
                        record.plannedDate = record.plannedDate.toDate().toLocaleDateString();
                    }
                    return { ...record, id: doc.id, equipmentNumber: record.equipmentNumber, activity: record.activity, date: record.date };
                });

                const fetchedRecordsTwo = snapshotTwo.docs.map(doc => {
                    let record = doc.data();
                    if (record.date?.toDate) {
                        const recordDate = record.date.toDate();
                        const now = new Date();
                        now.setHours(0, 0, 0, 0);
                        if (recordDate < now) {
                            return { ...record, id: doc.id, equipmentNumber: record.equipmentNumber, activity: record.activity, date: record.date };
                        }
                    }
                }).filter(Boolean);

                const allFetchedRecords = [...fetchedRecordsOne];
                setRecords(allFetchedRecords);  // Set the records state once

                const newRecordHasDocument = {};
                for (let record of allFetchedRecords) {
                    const hasDoc = await fetchDocumentUrl(record.equipmentNumber, record.activity, record.date, false); // shouldSetState is false

                    newRecordHasDocument[record.id] = hasDoc;
                }
                setRecordHasDocument(newRecordHasDocument);
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };

    const openEditModal = (record) => {
        setEditModalInfo({ show: true, record });
    };

    const openRescheduleModal = (record) => {
        setRescheduleModalInfo({ show: true, record, newDate: '' });
    };

    const approveRecord = async (id) => {
        setApprovingId(id);

        try {
            if (currentUser) {
                console.log(`Approving record ${id}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('maintenanceLogs').doc(id);

                await recordRef.update({
                    approved: true,
                    fullyApproved: true,
                });

                fetchConfirmRecords();
            }
        } catch (error) {
            console.error("Approve Record Error:", error.message);
        } finally {
            setApprovingId(null);
        }
    };

    const approveAllForEquipment = async (equipmentNumber) => {
        setApprovingAllId(equipmentNumber);

        try {
            if (currentUser) {
                console.log(`Approving all records for equipment ${equipmentNumber} for user: ${currentUser.email}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordsRef = companyRef.collection('maintenanceLogs');

                const snapshot = await recordsRef
                    .where('equipmentNumber', '==', equipmentNumber)
                    .get();

                const snapshotScheduled = await recordsRef
                    .where('equipmentNumber', '==', equipmentNumber)
                    .where('approved', '==', false)
                    .where('plannedDate', '<=', new Date())
                    .get();

                console.log(snapshot.docs.length, snapshotScheduled.docs.length);

                const batch = firestore.batch();
                const docs = [...snapshot.docs, ...snapshotScheduled.docs];
                console.log(docs.map(doc => doc.id));

                docs.forEach((doc) => {
                    const docRef = recordsRef.doc(doc.id);
                    batch.update(docRef, { approved: true, scheduled: false, fullyApproved: true });
                });

                await batch.commit().catch(console.error);

                fetchConfirmRecords();
            }
        } catch (error) {
            console.error("Approve All For Equipment Error:", error.message);
        } finally {
            setApprovingAllId(null);
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">Confirm Maintenance</h1>
            <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
                <Table striped bordered hover className="my-3">
                    <thead>
                        <tr>
                            <th>Equipment Number</th>
                            <th>Date</th>
                            <th>Maintenance Type</th>
                            <th>Activity</th>
                            <th>Downtime</th>
                            <th>Maintenance Duration</th>
                            <th>Vendor/Contractor</th>
                            <th>Confirm</th>
                            <th>Reschedule</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.equipmentNumber}</td>
                                    <td>{record.date ? record.date.toDate().toLocaleDateString() : 'N/A'}</td>
                                    <td>{record.maintenanceType}</td>
                                    <td>{record.activity}</td>
                                    <td>{record.downtime ? `${record.downtime.quantity} ${record.downtime.unit}` : 'N/A'}</td>
                                    <td>{record.maintenanceDuration ? `${record.maintenanceDuration.quantity} ${record.maintenanceDuration.unit}` : 'N/A'}</td>
                                    <td>{record.vendor}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => openEditModal(record)}>
                                            Confirm
                                        </Button>
                                    </td>
                                    <td>
                                        <Button variant="info" onClick={() => openRescheduleModal(record)}>
                                            Reschedule
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9}>No unapproved records</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')}>
                Back to Maintenance
            </Button>

            <Modal show={showNoAttachmentModal} onHide={() => setShowNoAttachmentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>No Attachment Found</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    No supporting file found for this entry.
                </Modal.Body>
            </Modal>

            <Modal show={showCantApproveModal} onHide={() => setShowCantApproveModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approval Not Possible</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You cannot approve a record without a supporting document.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCantApproveModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={selectedDocumentUrl !== null} onHide={() => setSelectedDocumentUrl(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Maintenance Record Document</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={modalBodyRef} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {selectedDocumentUrl && (
                        selectedDocumentUrl.mimeType === 'application/pdf' ? (
                            <Document
                                file={selectedDocumentUrl.url}
                                onLoadSuccess={({ numPages, originalWidth, originalHeight }) => {
                                    setNumPages(numPages);
                                    setOriginalAspectRatio(originalWidth / originalHeight);
                                }}
                            >
                                {numPages && Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={pdfWidth}
                                        height={pdfWidth / originalAspectRatio}
                                    />
                                ))}
                            </Document>
                        ) : (
                            <img src={selectedDocumentUrl.url} alt="Maintenance record" style={{ width: '100%' }} />
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setSelectedDocumentUrl(null); setNumPages(null); }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editModalInfo.show} onHide={closeEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="editLoggedDate">
                        <Form.Label>Logged Date (Date must be edited)</Form.Label>
                        <Form.Control
                            type="date"
                            defaultValue={editModalInfo.record?.loggedDate}
                            onChange={(e) =>
                                setEditModalInfo((prev) => ({
                                    ...prev,
                                    record: { ...prev.record, loggedDate: e.target.value },
                                }))
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="editVendor">
                        <Form.Label>Vendor/Contractor</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue={editModalInfo.record?.vendor}
                            onChange={(e) =>
                                setEditModalInfo((prev) => ({
                                    ...prev,
                                    record: { ...prev.record, vendor: e.target.value },
                                }))
                            }
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="downtime" className="my-3">
                        <Form.Label>Downtime</Form.Label>
                        <div style={{ display: 'flex' }}>
                            <Form.Control
                                type="number"
                                min="0"
                                step="1"
                                style={{ marginRight: '10px' }}
                                value={downtime.quantity}
                                onChange={e => setDowntime({ ...downtime, quantity: e.target.value })}
                                required
                            />
                            <Form.Control
                                as="select"
                                value={downtime.unit}
                                onChange={e => setDowntime({ ...downtime, unit: e.target.value })}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="hours">Hours</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="maintenanceDuration" className="my-3">
                        <Form.Label>Duration of Maintenance</Form.Label>
                        <div style={{ display: 'flex' }}>
                            <Form.Control
                                type="number"
                                min="0"
                                step="1"
                                style={{ marginRight: '10px' }}
                                value={maintenanceDuration.quantity}
                                onChange={e => setMaintenanceDuration({ ...maintenanceDuration, quantity: e.target.value })}
                                required
                            />
                            <Form.Control
                                as="select"
                                value={maintenanceDuration.unit}
                                onChange={e => setMaintenanceDuration({ ...maintenanceDuration, unit: e.target.value })}
                                required
                            >
                                <option value="days">Days</option>
                                <option value="hours">Hours</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="editSupportingFile">
                        <Form.Label>Upload Supporting Document</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="fileUploadProgress">
                        <Form.Label>File Upload Progress</Form.Label>
                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEdit} disabled={isEditing}>
                        {isEditing ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={rescheduleModalInfo.show} onHide={closeRescheduleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reschedule Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="rescheduleDate">
                        <Form.Label>New Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={rescheduleModalInfo.newDate}
                            onChange={(e) =>
                                setRescheduleModalInfo((prev) => ({
                                    ...prev,
                                    newDate: e.target.value,
                                }))
                            }
                            required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleReschedule}>
                        Reschedule
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ConfirmMaintenance;
