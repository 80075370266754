import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Spinner } from 'react-bootstrap';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import useRole from '..//hooks/useRole';


function reports() {

    
    return (
        <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "40vh" }}>
            <h1 className="mb-4">Reports</h1>

            <div className="w-100 text-center mt-2">
                <Link to="/maintenance-reports">
                    <Button variant="outline-success" className="m-2">Maintenance report</Button>
                </Link>
                <Link to="/lessonslearnt-report">
                    <Button variant="outline-success" className="m-2">Lessons learnt report</Button>
                </Link>
                {/*<Link to="/usagereports">
                    <Button variant="outline-success" className="m-2">Company Usage report</Button>
                </Link>*/}
                <Link to="/dashboard">
                    <Button variant="success" className="m-2">Back to Dashboard</Button>
                </Link>


            </div>
        </Container>
    );
}

export default reports;
