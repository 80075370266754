// SearchEquipment.js
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { firestore } from '../utils/firebase';  // Assuming you exported firestore from firebase.js

const SearchEquipment = () => {
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [equipment, setEquipment] = useState(null);

    const handleSearch = async () => {
        try {
            const snapshot = await firestore.collection('equipment').where('equipmentNumber', '==', equipmentNumber).get();
            const foundEquipment = snapshot.docs.map(doc => doc.data());
            setEquipment(foundEquipment[0] || null);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Form inline>
            <Form.Control type="text" placeholder="Search" className="mr-sm-2" value={equipmentNumber} onChange={e => setEquipmentNumber(e.target.value)} />
            <Button variant="outline-success" onClick={handleSearch}>Search</Button>
            {equipment && <div>{equipment.equipmentType}</div>}
        </Form>
    );
};

export default SearchEquipment;
