import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Modal, Spinner } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import useRole from '../hooks/useRole';
import logoImage from '../../Nucleus Logo.svg';

const AddEquipment = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };

    const [userData, setUserData] = useState(null);
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [equipmentType, setEquipmentType] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal state
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { role, hasRole, loading } = useRole();


    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userDoc = doc(firestore, 'users', currentUser.uid);
                const userDataSnapshot = await getDoc(userDoc);
                if (userDataSnapshot.exists()) {
                    setUserData(userDataSnapshot.data());
                }
            }
        };
        fetchUserData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userData) {
            setIsSubmitting(true);  // set isSubmitting to true before starting async operation
            const companyId = userData.company;
            const equipment = {
                equipmentNumber,
                serialNumber,
                equipmentType,
            };

            try {
                await firestore
                    .collection('companies')
                    .doc(companyId)
                    .collection('equipment')
                    .doc(equipmentNumber)
                    .set(equipment);

                setEquipmentNumber('');
                setSerialNumber('');
                setEquipmentType('');
                setShowModal(true); // Show the success modal
            } catch (error) {
                console.error('Error writing document: ', error);
            } finally {
                setIsSubmitting(false); // set isSubmitting to false after async operation
            }
        }
    };


    const handleModalClose = () => {
        setShowModal(false);
        navigate('/dashboard'); // Redirect to Dashboard when the modal is closed
    };

    const resetForm = () => {
        setEquipmentNumber('');
        setSerialNumber('');
        setEquipmentType('');
        setShowModal(false); // Close the modal
    };

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }  

    return (

        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-5 ">Add Equipment</h1>
            <Form onSubmit={(event) => {
                if (!isSubmitting) {
                    handleSubmit(event);
                }
            }}>
                <Form.Group controlId="equipmentNumber" className="my-3">
                    <Form.Label>Equipment Number</Form.Label>
                    <Form.Control
                        type="text"
                        value={equipmentNumber}
                        onChange={(e) => setEquipmentNumber(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="serialNumber" className="my-3">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control
                        type="text"
                        value={serialNumber}
                        onChange={(e) => setSerialNumber(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="equipmentType" className="my-3">
                    <Form.Label>Equipment Type</Form.Label>
                    <Form.Control
                        type="text"
                        value={equipmentType}
                        onChange={(e) => setEquipmentType(e.target.value)}
                        required
                    />
                </Form.Group>

                <div className="my-3">
                    <Button variant="success" type="submit" style={{  width: '175px' }} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <Spinner as= "span" animation="border" size="sm" role="status" aria-hidden="true" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            'Add Equipment'
                        )}
                    </Button>
                    <Button
                        variant="outline-success"
                        onClick={() => navigate('/dashboard')}
                        style={{ margin: '15px', width:'175px' }}
                    >
                        Back
                    </Button>
                </div>
            </Form>

            {/* Success Modal */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Equipment added successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={resetForm}>
                        Add more Equipment
                    </Button>
                    <Button variant="success" onClick={() => navigate('/dashboard')}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AddEquipment;
