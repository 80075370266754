// LogFaultRecord.js
import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Modal, Spinner } from 'react-bootstrap';
import { firestore, firebase } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Select from 'react-select';
import { components } from 'react-select';
import { serverTimestamp } from 'firebase/firestore'; // Import serverTimestamp



const LogFaultRecord = () => {
    const [equipmentNumber, setEquipmentNumber] = useState(null);
    const [equipmentList, setEquipmentList] = useState([]);
    const [fault, setFault] = useState('');
    const [solution, setSolution] = useState('');
    const [vendor, setVendor] = useState('');
    const [vendorContactNumber, setVendorContactNumber] = useState('');
    const [vendorContactEmail, setVendorContactEmail] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lessonType, setLessonType] = useState(null);
    const [vendorEmailError, setVendorEmailError] = useState('');
    const [lessonLearnedType, setLessonLearnedType] = useState('equipment');
    const [TaskDescription, setTaskDescription] = useState('');

    const lessonTypes = [
        { value: 'Usage Procedures', label: 'Usage Procedures', description: 'Standard operating procedures and protocols' },
        { value: 'Performance Optimization', label: 'Performance Optimization', description: 'Optimal settings and calibration' },
        { value: 'Maintenance and Preventative Care', label: 'Maintenance and Preventative Care', description: 'Routine maintenance practices' },
        { value: 'Energy Efficiency', label: 'Energy Efficiency', description: 'Consumption patterns and energy-saving techniques' },
        { value: 'Error Handling and Troubleshooting', label: 'Error Handling and Troubleshooting', description: 'Common error codes and guides' },
        { value: 'Human-Machine Interaction', label: 'Human-Machine Interaction', description: 'Ergonomic practices and usability' },
        { value: 'Quality Control', label: 'Quality Control', description:'Quality standards compliance and improvement' },
        { value: 'Environmental Impact', label: 'Environmental Impact' , description: 'Waste reduction and eco-friendly operation' },
        { value: 'Disaster Recovery and Emergency Procedures', label: 'Disaster Recovery and Emergency Procedures' , description: 'Emergency protocols and recovery plans' },
        { value: 'Innovation and Adaptation', label: 'Innovation and Adaptation', description: 'Adaptation to new tasks and integration of new technologies' },
    ];

    const lessonLearnedOptions = [
        { value: 'equipment', label: 'Equipment Lesson Learned' },
        { value: 'task', label: 'Task Lesson Learned' }
    ];


    const CustomOption = (props) => {
        return (
            <div
                onMouseOver={() => props.onMouseOver && props.onMouseOver()}
                onMouseOut={() => props.onMouseOut && props.onMouseOut()}
                style={{ cursor: 'pointer', backgroundColor: props.isFocused ? '#f0f0f0' : null }}
                {...props.innerProps} // Include this line to handle the click event
            >
                <div className="d-flex flex-column">
                    <span>{props.data.value}</span>
                    <small style={{ color: '#777' }}>{props.data.description}</small>
                </div>
            </div>
        );
    };

  




    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                    const company = userSnapshot.data().company;

                    const companyRef = firestore.collection('companies').doc(company);
                    const equipmentSnapshot = await companyRef.collection('equipment').get();

                    const equipments = equipmentSnapshot.docs.map((doc) => ({
                        value: doc.id, // Use 'doc.id' as the value
                        label: doc.data().equipmentNumber,
                    }));

                    setEquipmentList(equipments);
                }
            } catch (error) {
                console.error("Error fetching equipments:", error);
            }
        };

        fetchEquipments();
    }, []);

    const resetForm = () => {
        setEquipmentNumber(null);
        setFault('');
        setSolution('');
        setVendor('');
        setVendorContactNumber('');
        setVendorContactEmail('');
        setLessonType(null); // Reset the lesson type here
        setShowModal(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!validateVendorEmail(vendorContactEmail)) {
            setVendorEmailError('Invalid email or N/A');
            setIsLoading(false);
            return;
        }

        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const companyName = userSnapshot.data().company;
            const userName = userSnapshot.data().name; // Fetching the name
            const userSurname = userSnapshot.data().surname; // Fetching the surname
            const userEmployeeNumber = userSnapshot.data().employeeNumber; // Fetching the employee number
            const companyRef = firestore.collection('companies').doc(companyName);

            const faultRecord = {
                equipmentNumber: equipmentNumber.value,
                fault,
                solution,
                lessonType: lessonType.value,
                vendor,
                vendorContactNumber,
                vendorContactEmail,
                approved: false,
                timestamp: serverTimestamp(), // Add the timestamp here
                name: `${userName}`,
                surname: `${userSurname}`,
                employeeNumber:`${ userEmployeeNumber }`
            };

            try {
                // Create a new document with the equipment number and fault (lesson-learnt) as the document ID
                await companyRef
                    .collection('faultRecords')
                    .doc(`${equipmentNumber.value}-${fault}`)
                    .set(faultRecord);

                setEquipmentNumber(null);
                setFault('');
                setSolution('');
                setVendor('');
                setVendorContactNumber('');
                setVendorContactEmail('');
                setShowModal(true);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        navigate('/dashboard'); // Redirect to Dashboard when the modal is closed
    };

    const validateVendorEmail = (email) => {
        return email === 'N/A' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };


    return (

        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
            <h1 className="mb-5">Log Lesson Learned</h1>
            
            <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="equipmentNumber" className="my-3" >
                        <Form.Label>Equipment Number</Form.Label>
                        <Select
                            value={equipmentNumber}
                            onChange={(selectedOption) => setEquipmentNumber(selectedOption)}
                            options={equipmentList}
                            isSearchable
                            required
                        />
                    </Form.Group>

                <Form.Group controlId="lessonType" className="my-3">
                    <Form.Label>Type of Lesson learned</Form.Label>
                    <Select
                        value={lessonType}
                        onChange={(selectedOption) => setLessonType(selectedOption)}
                        options={lessonTypes}
                        isSearchable
                        required
                        components={{ Option: CustomOption }}

                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                cursor: 'pointer',
                                backgroundColor: state.isFocused ? '#f0f0f0' : null,
                            }),
                        }}
                    />
                </Form.Group>
                


                <Form.Group controlId="fault" className="my-3">
                    <Form.Label>Fault</Form.Label>
                    <Form.Control type="text" value={fault} onChange={e => setFault(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="solution" className="my-3">
                    <Form.Label>Lesson learned</Form.Label>
                    <Form.Control type="text" value={solution} onChange={e => setSolution(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="vendor" className="my-3">
                    <Form.Label>Vendor</Form.Label>
                    <Form.Control type="text" value={vendor} onChange={e => setVendor(e.target.value)} required placeholder="Enter vendor or N/A" />
                </Form.Group>
                <Form.Group controlId="vendorContact" className="my-3">
                    <Form.Label>Vendor Contact</Form.Label>
                    <Form.Control type="text" value={vendorContactNumber} onChange={e => setVendorContactNumber(e.target.value)} required placeholder="Enter vendor or N/A" />
                </Form.Group>
                <Form.Group controlId="vendorEmail" className="my-3">
                    <Form.Label>Vendor Email</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter vendor email or N/A"
                        value={vendorContactEmail}
                        onChange={(e) => {
                            setVendorContactEmail(e.target.value);
                            setVendorEmailError(''); // Clear error when the user starts typing again
                        }}
                        isInvalid={!!vendorEmailError}
                    />
                    <Form.Control.Feedback type="invalid">{vendorEmailError}</Form.Control.Feedback>
                </Form.Group>



                <div className="my-3">
                    <Button variant="success" type="submit" disabled={isLoading}>
                        {isLoading ? 'Logging Lesson..' : 'Log Lesson Learned'}
                        {isLoading && <Spinner animation="border" size="sm" />}
                    </Button>
                    <Button variant="outline-success" onClick={() => navigate('/lessonslearned')} style={{ margin: '15px' }}>
                        Back to Lessons Learned
                    </Button>
                </div>
            </Form>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Lesson learned logged successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={resetForm}>
                        Log another lesson learned
                    </Button>
                    <Button variant="outline-success" onClick={() => navigate('/lessonslearned')}>
                        Back to lessons learned
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default LogFaultRecord;
