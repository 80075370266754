import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Import your company logo image
import logoImage from './/Nucleus Logo.svg';

const HomePage = () => {
    const navigate = useNavigate();

    const containerStyle = {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8f9fa',
    };

    const headerStyle = {
        color: 'green',
        marginBottom: '30px',
    };

    const descriptionStyle = {
        marginBottom: '30px',
        textAlign: 'center',
    };

    const buttonStyle = {
        margin: '10px',
        width: '150px',
    };

    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
    };

    return (
        <Container style={containerStyle}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />

            <h1 style={headerStyle}>Welcome to Nucleus</h1>
            <Row>
                <Col xs={12}>
                    <p style={descriptionStyle}>
                        As the central hub for operational success, Nucleus provides a cutting-edge solution for enhancing your maintenance and fault tracking systems. Nucleus enables efficient equipment management and records key insights, helping retain critical knowledge within your organization. Nucleus plays a vital role in knowledge retention, maintenance planning, and record viewing. Its intuitive user-friendly interface, ensures your organization always operates at its peak
                    </p>
                    <p style={descriptionStyle}>
                        It's all about creating your unique Nucleus for operational success.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <Button variant="success" style={buttonStyle} onClick={() => navigate('/signin')}>
                        Sign In
                    </Button>
                    <Button variant="outline-success" style={buttonStyle} onClick={() => navigate('/signup')}>
                        Sign Up
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default HomePage;