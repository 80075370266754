import React, { useState, useEffect } from 'react';
import { firestore, firebase } from '../utils/firebase';
import { Button, Table, Container, Spinner, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';

const ApproveLessons = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const { role, hasRole, loading } = useRole();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [loadingApproveId, setLoadingApproveId] = useState(null); // Add this line
    const [loadingDeleteId, setLoadingDeleteId] = useState(null); // Add this line
    const [editModalInfo, setEditModalInfo] = useState({ show: false, record: null }); // Add this line

    useEffect(() => {
        if (!currentUser) {
            console.log("No user logged in, redirecting to login page...");
            navigate('/login');  // assuming '/login' is your login route
        } else {
            console.log(`Logged in user: ${currentUser.email}`);
            fetchUnapprovedRecords();
            fetchUserDetails();
        }
    }, []);

    const fetchUserDetails = async () => {
        try {
            if (currentUser) {
                const userRef = firestore.collection('users').doc(currentUser.uid);

                userRef.get().then((doc) => {
                    if (doc.exists) {
                        const userCompany = doc.data().company;
                        console.log(`Logged in user: ${currentUser.email}, Company ID: ${userCompany}`);
                        console.log('User data:', doc.data());
                        console.log('Updating role:', doc.data().role);
                        currentUser.company = userCompany; // Add this line to store the company ID in the currentUser object
                    } else {
                        console.log('No such document!');
                    }
                });
            }
        } catch (error) {
            console.log("Fetch User Details Error:", error.message);
        }
    };

    const fetchUnapprovedRecords = async () => {
        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(company);
                const faultRecordsRef = companyRef.collection('faultRecords');

                const snapshot = await faultRecordsRef
                    .where('approved', '==', false)
                    .get();

                const fetchedRecords = snapshot.docs.map(doc => {
                    return { ...doc.data(), id: doc.id };
                });
                setRecords(fetchedRecords);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const approveRecord = async (id) => {
        setLoadingApproveId(id); // Add this line
        try {
            if (currentUser) {
                console.log(`Approving record ${id}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);

                const recordRef = companyRef.collection('faultRecords').doc(id);

                await recordRef.update({
                    approved: true,
                });

                fetchUnapprovedRecords();
            }
        } catch (error) {
            console.error("Approve Record Error:", error.message);
        }
        setLoadingApproveId(null); // Add this line
    };

    const deleteRecord = async (id) => {
        setLoadingDeleteId(id); // Add this line
        try {
            if (currentUser) {
                console.log(`Deleting record ${id}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('faultRecords').doc(id);

                await recordRef.delete();

                fetchUnapprovedRecords();
            }
        } catch (error) {
            console.error("Delete Record Error:", error.message);
        }
        setLoadingDeleteId(null); // Add this line
    };

    const openEditModal = (record) => {
        setEditModalInfo({ show: true, record });
    };

    const closeEditModal = () => {
        setEditModalInfo({ show: false, record: null });
    };

    const handleEdit = async () => {
        // TODO: Handle how to update the record here...
        closeEditModal();
    };

    useEffect(() => {
        fetchUnapprovedRecords();
    }, []);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }  

    return (
    <>
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '30vh' }}>
                <h1 className="mb-5">Approve Lesson Learned</h1>
            <div style={{ overflowX: 'auto', maxWidth: '95vw' }}>
                <Table striped bordered hover className="my-3">
                    <thead>
                        <tr>
                            <th>Equipment Number</th>
                            <th>Fault</th>
                            <th>Lesson Learnt</th>
                            <th>Vendor Used</th>
                            <th>Vendor Contact Number</th>
                            <th>Vendor Contact Email</th>
                            {hasRole('Admin') && <th>Approve</th>}
                                {hasRole('Admin') && <th>Delete</th>} {/* New column for delete button */}
                                {/*hasRole('Admin') && <th>Edit</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) => (
                            <tr key={index}>
                                <td>{record.equipmentNumber}</td>
                                <td>{record.fault}</td>
                                <td>{record.solution}</td>
                                <td>{record.vendor}</td>
                                <td>{record.vendorContactNumber}</td>
                                <td>{record.vendorContactEmail}</td>
                                {role === 'Admin' && (
                                    <td>
                                        <Button variant="success" onClick={() => approveRecord(record.id)} disabled={loadingApproveId === record.id}>
                                            {loadingApproveId === record.id ? <Spinner animation="border" size="sm" /> : 'Approve'}
                                        </Button>
                                    </td>
                                )}
                                {role === 'Admin' && (
                                    <td>
                                        <Button variant="danger" onClick={() => deleteRecord(record.id)} disabled={loadingDeleteId === record.id}>
                                            {loadingDeleteId === record.id ? <Spinner animation="border" size="sm" /> : 'Delete'}
                                        </Button>
                                    </td>
                                )}
                                {/*role === 'Admin' && (
                                    <td><Button variant="warning" onClick={() => openEditModal(record)}>Edit</Button></td>
                                )*/}
                            </tr>
                        ))}
                            {records.length === 0 && (
                                <tr>
                                    <td colSpan={hasRole('Admin') ? 8 : 6} style={{ textAlign: "center" }}>No Lessons learned to approve</td>
                                </tr>
                            )}
                    </tbody>
                </Table>
            </div>
                <Button variant="outline-success" onClick={() => navigate('/lessonslearned')}>
                    Back to Lessons Learned
            </Button>
        </Container>
   <Modal show={editModalInfo.show} onHide={closeEditModal}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="editEquipmentNumber">
                    <Form.Label>Equipment Number</Form.Label>
                    <Form.Control type="text" defaultValue={editModalInfo.record?.equipmentNumber} required />
                </Form.Group>
                {/* Add other fields to edit here... */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleEdit}>
                    Save Changes
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
};

export default ApproveLessons;
