import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';


const Multiview = () => {
    const [records, setRecords] = useState([]);
    const [userCompany, setUserCompany] = useState('');
    const [modalContent, setModalContent] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchUserCompany = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) return;
            const userRef = doc(firestore, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                setUserCompany(userSnap.data().company);
                fetchRecords(userSnap.data().company);
            }
        };

        fetchUserCompany();
    }, []);

    const fetchRecords = async (company) => {
        if (!company) return;
        const recordsQuery = query(collection(firestore, `companies/${company}/multilog`));
        const querySnapshot = await getDocs(recordsQuery);
        const recordsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRecords(recordsData);
    };

    const handleShowModal = (record) => {
        setModalContent(record);
        setShowModal(true);
    };

    return (
        <Container>
            <h2>Records</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={index}>
                            <td>{record.description}</td>
                            <td>
                                <Button variant="primary" onClick={() => handleShowModal(record)}>View Details</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Record Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.entries(modalContent).map(([key, value], index) => (
                        key !== "id" ? <p key={index}><strong>{key}:</strong> {typeof value === 'string' ? value : 'File Uploaded'}</p> : null
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Multiview;
