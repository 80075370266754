import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Modal, Spinner} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { firestore, firebaseApp as firebase } from '../utils/firebase';
import { getAuth } from 'firebase/auth';
import Select from 'react-select';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, Timestamp } from "firebase/firestore";

// Initialize Firestore
const db = getFirestore();

function dateToStr(dateObj) {
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}


const ScheduleMaintenance = () => {
    const [equipmentNumber, setEquipmentNumber] = useState(null);
    const [plannedDate, setPlannedDate] = useState('');
    const [maintenanceType, setMaintenanceType] = useState('');
    const [activity, setActivity] = useState('');
    const [vendor, setVendor] = useState('');
    const navigate = useNavigate();
    const [equipmentList, setEquipmentList] = useState([]);
    const [isRoutineMaintenance, setIsRoutineMaintenance] = useState(false);
    const [routineInterval, setRoutineInterval] = useState('');
    const [showModal, setShowModal] = useState(false);
    const formStyle = {
        width: '50%',
        margin: 'auto'
    };
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [loading, setLoading] = useState(true);

    const maintenanceOptions = [
        { value: "Preventive Maintenance", label: "Preventive Maintenance - Routine checks and servicing" },
        { value: "Predictive Maintenance", label: "Predictive Maintenance - Real-time performance evaluation" },
        { value: "Corrective Maintenance", label: "Corrective Maintenance - Maintenance after fault detection" },
        { value: "Adaptive Maintenance", label: "Adaptive Maintenance - Changes to keep systems relevant" },
        { value: "Routine Maintenance", label: "Routine Maintenance - Regular cleaning, minor repairs, and inspections" },
        { value: "Emergency Maintenance", label: "Emergency Maintenance - Maintenance in response to urgent situations" },
        { value: "Reliability Centered Maintenance", label: "Reliability Centered Maintenance - Corporate-level maintenance strategy" },
        { value: "Condition-Based Maintenance", label: "Condition-Based Maintenance - Maintenance based on asset condition" },
        { value: "Risk-based Maintenance", label: "Risk-based Maintenance - Prioritize maintenance based on risk of failure" },
    ];

    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps}>
            <div>{data.value}</div>
            <div style={{ color: 'grey', fontSize: '12px', textAlign: 'center' }}>{label}</div>
        </div>
    );
    

    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                    const company = userSnapshot.data().company;

                    const companyRef = firestore.collection('companies').doc(company);
                    const equipmentSnapshot = await companyRef.collection('equipment').get();

                    const equipments = equipmentSnapshot.docs.map((doc) => ({
                        value: doc.id, // Use 'doc.id' as the value
                        label: doc.data().equipmentNumber,
                    }));

                    setEquipmentList(equipments);
                }
            } catch (error) {
                console.error("Error fetching equipments:", error);
            }
        };

        fetchEquipments();
    }, []);

    

    useEffect(() => {
        const fetchEmployees = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;
                const userQuerySnapshot = await firestore.collection('users').where('company', '==', company).get();

                const employees = userQuerySnapshot.docs.map(doc => ({ value: doc.id, label: `${doc.data().name} ${doc.data().surname}` }));
                setEmployeeList(employees);
                setLoading(false);  // Set loading to false once data has been loaded
            }
        };

        fetchEmployees();
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            let companyId; // Declare companyId here

            if (currentUser) {
                // Fetch user document to get the company ID
                const userSnapshot = await getDoc(doc(db, 'users', currentUser.uid));
                companyId = userSnapshot.data().company;

                // Debugging lines
                console.log('User Snapshot:', userSnapshot);
                console.log('Company ID:', companyId);

                // Check if equipment exists
                const equipmentRef = doc(db, 'companies', companyId, 'equipment', equipmentNumber.value);
                const snapshot = await getDoc(equipmentRef);
                if (!snapshot.exists()) {
                    // If it doesn't exist, add it
                    await setDoc(equipmentRef, {
                        equipmentNumber: equipmentNumber.value,
                        serialNumber: "", // Replace with appropriate serialNumber
                        equipmentType: "" // Replace with appropriate equipmentType
                    });
                }

                if (!plannedDate) {
                    throw new Error("Planned date is not set");
                }

                console.log('Planned Date:', plannedDate); // Debugging line

                const dateObject = new Date(plannedDate);
                console.log('Date Object:', dateObject); // Debugging line

                // If invalid date, throw an error
                if (isNaN(dateObject.getTime())) {
                    throw new Error('Invalid date format.');
                }

                const timestamp = Timestamp.fromDate(dateObject);
                console.log('Timestamp:', timestamp); // Debugging line

                const maintenanceLogRef = doc(db, 'companies', companyId, 'maintenanceLogs', `${equipmentNumber.value}-${activity}-${plannedDate}`);
                console.log('Maintenance log:', {
                    equipmentNumber: equipmentNumber.label,
                    date: timestamp,
                    maintenanceType,
                    activity,
                    vendor,
                    approved: false,
                    scheduled: true,
                    fullyApproved: false,
                    logmaintenance: true,
                    ScheduledApproved: false,
                    Confirmed:false,
                    routineInterval: isRoutineMaintenance ? routineInterval : null,  // Add routineInterval here
                    rejected: false, // set rejected field to false initially
                    comment: '', // set comment field to an empty string initially
                    employee: selectedEmployee ? selectedEmployee.label : null, 
                });
                await setDoc(maintenanceLogRef, {
                    equipmentNumber: equipmentNumber.label,
                    date: timestamp, // Firestore timestamp
                    maintenanceType,
                    activity,
                    vendor,
                    approved: false,
                    scheduled: true,
                    fullyApproved: false,
                    logmaintenance: true,
                    ScheduledApproved: false,
                    routineInterval: isRoutineMaintenance ? routineInterval : null,  // Add routineInterval here
                    rejected: false, // set rejected field to false initially
                    comment: '', // set comment field to an empty string initially
                    employee: selectedEmployee ? selectedEmployee.label : null, 
                    Confirmed: false,

                });

                
                // After successful form submission, navigate back to the dashboard
                setShowModal(true);
            }
            // Handle routine maintenance
            if (isRoutineMaintenance) {
                // Determine the number of routine tasks based on the interval
                let routineTasksCount;
                switch (routineInterval) {
                    case 'daily':
                        routineTasksCount = 365;
                        break;
                    case 'weekly':
                        routineTasksCount = 52;
                        break;
                    case 'monthly':
                        routineTasksCount = 12;
                        break;
                    case 'biannually':
                        routineTasksCount = 2;
                        break;
                    case 'yearly':
                        routineTasksCount = 1;
                        break;
                    default:
                        throw new Error(`Invalid routine interval: ${routineInterval}`);
                }

                // Generate a year's worth of routine tasks
                for (let i = 0; i < routineTasksCount; i++) {
                    setTimeout(async () => {
                        const taskDate = new Date(plannedDate);
                        taskDate.setDate(taskDate.getDate() + i * (365 / routineTasksCount));

                        // Generate a unique ID for each maintenance log
                        const maintenanceLogId = `${equipmentNumber.value}-${activity}-${dateToStr(taskDate)}`;

                        // Add the new maintenance log to the maintenanceLogs collection
                        const maintenanceLogRef = doc(db, 'companies', companyId, 'maintenanceLogs', maintenanceLogId);
                        await setDoc(maintenanceLogRef, {
                            equipmentNumber: equipmentNumber.label,
                            date: Timestamp.fromDate(taskDate), // Firestore timestamp
                            maintenanceType,
                            activity,
                            vendor,
                            approved: false,
                            scheduled: true,
                            fullyApproved: false,
                            logmaintenance: true,
                            ScheduledApproved: false,
                            routineInterval: routineInterval,  // Add routineInterval here
                            routineMaintenance: true,
                            rejected: false, // set rejected field to false initially
                            comment: '', // set comment field to an empty string initially
                            Confirmed: false,

                        });
                    }, i * 500); // 500ms delay between each operation
                }
                setShowModal(true);
            }

           

            

            console.log('Setting showModal to true...');
            await setShowModal(true); // Show the modal here
            setIsLoading(false);

        } catch (error) {
            console.error('Error:', error);
            console.log(plannedDate);  // Debugging line;
        }
    };

    

    const resetForm = () => {
        setEquipmentNumber(null);
        setPlannedDate('');
        setMaintenanceType('');
        setActivity('');
        setVendor('');
        setIsRoutineMaintenance(false);
        setRoutineInterval('');
        setShowModal(false);
    };
    

    const handleModalClose = () => {
        setShowModal(false);
        navigate('/schedule-maintenance');
        
    };

    

    return (
        
        <div className="mt-5" style={formStyle}>
            <h1 className="mb-5">Schedule Maintenance</h1>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group controlId="equipmentNumber" className="my-3">
                    <Form.Label>Equipment Number</Form.Label>
                    <Select
                        value={equipmentNumber}
                        onChange={(selectedOption) => setEquipmentNumber(selectedOption)}
                        options={equipmentList}
                        isSearchable
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Planned Date</Form.Label>
                    <Form.Control type="date" value={plannedDate} onChange={e => setPlannedDate(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="maintenanceType" className="my-3">
                    <Form.Label>Maintenance Type</Form.Label>
                    <Select
                        value={maintenanceOptions.find(option => option.value === maintenanceType)}
                        onChange={(selectedOption) => setMaintenanceType(selectedOption.value)}
                        options={maintenanceOptions}
                        isSearchable
                        required
                        components={{ Option: CustomOption }}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Activity</Form.Label>
                    <Form.Control type="text" value={activity} onChange={e => setActivity(e.target.value)} required />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Vendor/Contractor</Form.Label>
                    <Form.Control type="text" value={vendor} onChange={e => setVendor(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="employee" className="my-3">  {/* New Form Group for selecting employee */}
                    <Form.Label>Employee</Form.Label>
                    <Select
                        value={selectedEmployee}
                        onChange={(selectedOption) => setSelectedEmployee(selectedOption)}
                        options={employeeList}
                        isSearchable
                        isLoading={loading}  // Show loading indicator while fetching data
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="routineMaintenanceCheckbox">
                    <Form.Check
                        type="checkbox"
                        label="Is this Routine Maintenance?"
                        checked={isRoutineMaintenance}
                        onChange={e => setIsRoutineMaintenance(e.target.checked)}
                    />
                </Form.Group>

                {isRoutineMaintenance && (
                    <Form.Group className="mb-3" controlId="routineMaintenanceSelect">
                        <Form.Label>Routine Interval</Form.Label>
                        <Form.Select onChange={e => setRoutineInterval(e.target.value)} required>
                            <option value="">Select interval...</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="biannually">Biannually</option>
                            <option value="yearly">Yearly</option>
                        </Form.Select>
                    </Form.Group>
                )}

                <Button variant="success" type="submit" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                    {isLoading && <Spinner animation="border" size="sm" />}
                </Button>
                <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')} style={{ margin: '15px' }}>
                    Back to Maintenance
                </Button>
            </Form>

            {/* Success Modal */}
            <Modal show={showModal} onHide={handleModalClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Maintenance scheduled successfully!</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row">
                    <Button variant="success" onClick={() => {
                        resetForm();
                        handleModalClose();
                    }}>
                        Schedule more maintenance
                    </Button>
                    <Button variant="outline-success" onClick={resetForm} onClick={() => navigate('/equipment-maintenance')}>
                        Back to Equipment Maintenance
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
);
};
export default ScheduleMaintenance;