import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { Button, Form, Container, Alert, Row, Col, Modal, Spinner } from 'react-bootstrap'; // Import Spinner
import { collection, getDocs, doc, setDoc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import './signup.css';
import logoImage from '../../Nucleus Logo.svg';
import ReactGA4 from 'react-ga4';

const SignUp = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA4.initialize('G-FGPF61TG81');
        ReactGA4.send('page_view'); // Record the initial pageview
    }, []);
    useEffect(() => {
        const pagePath = `${location.pathname}${location.search}`;
        ReactGA4.send({ hitType: 'page_view', page_path: pagePath });
    }, [location.pathname, location.search]);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [company, setCompany] = useState('');
    const [companies, setCompanies] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const auth = getAuth();
    const [newCompany, setNewCompany] = useState('');
    const [uniqueCompanyCode, setUniqueCompanyCode] = useState('');
    const [showUniqueCodeField, setShowUniqueCodeField] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [companyOtherSelected, setCompanyOtherSelected] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const logoStyle = {
        maxWidth: '100px', // Adjust the size as needed
        margin: '0',
        padding: '0',
    };

    const showError = (message) => {
        setErrorMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage('');
    };

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            sessionStorage.setItem('userData', JSON.stringify(user));
        }
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const companiesCollection = collection(firestore, 'companies');
            const companySnapshot = await getDocs(companiesCollection);
            const companyList = companySnapshot.docs.map((doc) => doc.id);
            setCompanies(companyList);
        } catch (error) {
            console.error(error);
        }
    };

    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    const signUpWithEmail = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (password.length < 6) {
            setErrorMessage("Password should be at least 6 characters");
            setIsLoading(false);
            return;
        }

        try {
            const result = await createUserWithEmailAndPassword(auth, email, password);
            const user = result.user;
            const actualCompany = newCompany || company;

            let role = (company === 'Other') ? 'Admin' : 'User';
            let approved = (company === 'Other') ? true : false;
            let newUniqueCompanyCode = '';

            if (company !== 'Other') {
                const companyRef = doc(firestore, 'companies', actualCompany);
                const companySnap = await getDoc(companyRef);

                if (!companySnap.exists) {
                    throw new Error(`Company ${actualCompany} does not exist in Firestore`);
                }

                const companyData = companySnap.data();

                if (uniqueCompanyCode !== companyData.uniqueCompanyCode) {
                    throw new Error('Unique Company Code does not match. Please check your input.');
                }
            } else {
                newUniqueCompanyCode = Math.random().toString(36).substr(2, 9);

                const companiesRef = doc(firestore, 'companies', actualCompany);
                // Save the plan, uniqueCompanyCode, and companyAddedDate
                await setDoc(companiesRef, {
                    uniqueCompanyCode: newUniqueCompanyCode,
                    plan: selectedPlan,
                    companyAddedDate: new Date()  // Current date and time
                });
            }

            const userData = {
                name: userName,
                surname: userSurname,
                email: email,
                employeeNumber: employeeNumber,
                contactNumber: contactNumber,  // Add this line
                company: actualCompany,
                createdDate: new Date(), // Add user creation date here
                userId: user.uid,
                role: role,
                approved: approved,
                plan: selectedPlan,
            };

            if (newUniqueCompanyCode) {
                userData.uniqueCompanyCode = newUniqueCompanyCode;
            }
            const usersRef = doc(firestore, 'users', user.uid);
            await setDoc(usersRef, userData);

            // Verify data in Firestore
            const userSnap = await getDoc(usersRef);
            if (userSnap.exists()) {
                const fetchedUserData = userSnap.data();
                if (fetchedUserData && fetchedUserData.email === email) {
                    // Save user data in session storage
                    sessionStorage.setItem("userData", JSON.stringify(userData));
                } else {
                    throw new Error("There was an issue verifying your data. Please try again.");
                }
            } else {
                throw new Error("There was an issue creating your account. Please try again.");
            }

            // Stop showing the loading indicator
            setIsLoading(false);

            // Directly navigate the user to the appropriate page
            if (role === 'Admin') {
                navigate("/dashboard");
            } else if (approved) {
                navigate("/dashboard");
            } else {
                navigate("/approval-pending");
            }

        } catch (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    };
    ;

    return (
        <Container className="mt-5">
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <Form onSubmit={signUpWithEmail} className="form">
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} className="input-sm" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} className="input-sm" />
                </Form.Group>

                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" onChange={(e) => setUserName(e.target.value)} className="input-sm" />
                </Form.Group>

                <Form.Group controlId="formBasicSurname">
                    <Form.Label>Surname</Form.Label>
                    <Form.Control type="text" placeholder="Surname" onChange={(e) => setUserSurname(e.target.value)} className="input-sm" />
                </Form.Group>

                <Form.Group controlId="formBasicEmployeeNumber">
                    <Form.Label>Employee Number</Form.Label>
                    <Form.Control type="text" placeholder="Employee Number" onChange={(e) => setEmployeeNumber(e.target.value)} className="input-sm" />
                </Form.Group>

                <Form.Group controlId="formBasicContactNumber">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control type="text" placeholder="Contact Number" onChange={(e) => setContactNumber(e.target.value)} className="input-sm"
                    />
                </Form.Group>


                <Form.Group controlId="formBasicCompany">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                            const value = e.target.value;
                            setCompany(value);
                            setCompanyOtherSelected(value === "Other");
                            if (value !== "Other" && value !== "") {
                                setShowUniqueCodeField(true);
                            } else {
                                setShowUniqueCodeField(false);
                            }
                        }}
                        className="input-sm"
                    >
                        <option value="">Select a company...</option>
                        {companies.map((company) => (
                            <option key={company}>{company}</option>
                        ))}
                        <option value="Other">Add new company</option>
                    </Form.Control>
                    {companyOtherSelected && (
                        <Form.Group controlId="formBasicNewCompany">
                            <Form.Label>New Company</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter new company"
                                onChange={(e) => setNewCompany(e.target.value)}
                                className="input-sm"
                            />
                        </Form.Group>
                    )}
                    {showUniqueCodeField && (
                        <Form.Group controlId="formBasicUniqueCompanyCode">
                            <Form.Label>Unique Company Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter unique company code"
                                onChange={(e) => setUniqueCompanyCode(e.target.value)}
                                className="input-sm"
                            />
                        </Form.Group>
                    )}
                </Form.Group>
                {companyOtherSelected && (
                    <Form.Group controlId="formBasicPlan">
                        <Form.Label>Plan</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                setSelectedPlan(e.target.value);
                            }}
                            className="input-sm"
                        >
                            <option value="">Select a plan...</option>

                            <option value="full_nucleus">Full Nucleus ($499/mo)</option>
                        </Form.Control>
                    </Form.Group>
                )}


                <Row className="row-btn">
                    <Button variant="success" className="w-100 mt-3" type="submit" disabled={isLoading}>
                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Sign Up'}
                    </Button>
                    {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                    {showSuccess && <Alert variant="success" className="mt-3">Account created successfully! Redirecting...</Alert>}
                    <Button variant="outline-success" className="w-100 mt-3" onClick={() => navigate("/")}>
                        Back to Homepage
                    </Button>
                    <Button variant="success" className="w-100 mt-3" type="submit" onClick={() => navigate("/signin")}>
                        Sign In
                    </Button>

                </Row>
            </Form>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default SignUp;
