import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Container, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { firestore } from '../utils/firebase';   // Assuming you exported firestore from firebase.js
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './AddEmployees.css';
import logoImage from '../../Nucleus Logo.svg';


const AddEmployees = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };

    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [editingEmployeeId, setEditingEmployeeId] = useState('');
    const [newNumber, setNewNumber] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [editedEmployee, setEditedEmployee] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmployees = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;
                const userQuerySnapshot = await firestore.collection('users').where('company', '==', company).get();

                const employees = userQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setEmployeeList(employees);
                setLoading(false);  // Set loading to false once data has been loaded
            }
        };

        fetchEmployees();
    }, []);

    const handleDeleteEmployee = async () => {
        await firestore.collection('users').doc(selectedEmployeeId).delete();
        setEmployeeList(prevList => prevList.filter(emp => emp.id !== selectedEmployeeId));
        setShowDeleteModal(false);
    };

    const handleEditSave = async () => {
        await firestore.collection('users').doc(selectedEmployee.id).update(editedEmployee);
        setEmployeeList(prevList => {
            const updatedList = [...prevList];
            const index = updatedList.findIndex(emp => emp.id === selectedEmployee.id);
            if (index !== -1) {
                updatedList[index] = { ...updatedList[index], ...editedEmployee };
            }
            return updatedList;
        });
        setShowEditModal(false);
    };

    const handleEditClick = (employee) => {
        setSelectedEmployee(employee);
        setEditedEmployee(employee);
        setShowEditModal(true);
    };

    const [filters, setFilters] = useState({
        name: '',
        surname: '',
        number: '',
        email: ''
    });

    const [showSearch, setShowSearch] = useState({
        name: false,
        surname: false,
        number: false,
        email: false
    });

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('th')) {
                setShowSearch({
                    name: false,
                    surname: false,
                    number: false,
                    email: false
                });
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);

    const columns = [
        { key: 'name', title: 'Name' },
        { key: 'surname', title: 'Surname' },
        { key: 'number', title: 'Contact Number' },
        { key: 'email', title: 'Email' },
        { key: 'edit', title: 'Edit' },
        { key: 'delete', title: 'Delete' }
    ];

    const filteredEmployees = employeeList.filter(employee =>
        Object.keys(filters).every(key =>
            filters[key] === '' || // if the filter for this key is empty, return true
            (employee[key] && employee[key].toString().toLowerCase().includes(filters[key].toLowerCase()))  // otherwise, check if the field is present and passes the filter
        )
    );


    return (
        <Container className="container-custom d-flex justify-content-center align-items-center" style={{ minHeight: '75vh' }}>

            {loading ? (  // Conditionally render spinner or the rest of your component
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            ) : (
                    <>
                        <img src={logoImage} alt="Company Logo" style={logoStyle} />

                    <h2>Employee Details</h2>

            <Row>
                <Col>
                    <Button variant="outline-success" style={{ margin: '15px', height: '55px' }} onClick={() => navigate('/dashboard')}>
                        Back to Dashboard
                    </Button>
                </Col>
            </Row>
            <div style={{ overflowX: 'auto', maxWidth: '95vw' }}>
                <Table striped bordered hover className="mt-4 table-custom">
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.key}>
                                    <div className="table-header">
                                        <span style={{ opacity: showSearch[column.key] ? 0 : 1 }}>
                                            {column.title}
                                        </span>
                                        {column.key !== 'edit' && column.key !== 'delete' && (  // Exclude search for Edit and Delete columns
                                            <>
                                                <i
                                                    className="fas fa-search"
                                                    onClick={() => setShowSearch({ ...showSearch, [column.key]: !showSearch[column.key] })}
                                                />
                                                {showSearch[column.key] && (
                                                    <input
                                                        type="text"
                                                        value={filters[column.key]}
                                                        onChange={(e) => setFilters({ ...filters, [column.key]: e.target.value })}
                                                        placeholder={`Filter by ${column.title}`}
                                                        className="table-search-input"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>


                    <tbody>
                        {filteredEmployees.map((employee) => (
                            <tr key={employee.id}>
                                <td>{employee.name}</td>
                                <td>{employee.surname}</td>
                                <td>{employee.number}</td>
                                <td>{employee.email}</td>
                                <td>
                                    {editingEmployeeId === employee.id ? (
                                        <>
                                            <Button variant="success" onClick={handleEditSave}>Save</Button>
                                            <Button variant="secondary" onClick={() => setEditingEmployeeId('')}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="info" onClick={() => handleEditClick(employee)}>Edit</Button>
                                    )}
                                </td>
                                <td>
                                    {editingEmployeeId === employee.id ? null : (
                                        <Button variant="danger" onClick={() => { setShowDeleteModal(true); setSelectedEmployeeId(employee.id); }}>Delete</Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>


            </Table>
            </div>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={editedEmployee.name || ''}
                            onChange={e => setEditedEmployee({ ...editedEmployee, name: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Surname</Form.Label>
                        <Form.Control
                            type="text"
                            value={editedEmployee.surname || ''}
                            onChange={e => setEditedEmployee({ ...editedEmployee, surname: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                            type="text"
                            value={editedEmployee.number || ''}
                            onChange={e => setEditedEmployee({ ...editedEmployee, number: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            value={editedEmployee.email || ''}
                            onChange={e => setEditedEmployee({ ...editedEmployee, email: e.target.value })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEditSave}>Save</Button>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this employee?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteEmployee}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
                </>
            )}
        </Container>
    );
};


export default AddEmployees;
