import React, { useState, useCallback, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Spinner } from 'react-bootstrap';
import { askChatGPT } from '../utils/chatGPT';
import { fetchFaultRecordsByEquipmentNumber, fetchEquipmentNumbers } from '../utils/firebaseQueries';
import './chatbot.css';
import logoImage from '../../Nucleus Logo.svg';

// Define the debounce function
function debounce(func, delay) {
    let timer;
    return function (...args) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(context, args), delay);
    };
}

// Helper function to format timestamp
const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        return date.toISOString().replace('T', ' ').split('.')[0];
    }
    return 'Invalid Timestamp';
};

// Helper function to parse and replace asterisks with <strong> tags
const parseResponse = (response) => {
    const regex = /\*\*(.*?)\*\*/g;
    const parts = response.split(regex);
    return parts.map((part, index) => {
        if (index % 2 === 1) {
            return <strong key={index}>{part}</strong>;
        }
        return part;
    });
};

function ChatBot() {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');
    const [equipmentList, setEquipmentList] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [conversationContext, setConversationContext] = useState({});

    const messagesEndRef = useRef(null);

    useEffect(() => {
        const loadEquipmentNumbers = async () => {
            await fetchEquipmentNumbers(setEquipmentList);
        };

        loadEquipmentNumbers();
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isLoading]);

    const handleQuery = async () => {
        if (!query) {
            setResponse("Please enter a query.");
            return;
        }

        if (!selectedEquipment) {
            setResponse("Please select an equipment number.");
            return;
        }

        try {
            const userMessage = { role: "user", content: query };
            setMessages([...messages, userMessage]);
            setQuery(''); // Clear the query input after processing

            setIsLoading(true);
            console.log("Query:", query);
            console.log("Selected Equipment:", selectedEquipment);

            const faultData = await fetchFaultRecordsByEquipmentNumber(selectedEquipment.value);
            console.log("Fault Data:", faultData);

            // Prepare context for AI based on all record fields with formatted timestamps
            let context = `Selected Equipment: ${selectedEquipment.label}\nEquipment Fault Records:\n`;

            faultData.forEach(record => {
                context += `Fault: ${record.fault}\n`;
                context += `Solution: ${record.solution}\n`;
                context += `Vendor: ${record.vendor}\n`;
                context += `Vendor Contact Email: ${record.vendorContactEmail}\n`;
                context += `Vendor Contact Number: ${record.vendorContactNumber}\n`;
                context += `Timestamp: ${formatTimestamp(record.timestamp)}\n`;
            });

            // Determine if the query is a request for a solution
            const faultMatch = query.match(/how do I fix (.*)/i);
            const vendorMatch = query.match(/which vendors have been used/i);
            let aiResponse;

            if (faultMatch) {
                const faultToFix = faultMatch[1].toLowerCase();
                const relevantRecord = faultData.find(record => record.fault.toLowerCase().includes(faultToFix));

                if (relevantRecord) {
                    const solutionContext = `The solution for fixing ${relevantRecord.fault} is: ${relevantRecord.solution}`;
                    aiResponse = await askChatGPT(solutionContext, query, false);
                } else {
                    aiResponse = `No solution found for the fault "${faultToFix}" in the database.`;
                }
            } else if (vendorMatch) {
                const vendors = [...new Set(faultData.map(record => record.vendor))];
                const vendorContext = `The equipment, identified as ${selectedEquipment.label}, has used the following vendors: ${vendors.join(', ')}.`;
                aiResponse = await askChatGPT(vendorContext, query, false);
            } else {
                // Handle other queries normally
                aiResponse = await askChatGPT(context, query, /what faults has the equipment had/i.test(query));
            }

            console.log("AI Response:", aiResponse);

            let finalResponse = aiResponse;

            // Specific handling for conversational queries
            if (/what faults has the equipment had/i.test(query)) {
                finalResponse = `The equipment, identified as ${selectedEquipment.label}, has had the following faults recorded:`;
                faultData.forEach(record => {
                    finalResponse += ` ${record.fault},`;
                });
                finalResponse = finalResponse.slice(0, -1) + "."; // Remove the last comma and add a period
            }

            if (!aiResponse) {
                setResponse("Failed to get a response from the AI. Please check your query or try again later.");
                setMessages([...messages, userMessage, { role: "bot", content: "Failed to get a response from the AI. Please check your query or try again later." }]);
            } else {
                setResponse(finalResponse);
                setMessages([...messages, userMessage, { role: "bot", content: finalResponse }]);
            }
        } catch (error) {
            console.error("Error in handleQuery:", error);
            setResponse("An error occurred while processing your request. Please try again.");
            setMessages([...messages, { role: "bot", content: "An error occurred while processing your request. Please try again." }]);
        }

        setIsLoading(false);
    };

    const handleFollowUpQuery = async () => {
        if (!query) {
            setResponse("Please enter a query.");
            return;
        }

        try {
            const userMessage = { role: "user", content: query };
            setMessages([...messages, userMessage]);
            setQuery(''); // Clear the query input after processing

            setIsLoading(true);
            console.log("Query:", query);

            // Use conversation context to provide more relevant answers
            let context = `Selected Equipment: ${conversationContext.equipmentLabel}\n`;
            context += `Last Fault Record:\n`;
            context += `Approved: ${conversationContext.lastFaultRecord.approved}\n`;
            context += `Employee Number: ${conversationContext.lastFaultRecord.employeeNumber}\n`;
            context += `Equipment Number: ${conversationContext.lastFaultRecord.equipmentNumber}\n`;
            context += `Fault: ${conversationContext.lastFaultRecord.fault}\n`;
            context += `Lesson Type: ${conversationContext.lastFaultRecord.lessonType}\n`;
            context += `Name: ${conversationContext.lastFaultRecord.name}\n`;
            context += `Solution: ${conversationContext.lastFaultRecord.solution}\n`;
            context += `Surname: ${conversationContext.lastFaultRecord.surname}\n`;
            context += `Timestamp: ${formatTimestamp(conversationContext.lastFaultRecord.timestamp)}\n`;
            context += `Vendor: ${conversationContext.lastFaultRecord.vendor}\n`;
            context += `Vendor Contact Email: ${conversationContext.lastFaultRecord.vendorContactEmail}\n`;
            context += `Vendor Contact Number: ${conversationContext.lastFaultRecord.vendorContactNumber}\n\n`;

            const aiResponse = await askChatGPT(context, query, false); // Always detailed for follow-up
            console.log("AI Response:", aiResponse);

            if (!aiResponse) {
                setResponse("Failed to get a response from the AI. Please check your query or try again later.");
                setMessages([...messages, userMessage, { role: "bot", content: "Failed to get a response from the AI. Please check your query or try again later." }]);
            } else {
                setResponse(aiResponse);
                setMessages([...messages, userMessage, { role: "bot", content: aiResponse }]);
            }
        } catch (error) {
            console.error("Error in handleFollowUpQuery:", error);
            setResponse("An error occurred while processing your request. Please try again.");
            setMessages([...messages, { role: "bot", content: "An error occurred while processing your request. Please try again." }]);
        }

        setIsLoading(false);
    };

    // Use debounce for the handleQuery function
    const debouncedHandleQuery = useCallback(debounce(handleQuery, 1000), [query, selectedEquipment, messages]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            conversationContext.lastFaultRecord && !/what faults has the equipment had/i.test(query) ? handleFollowUpQuery() : debouncedHandleQuery();
        }
    };

    const handleEquipmentChange = (selectedOption) => {
        setSelectedEquipment(selectedOption);
        setConversationContext({}); // Reset conversation context when equipment changes
        setMessages([]); // Clear previous messages when equipment changes
    };

    return (
        <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
            <button className="chatbot-button" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? 'Close' : <img src={logoImage} alt="Chat with us" />}
            </button>
            {isOpen && (
                <div className="chat-interface">
                    <p>Welcome to Nucleus Knowledge Manager. Please select the equipment number to get started.</p>
                    <Select
                        value={selectedEquipment}
                        onChange={handleEquipmentChange}
                        options={equipmentList}
                        placeholder="Select Equipment"
                        isSearchable
                        className="equipment-select"
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            menu: base => ({
                                ...base,
                                maxHeight: '150px',
                                overflowY: 'auto'
                            })
                        }}
                    />
                    <div className="message-container">
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.role}`}>
                                {parseResponse(message.content)}
                            </div>
                        ))}
                        {isLoading && <div className="message bot"><Spinner animation="border" role="status" size="sm"><span className="sr-only">Loading...</span></Spinner></div>}
                        <div ref={messagesEndRef} />
                    </div>
                    <input
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter your query"
                    />
                    <button onClick={conversationContext.lastFaultRecord ? handleFollowUpQuery : debouncedHandleQuery}>Ask</button>
                </div>
            )}
        </div>
    );
}

export default ChatBot;
