import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { Button, Table, Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import useRole from '../hooks/useRole';
import logoImage from '../../Nucleus Logo.svg';

const ApproveUsers = () => {
    const logoStyle = {
        maxWidth: '200px',
        margin: '10px',
        padding: '0',
    };
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const { role, hasRole, loading: roleLoading } = useRole();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            console.log("No user logged in, redirecting to login page...");
            navigate('/login');
            return; // Exit early if there is no user.
        }
        console.log("User is logged in, fetching unapproved users...");
        fetchUnapprovedUsers();
    }, [currentUser]);

    const fetchUnapprovedUsers = async () => {
        setIsLoading(true);
        try {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;
            const usersRef = firestore.collection('users');
            const snapshot = await usersRef
                .where('company', '==', company)
                .where('approved', '==', false)
                .get();

            const fetchedUsers = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
            setUsers(fetchedUsers);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const approveUser = async (id) => {
        try {
            console.log(`Approving user ${id}`);

            const userRef = firestore.collection('users').doc(id);

            await userRef.update({
                approved: true
            });

            fetchUnapprovedUsers();
        } catch (error) {
            console.error("Approve User Error:", error.message);
        }
    };

    const rejectUser = async (id) => {
        try {
            console.log(`Rejecting user ${id}`);

            const userRef = firestore.collection('users').doc(id);

            await userRef.delete();

            fetchUnapprovedUsers();
        } catch (error) {
            console.error("Reject User Error:", error.message);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const windowHeight = window.innerHeight;
            const marginTopValue = `${-0.25 * windowHeight}px`;
            const containerElement = document.getElementById('custom-container');
            if (containerElement) {
                containerElement.style.marginTop = marginTopValue;
            }
        };

        handleResize(); // Initial calculation on component mount

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isLoading || roleLoading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }


    

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />

            <h1 className="mb-5">Approve Users</h1>
            {users.length === 0 ? (
                <h2 className="no-users-text text-success mb-5" >No Users to Approve</h2>
            ) : (
                <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>

                <Table striped bordered hover className="my-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Surname</th>
                                    <th className="d-none d-sm-table-cell">Email</th>
                                    <th className="d-none d-sm-table-cell">Role</th>
                            <th>Approve</th>
                            <th>Reject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.surname}</td>
                                <td className="d-none d-sm-table-cell">{user.email}</td>
                                <td className="d-none d-sm-table-cell">{user.role}</td>
                                <td>
                                    <Button variant="success" onClick={() => approveUser(user.id)}>Approve</Button>
                                </td>
                                <td>
                                    <Button variant="danger" onClick={() => rejectUser(user.id)}>Reject</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                        </Table>
                </div>
            )}
            <Button variant="success" style={{width:'175px'}} onClick={() => navigate('/dashboard')}>
                Back to Dashboard
            </Button>
        </Container>
    );
};

export default ApproveUsers;
