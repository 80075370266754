import { firestore } from '../utils/firebase';
import { getAuth } from 'firebase/auth';

export const fetchEquipmentNumbers = async (setEquipmentList) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
    const company = userSnapshot.data().company;

    const faultRecordsRef = firestore.collection('companies').doc(company).collection('faultRecords');

    try {
        const snapshot = await faultRecordsRef.get();
        const uniqueEquipments = new Set(snapshot.docs.map(doc => doc.data().equipmentNumber));
        const fetchedEquipment = Array.from(uniqueEquipments).map(equipmentNumber => ({ value: equipmentNumber, label: equipmentNumber }));
        setEquipmentList(fetchedEquipment);
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetches fault records based on a query.
 * @param {string} equipmentNumber - The equipment number to search for in fault records.
 * @return {Promise<Array>} - The fetched fault records.
 */
export const fetchFaultRecordsByEquipmentNumber = async (equipmentNumber) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
        console.log("No current user found.");
        return [];
    }

    console.log("Current user ID:", currentUser.uid);

    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
    const userData = userSnapshot.data();
    if (!userData) {
        console.log("User data not found.");
        return [];
    }

    const company = userData.company;
    console.log("Company ID:", company);

    const companyRef = firestore.collection('companies').doc(company);
    const faultRecordsRef = companyRef.collection('faultRecords');

    try {
        const snapshot = await faultRecordsRef
            .where('equipmentNumber', '==', equipmentNumber)
            .where('approved', '==', true)
            .get();

        console.log("Number of records fetched:", snapshot.size);

        const records = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log("Fetched records:", records);

        return records;
    } catch (error) {
        console.error("Error fetching fault records by equipment number:", error);
        return [];
    }
};
