import React, { useState, useEffect, useRef, useCallback } from 'react';
import { firestore } from '../utils/firebase';
import { Button, Table, Container, Spinner, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";
import { Document, Page, pdfjs } from 'react-pdf';
import './ApproveMaintenance.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ApproveMaintenance = () => {
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const { role, hasRole } = useRole();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectRecordId, setRejectRecordId] = useState(null);
    const [approvingId, setApprovingId] = useState(null);
    const [approvingAllId, setApprovingAllId] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(0);
    const modalBodyRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [originalAspectRatio, setOriginalAspectRatio] = useState(0);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [noAttachmentRecordId, setNoAttachmentRecordId] = useState(null);
    const [showNoAttachmentModal, setShowNoAttachmentModal] = useState(false);
    const [recordHasDocument, setRecordHasDocument] = useState({});
    const [showCantApproveModal, setShowCantApproveModal] = useState(false);

    const fetchDocumentUrl = async (fileName) => {
        const storage = getStorage();
        const storageRef = ref(storage, fileName);

        try {
            const url = await getDownloadURL(storageRef);
            const metadata = await getMetadata(storageRef);
            return { url, mimeType: metadata.contentType };
        } catch (error) {
            console.error("Error fetching document URL:", error);
            return null;
        }
    };

    const fetchDocumentsForRecords = useCallback(async (records) => {
        const fetchDocumentPromises = records.map(async (record) => {
            const dateObj = record.date.toDate();
            const dateString = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
            const fileName = `${record.equipmentNumber}-${record.activity}-${dateString}`;
            const documentData = await fetchDocumentUrl(fileName);

            return { ...record, documentData };
        });

        return Promise.all(fetchDocumentPromises);
    }, []);

    const fetchUserDetails = useCallback(async () => {
        try {
            if (currentUser) {
                const userRef = firestore.collection('users').doc(currentUser.uid);
                const doc = await userRef.get();

                if (doc.exists) {
                    const userCompany = doc.data().company;
                    console.log(`Logged in user: ${currentUser.email}, Company ID: ${userCompany}`);
                    console.log('User data:', doc.data());
                    console.log('Updating role:', doc.data().role);
                    currentUser.company = userCompany;
                } else {
                    console.log('No such document!');
                }
            }
        } catch (error) {
            console.log("Fetch User Details Error:", error.message);
        }
    }, [currentUser]);

    const fetchUnapprovedRecords = useCallback(async () => {
        setLoading(true);

        try {
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(company);
                const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

                const snapshotOne = await maintenanceLogsRef
                    .where('approved', '==', false)
                    .where('scheduled', '==', false)
                    .where('fullyApproved', '==', false)
                    .where('logmaintenance', '==', true)
                    .where('rejected', '==', false)
                    .where('Confirmed', '==', true)
                    .get();

                const snapshotTwo = await maintenanceLogsRef
                    .where('approved', '==', false)
                    .where('scheduled', '==', true)
                    .where('fullyApproved', '==', false)
                    .where('logmaintenance', '==', true)
                    .where('ScheduledApproved', '==', true)
                    .where('rejected', '==', false)
                    .where('Confirmed', '==', true)
                    .get();

                const fetchedRecordsOne = snapshotOne.docs.map(doc => {
                    let record = doc.data();
                    if (record.plannedDate?.toDate) {
                        record.plannedDate = record.plannedDate.toDate().toLocaleDateString();
                    }
                    return { ...record, id: doc.id, equipmentNumber: record.equipmentNumber, activity: record.activity, date: record.date };
                });

                const fetchedRecordsTwo = snapshotTwo.docs.map(doc => {
                    let record = doc.data();
                    if (record.date?.toDate) {
                        const recordDate = record.date.toDate();
                        const now = new Date();
                        now.setHours(0, 0, 0, 0);
                        if (recordDate < now) {
                            return { ...record, id: doc.id, equipmentNumber: record.equipmentNumber, activity: record.activity, date: record.date };
                        }
                    }
                }).filter(Boolean);

                const allFetchedRecords = [...fetchedRecordsOne, ...fetchedRecordsTwo];

                const recordsWithDocuments = await fetchDocumentsForRecords(allFetchedRecords);
                setRecords(recordsWithDocuments);

                const newRecordHasDocument = {};
                recordsWithDocuments.forEach(record => {
                    newRecordHasDocument[record.id] = !!record.documentData;
                });
                setRecordHasDocument(newRecordHasDocument);
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    }, [currentUser, fetchDocumentsForRecords]);

    useEffect(() => {
        const handleResize = () => {
            setPdfWidth(modalBodyRef.current.offsetWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!currentUser) {
            console.log("No user logged in, redirecting to login page...");
            navigate('/login');
        } else {
            console.log(`Logged in user: ${currentUser.email}`);
            fetchUnapprovedRecords();
            fetchUserDetails();
        }
    }, [currentUser, navigate, fetchUnapprovedRecords, fetchUserDetails]);

    const approveRecord = useCallback(async (id) => {
        setApprovingId(id);

        try {
            if (currentUser) {
                console.log(`Approving record ${id}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('maintenanceLogs').doc(id);

                await recordRef.update({
                    approved: true,
                    fullyApproved: true,
                });

                fetchUnapprovedRecords();
            }
        } catch (error) {
            console.error("Approve Record Error:", error.message);
        } finally {
            setApprovingId(null);
        }
    }, [currentUser, fetchUnapprovedRecords]);

    const approveAllForEquipment = useCallback(async (equipmentNumber) => {
        setApprovingAllId(equipmentNumber);

        try {
            if (currentUser) {
                console.log(`Approving all records for equipment ${equipmentNumber} for user: ${currentUser.email}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordsRef = companyRef.collection('maintenanceLogs');

                const snapshot = await recordsRef
                    .where('equipmentNumber', '==', equipmentNumber)
                    .get();

                const snapshotScheduled = await recordsRef
                    .where('equipmentNumber', '==', equipmentNumber)
                    .where('approved', '==', false)
                    .where('plannedDate', '<=', new Date())
                    .get();

                console.log(snapshot.docs.length, snapshotScheduled.docs.length);

                const batch = firestore.batch();
                const docs = [...snapshot.docs, ...snapshotScheduled.docs];
                console.log(docs.map(doc => doc.id));

                docs.forEach((doc) => {
                    const docRef = recordsRef.doc(doc.id);
                    batch.update(docRef, { approved: true, scheduled: false, fullyApproved: true });
                });

                await batch.commit().catch(console.error);

                fetchUnapprovedRecords();
            }
        } catch (error) {
            console.error("Approve All For Equipment Error:", error.message);
        } finally {
            setApprovingAllId(null);
        }
    }, [currentUser, fetchUnapprovedRecords]);

    const rejectRecord = useCallback(async (id, comment) => {
        setRejectLoading(true);
        try {
            if (currentUser) {
                console.log(`Rejecting record ${id}`);

                const companyRef = firestore.collection('companies').doc(currentUser.company);
                const recordRef = companyRef.collection('maintenanceLogs').doc(id);

                await recordRef.update({
                    rejected: true,
                    comment,
                });

                fetchUnapprovedRecords();
            }
        } catch (error) {
            console.error("Reject Record Error:", error.message);
        } finally {
            setRejectLoading(false);
            setShowRejectModal(false);
        }
    }, [currentUser, fetchUnapprovedRecords]);

    const handleReject = useCallback(() => {
        rejectRecord(rejectRecordId, comment);
        setRejectRecordId(null);
    }, [rejectRecordId, comment, rejectRecord]);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">Approve Maintenance</h1>
            <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
                <Table striped bordered hover className="my-3">
                    <thead>
                        <tr>
                            <th>Equipment Number</th>
                            <th>Date</th>
                            <th>Maintenance Type</th>
                            <th>Activity</th>
                            <th>Downtime</th>
                            <th>Maintenance Duration</th>
                            <th>Vendor/Contractor</th>
                            {hasRole('Admin') && <th>Approve</th>}
                            <th>View Supporting file</th>
                            {hasRole('Admin') && <th>Reject</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.equipmentNumber}</td>
                                    <td>{record.date ? record.date.toDate().toLocaleDateString() : 'N/A'}</td>
                                    <td>{record.maintenanceType}</td>
                                    <td>{record.activity}</td>
                                    <td>{record.downtime ? `${record.downtime.quantity} ${record.downtime.unit}` : 'N/A'}</td>
                                    <td>{record.maintenanceDuration ? `${record.maintenanceDuration.quantity} ${record.maintenanceDuration.unit}` : 'N/A'}</td>
                                    <td>{record.vendor}</td>
                                    {role === 'Admin' && (
                                        <td>
                                            <Button
                                                variant="success"
                                                className={!recordHasDocument[record.id] ? "disabled-look" : ""}
                                                onClick={() => {
                                                    if (recordHasDocument[record.id]) {
                                                        approveRecord(record.id);
                                                    } else {
                                                        setShowCantApproveModal(true);
                                                        setNoAttachmentRecordId(record.id);
                                                    }
                                                }}
                                            >
                                                {approvingId === record.id ? (
                                                    <Spinner animation="border" role="status" size="sm">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                ) : (
                                                    'Approve'
                                                )}
                                            </Button>
                                        </td>
                                    )}
                                    <td>
                                        <Button variant="info" onClick={() => setSelectedDocumentUrl(record.documentData)}>
                                            View File
                                        </Button>
                                    </td>
                                    {role === 'Admin' && (
                                        <td>
                                            <Button variant="danger" onClick={() => {
                                                setShowRejectModal(true);
                                                setRejectRecordId(record.id);
                                            }}>
                                                Reject
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={11}>No unapproved records</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')}>
                Back to Maintenance
            </Button>

            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reject Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="rejectComment">
                        <Form.Label>Rejection Comment</Form.Label>
                        <Form.Control type="text" placeholder="Enter a comment..." onChange={(e) => setComment(e.target.value)} required />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleReject} disabled={rejectLoading}>
                        {rejectLoading ? (
                            <Spinner animation="border" role="status" size="sm">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            'Reject'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNoAttachmentModal} onHide={() => setShowNoAttachmentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>No Attachment Found</Modal.Title>
                </Modal.Header>
                <Modal.Body>No supporting file found for this entry.</Modal.Body>
            </Modal>

            <Modal show={showCantApproveModal} onHide={() => setShowCantApproveModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approval Not Possible</Modal.Title>
                </Modal.Header>
                <Modal.Body>You cannot approve a record without a supporting document.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCantApproveModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={selectedDocumentUrl !== null} onHide={() => setSelectedDocumentUrl(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Maintenance Record Document</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={modalBodyRef} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {selectedDocumentUrl && (
                        selectedDocumentUrl.mimeType === 'application/pdf' ? (
                            <Document
                                file={selectedDocumentUrl.url}
                                onLoadSuccess={({ numPages, originalWidth, originalHeight }) => {
                                    setNumPages(numPages);
                                    setOriginalAspectRatio(originalWidth / originalHeight);
                                }}
                            >
                                {numPages && Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={pdfWidth}
                                        height={pdfWidth / originalAspectRatio}
                                    />
                                ))}
                            </Document>
                        ) : (
                            <img src={selectedDocumentUrl.url} alt="Maintenance record" style={{ width: '100%' }} />
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setSelectedDocumentUrl(null); setNumPages(null); }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ApproveMaintenance;
