import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../utils/firebase';
import { Table, Button, Container, Spinner, Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getAuth } from 'firebase/auth';
import ReactToPrint from 'react-to-print';
import logoImage from '../../Nucleus Logo PPTX.png';
import './EquipPrint.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getStorage, ref, getMetadata } from "firebase/storage";

const storage = getStorage();

const ViewMaintenanceRecord = () => {
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [records, setRecords] = useState([]);
    const [equipmentList, setEquipmentList] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const componentRef = useRef();
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
    const modalBodyRef = useRef(null);

    const fetchMaintenanceRecords = async () => {
        setLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const companyRef = firestore.collection('companies').doc(company);
        const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

        try {
            let query = maintenanceLogsRef.where('approved', '==', true).where('fullyApproved', '==', true);

            if (equipmentNumber !== 'All') {
                query = query.where('equipmentNumber', '==', equipmentNumber);
            }

            const snapshot = await query.get();
            const fetchedRecords = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setRecords(fetchedRecords);
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };

    const convertToCSV = (data) => {
        const replacer = (key, value) => value === null ? '' : value;
        const header = [
            'Equipment Number', 'Date', 'Maintenance Type', 'Activity', 'Vendor/Contractor',
            'Maintenance Duration', 'Equipment Downtime', 'Employee', 'Approved'
        ];

        const csv = [
            header.join(','),
            ...data.map(row => [
                row.equipmentNumber,
                row.date ? row.date.toDate().toLocaleDateString() : 'N/A',
                row.maintenanceType, row.activity, row.vendor,
                row.maintenanceDuration ? `${row.maintenanceDuration.quantity} ${row.maintenanceDuration.unit}` : 'N/A',
                row.downtime ? `${row.downtime.quantity} ${row.downtime.unit}` : 'N/A',
                row.employee, row.approved ? 'Yes' : 'No'
            ].map(item => JSON.stringify(item, replacer)).join(','))
        ].join('\r\n');

        return csv;
    };

    const downloadCSV = () => {
        const csvData = convertToCSV(records);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'maintenance_records.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const fetchEquipmentNumbers = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const maintenanceLogsRef = firestore.collection('companies').doc(company).collection('maintenanceLogs');

        try {
            const snapshot = await maintenanceLogsRef.get();
            const uniqueEquipments = new Set(snapshot.docs.map(doc => doc.data().equipmentNumber));
            const fetchedEquipment = Array.from(uniqueEquipments).map(equipmentNumber => ({ value: equipmentNumber, label: equipmentNumber }));

            fetchedEquipment.unshift({ value: "All", label: "All" });
            setEquipmentList(fetchedEquipment);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEquipmentNumbers();
    }, []);

    const handleSelectChange = (selectedOption) => {
        setEquipmentNumber(selectedOption.value);
    };

    const handleViewFile = async (fileUrl) => {
        const fileRef = ref(storage, fileUrl);
        try {
            const metadata = await getMetadata(fileRef);
            const mimeType = metadata.contentType;
            setSelectedDocumentUrl({ url: fileUrl, mimeType });
        } catch (error) {
            console.error("Error getting file metadata:", error);
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">View Maintenance Records</h1>
            <div className="my-4">
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={8} className="mb-3">
                        <Select
                            options={equipmentList}
                            value={equipmentList.find(option => option.value === equipmentNumber)}
                            onChange={handleSelectChange}
                            placeholder="Select Equipment Number"
                            isSearchable
                        />
                    </Col>
                    <Col xs={12} md={4} className="mb-3">
                        <Button
                            variant="success"
                            onClick={fetchMaintenanceRecords}
                            disabled={loading}
                            style={{ width: '175px' }}
                        >
                            {loading ? <Spinner animation="border" size="sm" /> : 'Search'}
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col xs={12} md={4} className="mb-3">
                        <Button
                            variant="primary"
                            onClick={downloadCSV}
                            style={{ width: '175px' }}
                        >
                            Download CSV
                        </Button>
                    </Col>

                    <Col xs={12} md={4} className="mb-3">
                        <ReactToPrint
                            trigger={() => <Button variant="success" style={{ width: '175px' }}>Print Report</Button>}
                            content={() => componentRef.current}
                        />
                    </Col>

                    <Col xs={12} md={4} className="mb-3">
                        <Button
                            variant="outline-success"
                            onClick={() => navigate('/equipment-maintenance')}
                            style={{ width: '175px' }}
                        >
                            Back
                        </Button>
                    </Col>
                </Row>
            </div>

            <div ref={componentRef}>
                <div className="print-only">
                    <Row className="align-items-center">
                        <Col xs={2}>
                            <img src={logoImage} alt="Company Logo" className="logo" />
                        </Col>
                        <Col xs={10}>
                            <Row>
                                <h1 className="mb-5">Maintenance History Report</h1>
                            </Row>
                            <Row>
                                {equipmentNumber && <h2>Equipment Number: {equipmentNumber}</h2>}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Maintenance Type</th>
                                <th>Activity</th>
                                <th>Vendor/Contractor</th>
                                <th>Maintenance Duration</th>
                                <th>Equipment Downtime</th>
                                <th>Employee</th>
                                <th className="hide-on-print">Approved</th>
                                <th className="hide-on-print">View Supporting</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.length > 0 ? records.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.date ? record.date.toDate().toLocaleDateString() : 'N/A'}</td>
                                    <td>{record.maintenanceType}</td>
                                    <td>{record.activity}</td>
                                    <td>{record.vendor}</td>
                                    <td>{record.maintenanceDuration ? `${record.maintenanceDuration.quantity} ${record.maintenanceDuration.unit}` : 'N/A'}</td>
                                    <td>{record.downtime ? `${record.downtime.quantity} ${record.downtime.unit}` : 'N/A'}</td>
                                    <td>{record.employee}</td>
                                    <td className="hide-on-print">{record.approved ? 'Yes' : 'No'}</td>
                                    <td className="hide-on-print">
                                        {record.proofUrl ? (
                                            <Button
                                                size="sm"
                                                style={{ fontSize: '12px' }}
                                                variant="info"
                                                onClick={() => handleViewFile(record.proofUrl)}
                                            >
                                                View File
                                            </Button>
                                        ) : 'N/A'}
                                    </td>
                                </tr>
                            )) : <tr><td colSpan="9">No records available</td></tr>}
                        </tbody>
                    </Table>
                </div>
            </div>

            <Modal show={selectedDocumentUrl !== null} onHide={() => setSelectedDocumentUrl(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Supporting Document</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={modalBodyRef} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    {selectedDocumentUrl && (
                        selectedDocumentUrl.mimeType === 'application/pdf' ? (
                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js">
                                <Viewer fileUrl={selectedDocumentUrl.url} />
                            </Worker>
                        ) : (
                            <img src={selectedDocumentUrl.url} alt="Maintenance record" style={{ width: '100%' }} />
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedDocumentUrl(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ViewMaintenanceRecord;
