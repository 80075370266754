import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Spinner} from 'react-bootstrap';
import useRole from '../hooks/useRole';
import '..//..//Spinner.css';
import ReactGA4 from 'react-ga4';
import logoImage from '../../Nucleus Logo.svg';
import './trainingdash.css';


const FaultSolution = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };
    const location = useLocation();

    useEffect(() => {
        ReactGA4.initialize('G-FGPF61TG81');
        ReactGA4.send('page_view'); // Record the initial pageview
    }, []);

    useEffect(() => {
        // Combine pathname and search into a single string
        const pagePath = `${location.pathname}${location.search}`;
        ReactGA4.send({ hitType: 'page_view', page_path: pagePath });
    }, [location.pathname, location.search]);

    const { role, hasRole, loading } = useRole();

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }    
    return (
        <Container className=" d-flex justify-content-center align-items-center" style={{ minHeight: '75vh' }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-5">Training Material</h1>
            <Row  className="g-4 ">
                
                <Col>
                    <Link to="/AddEquipTraining">
                        <Button variant="success" className="Button">Add Training Material</Button>
                </Link>
                </Col>
                <Col>
                    <Link to="/Viewtrainingmat">
                        <Button variant="success" className="Button">View Training Material</Button>
                    </Link>
                </Col>

                <Col >
                    <Link to="/dashboard">
                        <Button variant="outline-success" className="Button ">Back to Dashboard</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default FaultSolution;
