import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Table, Form, InputGroup, Container, Col, Row, Spinner } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './ViewRecords.css'
import ReactToPrint from 'react-to-print';
import logoImage from '../../Nucleus Logo PPTX.png';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole'; // Assume this is the correct path


const PrintComponent = React.forwardRef(({ selectedRecord, formatDate }, ref) => {
    // Ensure selectedRecord is not null or undefined
    if (!selectedRecord) {
        return null;
    }

    // Preparing data for the transposed table
    const dataEntries = Object.entries(selectedRecord).filter(([key]) => !['id', 'formId', 'createdAt', 'seq', 'approved', 'fileURLs'].includes(key));
    const headers = ['Form ID', 'Record Number', 'Created', ...dataEntries.map(([key]) => key)];
    const values = [
        selectedRecord.formId,
        selectedRecord.id,
        formatDate(selectedRecord.createdAt),
        ...dataEntries.map(([, value]) => value.toString())
    ];

    return (
        <div ref={ref}>
            <div className="print-only" style={{ textAlign: 'center' }}>
                <img src={logoImage} alt="Company Logo" className="logo" style={{ marginBottom: '20px' }} />
                    <h1>Record History Report</h1>
            <Row>

                    <Table className="print-only-table" bordered>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {values.map((value, index) => (
                                <td key={index}>{value}</td>
                            ))}
                        </tr>
                    </tbody>
                    </Table>
            </Row>

            </div>
        </div>
    );
});

const BulkPrintComponent = React.forwardRef(({ filteredRecords, formatDate }, ref) => {
    if (!filteredRecords.length) {
        return null; // Return null to avoid rendering an empty component.
    }

    // Dynamically generate headers based on keys present in the first record.
    // This assumes all records have a similar structure.
    const headers = ['Form ID', 'Record Number', 'Created', ...Object.keys(filteredRecords[0]).filter(key => !['id', 'formId', 'createdAt', 'seq', 'approved', 'fileURLs'].includes(key))];

    return (

        <div ref={ref} style={{ display: 'block' }}> {/* Ensure this is visible for printing */}
            <div mb-5>
                <Row className="print-only align-items-center ">
                    <Col xs={1}>
                        <img src={logoImage} alt="Company Logo" className="print-only logo" />
                    </Col>
                    <Col xs={9}>
                        <h1 className="print-only mb-5">Filtered Records Report</h1>

                    </Col>
                    <Col xs={2}>

                        <p className="print-only" >Date from: </p>
                        <p className="print-only" >Date to: </p>

                    </Col>
                </Row>
            </div>
            <div >
                <div className="print-only" style={{ textAlign: 'center' }}>
                    <Table bordered >
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRecords.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.formId}</td>
                                    <td>{record.id}</td>
                                    <td>{formatDate(record.createdAt)}</td>
                                    {headers.slice(3).map((header, idx) => (
                                        <td key={idx}>{record[header]?.toString() || 'N/A'}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="print-only" style={{ textAlign: 'center' }}>
                
            </div>
        </div>
    );
});



const ViewRecords = () => {
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [filters, setFilters] = useState({});
    const [showFilterInput, setShowFilterInput] = useState({});
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const printRef = useRef();
    const [isFiltered, setIsFiltered] = useState(false);
    const bulkPrintRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRecords, setIsLoadingRecords] = useState(false);
    const { role, hasRole, loading: isLoadingRole } = useRole();


    useEffect(() => {
        const fetchRecords = async () => {
            setIsLoadingRecords(true);
            setIsLoading(true); // Start loading
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setError('User not authenticated.');
                setIsLoadingRecords(false);
                setIsLoading(false);
                return;
            }

            try {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                if (!userSnapshot.exists) {
                    setError('Unable to fetch user data.');
                    setIsLoadingRecords(false);
                    setIsLoading(false);
                    return;
                }

                const company = userSnapshot.data().company;
                if (!company) {
                    setError('Company not set.');
                    setIsLoadingRecords(false);
                    setIsLoading(false);
                    return;
                }

                const queryRef = query(collection(firestore, `companies/${company}/popCustomForms`), where("approved", "==", true));
                const recordsSnapshot = await getDocs(queryRef);
                const fetchedRecords = recordsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRecords(fetchedRecords);
            } catch (error) {
                console.error('Error fetching records:', error);
                setError('Failed to fetch records.');
            }
            setIsLoadingRecords(false);
            setIsLoading(false);
        };


        fetchRecords();
    }, []);

    useEffect(() => {
        setFilteredRecords(records.filter(record =>
            Object.entries(filters).every(([k, v]) =>
                record[k]?.toString().toLowerCase().includes(v.toLowerCase()))
        ));
    }, [records, filters]);

    useEffect(() => {
        // This effect runs whenever `records` or `filters` changes.
        const filtered = records.filter(record =>
            Object.entries(filters).every(([k, v]) =>
                record[k]?.toString().toLowerCase().includes(v.toLowerCase()))
        );
        setFilteredRecords(filtered);

        // Update `isFiltered` based on whether any filters are actively applied.
        // This checks if any of the filter values are non-empty.
        const anyActiveFilters = Object.values(filters).some(value => value !== '');
        setIsFiltered(anyActiveFilters);

    }, [records, filters]); // Dependency array includes `filters` to react to its changes.

    // Function to check if any filters are active
    const areFiltersActive = () => {
        return Object.values(filters).some(value => value !== '');
    };

    if (isLoadingRole || isLoadingRecords) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
        // After setting filters, check if any are active to update isFiltered
        setIsFiltered(areFiltersActive());
    };




    const handleSelectRecord = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedRecord(null);
        setShowModal(false);
    };

    const formatDate = (timestamp) => {
        // Check if timestamp exists and has the toDate() method
        if (timestamp && typeof timestamp.toDate === 'function') {
            return timestamp.toDate().toLocaleString();
        } else if (timestamp) { // Fallback if timestamp is already a Date object or needs new Date conversion
            return new Date(timestamp).toLocaleString();
        }
        return 'N/A'; // Return 'N/A' if timestamp is null or undefined
    };


    const columns = [
        { key: 'createdAt', label: 'Date' },
        { key: 'formId', label: 'Form ID' },
        { key: 'id', label: 'Record Name' },
        // Add other columns as needed
    ];

    if (isLoading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }



    return (
        <Container>
            <Row>
                    <h1>View Records</h1>
                    <Table striped bordered hover className="Table">
                        <thead>
                            <tr>
                                {columns.map(({ key, label }) => (
                                    <th key={key} style={{ width: '25%' }}>
                                        {label}
                                        <i
                                            className="fas fa-search ml-2 cursor-pointer"
                                            onClick={() => setShowFilterInput({ ...showFilterInput, [key]: !showFilterInput[key] })}
                                        ></i>
                                        {showFilterInput[key] && (
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                placeholder={`Filter by ${label}`}
                                                value={filters[key] || ''}
                                                onChange={(e) => handleFilterChange(key, e.target.value)}
                                                className="mt-1"
                                            />
                                        )}
                                    </th>
                                ))}
                            <th style={{ width: '25%' }} >View Supporting</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRecords.map((record, index) => (
                                <tr key={index}>
                                    {columns.map(({ key }) => (
                                        <td key={key}>{key === 'createdAt' ? formatDate(record[key]) : record[key]}</td>
                                    ))}
                                    <td>
                                        <Button size="sm" variant="success" onClick={() => handleSelectRecord(record)}>View Record</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            </Row>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Record Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRecord && (
                        <>
                            <p><strong>Form:</strong> {selectedRecord.formId}</p>
                            <p><strong>Record Number:</strong> {selectedRecord.id}</p>
                            <p><strong>Created:</strong> {formatDate(selectedRecord.createdAt)}</p>
                            <p><strong>Data:</strong></p>
                            <ul>
                                {Object.entries(selectedRecord)
                                    .filter(([key]) => !['id', 'formId', 'createdAt', 'seq', 'fileURLs'].includes(key))
                                    .map(([key, value], index) => (
                                        <li key={index}><strong>{key}:</strong> {value.toString()}</li>
                                    ))
                                }
                            </ul>
                            {selectedRecord.fileURLs && (
                                <div>
                                    <p><strong>Documents:</strong></p>
                                    {Object.entries(selectedRecord.fileURLs).map(([key, url], index) => (
                                        <Button
                                            variant="link"
                                            key={index}
                                            onClick={() => window.open(url, '_blank')}>
                                            Show Document {index + 1}
                                        </Button>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Row>
                    <ReactToPrint
                    trigger={() => <Button size="sm" variant="success" className="mb-3 mt-3 recordbutton" disabled={filteredRecords.length === 0}>Print Filtered Records</Button>}
                        content={() => bulkPrintRef.current}
                    />
                <BulkPrintComponent ref={bulkPrintRef} filteredRecords={filteredRecords} formatDate={formatDate} />
            </Row>
            <Row>
                <Button size="sm" variant="outline-success" className="mb-3 mt-3 recordbutton" onClick={() => navigate('/recordlogging')} >
                        Back
                    </Button>
            </Row>
            <PrintComponent ref={printRef} selectedRecord={selectedRecord} formatDate={formatDate} />


        </Container>

    );
};

export default ViewRecords;