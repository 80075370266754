import { useEffect, useState } from 'react';
import { auth, firestore } from '../utils/firebase';


const useRole = () => {
    const currentUser = auth.currentUser;
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const companyRef = firestore.collection('companies').doc(currentUser.company);;
                const userRef = firestore.collection('users').doc(currentUser.uid);
                userRef.get()
                    .then(doc => {
                        if (doc.exists) {
                            console.log('Updating role:', doc.data().role); // To check role update
                            setRole(doc.data().role);
                            setLoading(false);
                        }
                    })
                    .catch(error => {
                        console.error('Error getting document:', error);
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        });

        // Cleanup function
        return () => unsubscribe();
    }, []);

    const hasRole = (requiredRole) => {
        return role === requiredRole;
    };

    return { role, hasRole, loading };
};

export default useRole;
