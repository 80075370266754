import React from 'react';
import { Container } from 'react-bootstrap';

function ApprovalPending() {
    return (
        <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            <h1>Your request to join the company is pending approval by the company admin.</h1>
        </Container>
    );
}

export default ApprovalPending;
