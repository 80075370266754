import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import useRole from '../hooks/useRole';
import '..//..//Spinner.css';
import ReactGA4 from 'react-ga4';
import logoImage from '../../Nucleus Logo.svg';
import './Recordlogging.css'


const Recordlogging = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };

    const location = useLocation();

    useEffect(() => {
        ReactGA4.initialize('G-FGPF61TG81');
        ReactGA4.send('page_view'); // Record the initial pageview
    }, []);

    useEffect(() => {
        // Combine pathname and search into a single string
        const pagePath = `${location.pathname}${location.search}`;
        ReactGA4.send({ hitType: 'page_view', page_path: pagePath });
    }, [location.pathname, location.search]);



    const { role, hasRole, loading } = useRole();

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="d-flex justify-content-center align-items-center Container" style={{ minHeight: '70vh' }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-5">Record Logging</h1>
            <Row xs={1} md={2} lg={3} className="g-4 Row">

                <Col sm={4}>
                    <Link to="/createcustomform">
                        <Button variant="success" className="mb-3 w-100 recordbutton" title="Create Custom Form">
                            Create Custom Form
                        </Button>
                    </Link>
                </Col>

                <Col sm={4}>
                    <Link to="/viewcustomforms">
                        <Button variant="success" className="mb-3 w-100 recordbutton" title="View Custom Forms">
                            View Custom Forms
                        </Button>
                    </Link>
                </Col>

                <Col sm={4}>
                    <Link to="/viewrecords">
                        <Button variant="success" className="mb-3 w-100 recordbutton" title="View Records">
                            View Records
                        </Button>
                    </Link>
                </Col>

            

                {role === 'Admin' && (<Col sm={6}>
                    <Link to="/approverecords">
                        <Button variant="info" className="mb-3 w-100 recordbutton" title="Approve Records">
                            Approve Records
                        </Button>
                    </Link>
                </Col>)}
                <Col sm={6}>
                    <Link to="/dashboard">
                        <Button variant="outline-success" className="mb-3 w-100 recordbutton" title="Back to dashboard">
                            Back to Dashboard
                        </Button>
                    </Link>
                 </Col>
                
            </Row>
        </Container>
    );
};

export default Recordlogging;
