import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { Table, Button, Row, Col, Container, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs } from 'react-pdf';
import { Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';
import { getAuth } from 'firebase/auth';
import './training.css'

const ViewTrainingMaterial = () => {

    const [records, setRecords] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const navigate = useNavigate();
    const [equipmentList, setEquipmentList] = useState([]);
    const [equipmentNumber, setEquipmentNumber] = useState(null);
    const viewerStyle = {
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    };

    const [filterDescription, setFilterDescription] = useState('');

    const [showDescriptionSearch, setShowDescriptionSearch] = useState(false);

    const fetchTrainingMaterialsByCompany = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const companyRef = firestore.collection('companies').doc(company);
        const trainingDocumentsRef = companyRef.collection('trainingDocuments');

        try {
            const snapshot = await trainingDocumentsRef
                .where('equipmentNumber', '==', equipmentNumber)
                .get();
            const fetchedRecords = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setRecords(fetchedRecords);
        } catch (error) {
            console.error(error);
        }
    };
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                    const company = userSnapshot.data().company;

                    const companyRef = firestore.collection('companies').doc(company);
                    const equipmentSnapshot = await companyRef.collection('equipment').get();

                    const equipments = equipmentSnapshot.docs.map((doc) => ({
                        value: doc.id, // Use 'doc.id' as the value
                        label: doc.data().equipmentNumber,
                    }));

                    setEquipmentList(equipments);
                }
            } catch (error) {
                console.error("Error fetching equipments:", error);
            }
        };

        fetchEquipments();
    }, []);

    const handleSelectChange = (selectedOption) => {
        setEquipmentNumber(selectedOption.value);
    };

    const filteredRecords = records.filter(record =>
        record.documentDescription.toLowerCase().includes(filterDescription.toLowerCase())
    );

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Close the search box if clicked outside of the 'Description' table header
            if (!event.target.closest('th')) {
                setShowDescriptionSearch(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleOutsideClick);

        // Cleanup function: remove the event listener when the component is unmounted
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);


    return (

        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">View Equipment Training Material</h1>

            <Row>
                <Col>
                    <Select
                        options={equipmentList} // Update this line
                        onChange={handleSelectChange}
                        placeholder="Select Equipment Number"
                    />
                </Col>
                <Col xs="auto">
                    <Button variant="success" onClick={fetchTrainingMaterialsByCompany}>Search</Button>
                </Col>
            </Row>
            <div className="table-responsive">
                <Table size="sm" striped bordered hover className="mt-4">
                <thead>
                    <tr>
                            <th size="sm">Equipment Number</th>
                        <th>
                            {/* Filter icon and input for description */}
                            <div className="table-header">
                                    <span size="sm" style={{ opacity: showDescriptionSearch ? 0 : 1 }}>
                                    Description
                                </span>
                                <i
                                    className="fas fa-search"
                                    onClick={() => setShowDescriptionSearch(!showDescriptionSearch)}
                                />
                                {showDescriptionSearch && (
                                        <input

                                        type="text"
                                        value={filterDescription}
                                        onChange={(e) => setFilterDescription(e.target.value)}
                                        placeholder={`Filter by Description`}
                                        className="table-search-input"
                                    />
                                )}
                            </div>
                        </th>
                            <th size="sm">Open</th>
                            <th size="sm">Download</th>
                    </tr>
                </thead>

                <tbody>
                    {filteredRecords.map((record, index) => (
                        <tr key={record.id}>
                            <td>{record.equipmentNumber}</td>
                            <td>{record.documentDescription}</td>
                            <td>
                                <Button size="sm" variant="success" onClick={() => setSelectedMaterial(record)}>Open</Button>
                            </td>
                            <td>
                                <a href={record.documentUrl} download>
                                    <Button size="sm" variant="info">Download</Button>
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </div>
            <Button size="sm" variant="outline-success" onClick={() => navigate('/trainingmaterials')} style={{ marginLeft: '0px', width:'175px' }}>
                Back 
            </Button>

            <Modal show={selectedMaterial !== null} onHide={() => setSelectedMaterial(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Training Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMaterial && (
                        selectedMaterial.fileType === 'pdf' ? (
                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js">
                                <Viewer fileUrl={selectedMaterial.documentUrl} style={viewerStyle} />

                            </Worker>
                        ) : (
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={selectedMaterial.documentUrl}
                                        controls
                                        playing
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
                        )
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedMaterial(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ViewTrainingMaterial;
