import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { PieChart, Pie, Tooltip, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Cell } from 'recharts';
import firebase from "firebase/compat/app";
import { Button, Container, Row, Col, Form, Table, Spinner } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import './MaintenanceReports.css';
import logoImage from '../../Nucleus Logo PPTX.png';

import { useNavigate } from 'react-router-dom';



// Helper function to count frequency
const countFrequency = (array) => {
    return array.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
    }, {});
};




const MaintenanceReports = () => {
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [allRecords, setAllRecords] = useState([]);  // All records without any date filter
    const [records, setRecords] = useState([]);  // Filtered records based on selected dates
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const componentRef = React.useRef();
    const role = useRole();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        fetchRecords();
    }, [startDate, endDate]);

    useEffect(() => {
        const fetchAllRecords = async () => {
            setLoading(true);
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;
            const companyRef = firestore.collection('companies').doc(company);
            const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

            try {
                const snapshot = await maintenanceLogsRef
                    .where('approved', '==', true)
                    .where('fullyApproved', '==', true)
                    .get();
                const fetchedRecords = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setAllRecords(fetchedRecords);
                setRecords(fetchedRecords);  // Initially, display all records
                setLoading(false);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchAllRecords();
    }, []);

    const fetchRecords = () => {
        if (startDate && endDate) {
            const filtered = allRecords.filter(record => {
                const recordDate = record.date.toDate();  // Assuming record.date is a Firestore Timestamp
                return recordDate >= startDate && recordDate <= endDate;
            });
            setRecords(filtered);
        } else {
            setRecords(allRecords);  // If no dates are selected, show all records
        }
    };



    const handleDateChange = (event, setDate) => {
        setDate(new Date(event.target.value + 'T00:00:00'));
    };

    const handleEndDateChange = (event) => {
        setEndDate(new Date(event.target.value + 'T23:59:59'));
    };

    const equipmentFrequency = countFrequency(records.map(record => record.equipmentNumber));


    console.log('equipmentFrequency:', equipmentFrequency);
    const undefinedEquipmentRecords = records.filter(record => record.equipmentNumber === undefined);
    console.log('Undefined equipment records:', undefinedEquipmentRecords);

    const commonMaintenanceTypes = countFrequency(records.map(record => record.maintenanceType));

    // Get the frequency of each maintenance activity
    const allMaintenanceActivities = countFrequency(records.map(record => record.activity));

    // Sort the activities based on their frequency in descending order and take the top 10
    const top10ActivityKeys = Object.entries(allMaintenanceActivities)
        .sort(([, aVal], [, bVal]) => bVal - aVal)
        .slice(0, 5)
        .map(item => item[0]);

    // Create an object with only the top 10 activities
    const commonMaintenanceActivities = {};
    top10ActivityKeys.forEach(key => {
        commonMaintenanceActivities[key] = allMaintenanceActivities[key];
    });

    // filter out records without maintenanceDuration
    const filteredRecords = records.filter(record => record.maintenanceDuration);

    const avgMaintenanceDurationRaw = filteredRecords.reduce((acc, curr) => acc + parseFloat(curr.maintenanceDuration.quantity), 0) / filteredRecords.length;
    const avgMaintenanceDuration = Math.round(avgMaintenanceDurationRaw * 100) / 100;

    const longestMaintenanceDurationRaw = Math.max(...filteredRecords.map(record => parseFloat(record.maintenanceDuration.quantity)));
    const longestMaintenanceDuration = Math.round(longestMaintenanceDurationRaw * 100) / 100;

    const shortestMaintenanceDurationRaw = Math.min(...filteredRecords.map(record => parseFloat(record.maintenanceDuration.quantity)));
    const shortestMaintenanceDuration = Math.round(shortestMaintenanceDurationRaw * 100) / 100;

    const totalDowntimeRaw = records.reduce((acc, curr) => acc + (curr.downtime && !isNaN(curr.downtime.quantity) ? parseFloat(curr.downtime.quantity) : 0), 0);
    const totalDowntime = Math.round(totalDowntimeRaw * 100) / 100;


    const avgDowntimePerEquipment = Object.entries(countFrequency(records.map(record => record.equipmentNumber)))

        .map(([equipmentNumber, count]) => {
            // filter out records for this equipment which don't have a downtime field
            const equipmentRecords = records.filter(record => record.equipmentNumber === equipmentNumber && record.downtime);
            return {
                equipmentNumber,
                averageDowntime: equipmentRecords.reduce((acc, curr) => acc + parseFloat(curr.downtime.quantity), 0) / equipmentRecords.length,
            };
        });
    const mostUsedVendors = countFrequency(records.map(record => record.vendor));

    // Prepare the data for the charts
    const equipmentFrequencyData = Object.entries(equipmentFrequency).map(([name, value]) => ({ name, value }));
    const maintenanceActivitiesData = Object.entries(commonMaintenanceActivities).map(([name, value]) => ({ name, value }));
    const vendorsData = Object.entries(mostUsedVendors).map(([name, value]) => ({ name, value }));
    const COLORS = ['#0088FE', '#00C49F', '#ffa500', '#ff42c1', '#8884d8', '#e74c3c', '#2ecc71', '#3498db', '#f39c12', '#8e44ad'];
    const [isPrinting, setIsPrinting] = useState(false);
    const CustomLegend = ({ payload }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
            {
                payload.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', maxWidth: '50%' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: entry.color }}></div>
                        <span style={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{entry.value}</span>
                    </div>
                ))
            }
        </div>
    );
    const CustomBarChartLegend = ({ maintenanceTypesData }) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {maintenanceTypesData.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: COLORS[index % COLORS.length] }}></div>
                        <span style={{ fontSize: '14px' }}>{`${entry.name}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    // For maintenanceActivitiesData
    const MaintenanceActivitiesLegend = ({ maintenanceActivitiesData }) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {maintenanceActivitiesData.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: COLORS[index % COLORS.length] }}></div>
                        <span style={{ fontSize: '14px' }}>{`${entry.name}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    // For vendorsData
    const VendorsDataLegend = ({ vendorsData }) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {vendorsData.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: COLORS[index % COLORS.length] }}></div>
                        <span style={{ fontSize: '14px' }}>{`${entry.name}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    // For avgDowntimePerEquipment
    const DowntimePerEquipmentLegend = ({ avgDowntimePerEquipment }) => (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {avgDowntimePerEquipment.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: COLORS[index % COLORS.length] }}></div>
                        <span style={{ fontSize: '14px' }}>{`${entry.equipmentNumber}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    const maintenanceTypesData = Object.entries(commonMaintenanceTypes).map(([name, value], index) => ({
        name,
        value,
        color: COLORS[index % COLORS.length],
    }));








    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div>

            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '10vh' }} className="heading print-container" ref={componentRef}>
                <h1>Maintenance Report</h1>
                <Form className="mb-3">
                    <Form.Group className="mb-3">
                        <Row className="align-items-center ">
                            <Col>
                                <Form.Label>Start Date</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="date"
                                    value={startDate ? startDate.toISOString().split('T')[0] : ''}
                                    onChange={event => handleDateChange(event, setStartDate)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row className="align-items-center ">
                            <Col>
                                <Form.Label>End Date</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="date"
                                    value={endDate ? endDate.toISOString().split('T')[0] : ''}
                                    onChange={handleEndDateChange}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col>
                            <ReactToPrint
                                trigger={() => <Button variant="success" className="mr-3" >Print Report</Button>}
                                content={() => componentRef.current}  // <-- use ref here
                                onBeforeGetContent={() => setIsPrinting(true)}
                                onAfterPrint={() => setIsPrinting(false)}
                            />

                        </Col>


                        <Col>
                            <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')}>Back</Button>
                        </Col>
                    </Row>
                </Form>

            </Container>

            <Container className="heading" ref={componentRef}>
                <div mb-5>
                    <Row className="print-only align-items-center ">
                        <Col xs={1}>
                            <img src={logoImage} alt="Company Logo" className="print-only logo" />
                        </Col>
                        <Col xs={9}>
                            <h1 className="print-only mb-5">Maintenance Report</h1>

                        </Col>
                        <Col xs={2}>

                            <p className="print-only" >Date from: {startDate ? startDate.toLocaleDateString() : null}</p>
                            <p className="print-only" >Date to: {endDate ? endDate.toLocaleDateString() : null}</p>

                        </Col>
                    </Row>
                </div>

                <div className="chart-container">
                    <div className="chart-section">
                        <Row >
                            <Col xs={12} md={6}>
                                <div style={{ width: '100%', paddingTop: '5%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Maintenance Frequency per Equipment</h2>

                                    <PieChart
                                        className={isPrinting ? "print-chart" : ""}
                                        width={Math.min(isPrinting ? 350 : window.innerWidth, 350)}
                                        height={300}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={equipmentFrequencyData}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            label
                                            // map colors to each slice
                                            children={equipmentFrequencyData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        />
                                        <Tooltip />
                                        <Legend
                                            layout="horizontal"
                                            verticalAlign="bottom"
                                            align="center"
                                            wrapperStyle={{ paddingTop: '20px' }} // Add top padding to the legend
                                        />
                                    </PieChart>
                                </div>

                            </Col>
                            <Col xs={12} md={6}>
                                <div style={{ width: '100%', paddingTop: '5%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Most Common Maintenance Types</h2>
                                    <BarChart
                                        data={maintenanceTypesData}
                                        className={isPrinting ? "print-chart" : "print-chart"}
                                        width={Math.min(isPrinting ? 350 : window.innerWidth, 350)}
                                        height={300}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis domain={[0, 'dataMax+10']} />
                                        <Tooltip />

                                        <Bar dataKey="value">
                                            {maintenanceTypesData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>

                                    <CustomBarChartLegend maintenanceTypesData={maintenanceTypesData} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="chart-section">
                        <Row>
                            <Col xs={12} md={6}>
                                <div style={{ width: '100%', paddingTop: '5%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Top 5 Maintenance Activities</h2>

                                    <BarChart
                                        data={maintenanceActivitiesData}
                                        className={isPrinting ? "print-chart" : ""}
                                        width={Math.min(isPrinting ? 350 : window.innerWidth, 350)}
                                        height={250}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="value">
                                            {maintenanceActivitiesData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                    <MaintenanceActivitiesLegend maintenanceActivitiesData={maintenanceActivitiesData} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div style={{ width: '100%', paddingTop: '5%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Most Used Vendors</h2>
                                    <BarChart
                                        data={vendorsData}
                                        className={isPrinting ? "print-chart" : ""}
                                        width={Math.min(isPrinting ? 350 : window.innerWidth, 350)}
                                        height={250}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="value">
                                            {vendorsData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                    <VendorsDataLegend vendorsData={vendorsData} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="chart-section">
                    <Row>
                        <Col xs={12} md={6}>
                            <div style={{ width: '100%', paddingTop: '0%', position: 'relative' }}>
                                <h2 style={{ textAlign: 'center' }}>Average Downtime per Equipment</h2>
                                <BarChart
                                    data={avgDowntimePerEquipment}
                                    className={isPrinting ? "print-chart" : ""}
                                    width={Math.min(isPrinting ? 350 : window.innerWidth, 350)}
                                    height={250}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="averageDowntime">
                                        {avgDowntimePerEquipment.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Bar>
                                </BarChart>
                                <DowntimePerEquipmentLegend avgDowntimePerEquipment={avgDowntimePerEquipment} />
                            </div>
                        </Col>

                        <Col xs={12} md={6}>
                            <div style={{ width: '80%', paddingTop: '0%', paddingLeft: '10%', position: 'relative' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <h2>Maintenance Statistics </h2>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px', // matching the chart's height

                                }}>
                                    <Table
                                        striped
                                        bordered
                                        style={{
                                            width: Math.min(isPrinting ? 400 : window.innerWidth, 550)
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <th>Average Maintenance Duration</th>
                                                <td>{avgMaintenanceDuration.toFixed(2)} days</td>
                                            </tr>
                                            <tr>
                                                <th>Longest Maintenance Duration</th>
                                                <td>{longestMaintenanceDuration} days</td>
                                            </tr>
                                            <tr>
                                                <th>Shortest Maintenance Duration</th>
                                                <td>{shortestMaintenanceDuration} days</td>
                                            </tr>
                                            <tr>
                                                <th>Total Downtime</th>
                                                <td>{totalDowntime} days</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </div>



            </Container>
        </div>
    );
};

export default MaintenanceReports;
