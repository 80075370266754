import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Table, Form, Container, Col, Row, Spinner } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './ViewRecords.css';
import ReactToPrint from 'react-to-print';
import logoImage from '../../Nucleus Logo PPTX.png';
import { useNavigate } from 'react-router-dom';

const ApproveRecords = () => {
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [filters, setFilters] = useState({});
    const [company, setCompany] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [approving, setApproving] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecords = async () => {
            setIsLoading(true);
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setError('User not authenticated.');
                setIsLoading(false);
                return;
            }

            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            if (!userSnapshot.exists) {
                setError('Unable to fetch user data.');
                setIsLoading(false);
                return;
            }

            const fetchedCompany = userSnapshot.data().company;
            setCompany(fetchedCompany);
            if (!fetchedCompany) {
                setError('Company not set.');
                setIsLoading(false);
                return;
            }

            const queryRef = query(collection(firestore, `companies/${fetchedCompany}/popCustomForms`), where("approved", "==", false));
            const recordsSnapshot = await getDocs(queryRef);
            setRecords(recordsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            setIsLoading(false);
        };

        fetchRecords();
    }, []);

    useEffect(() => {
        setFilteredRecords(records.filter(record =>
            Object.entries(filters).every(([k, v]) =>
                record[k]?.toString().toLowerCase().includes(v.toLowerCase()))
        ));
    }, [records, filters]);

    useEffect(() => {
        const filtered = records.filter(record =>
            Object.entries(filters).every(([k, v]) =>
                record[k]?.toString().toLowerCase().includes(v.toLowerCase()))
        );
        setFilteredRecords(filtered);
    }, [records, filters]);

    const approveRecord = async (recordId) => {
        setApproving(prev => ({ ...prev, [recordId]: true }));
        const recordRef = doc(firestore, `companies/${company}/popCustomForms`, recordId);
        await updateDoc(recordRef, { approved: true });
        setRecords(prevRecords => prevRecords.filter(record => record.id !== recordId));
        setApproving(prev => ({ ...prev, [recordId]: false }));
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleSelectRecord = (record) => {
        setSelectedRecord(record);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const formatDate = (timestamp) => {
        if (timestamp && typeof timestamp.toDate === 'function') {
            return timestamp.toDate().toLocaleString();
        } else if (timestamp) {
            return new Date(timestamp).toLocaleString();
        }
        return 'N/A';
    };

    const columns = [
        { key: 'createdAt', label: 'Date' },
        { key: 'formId', label: 'Form ID' },
        { key: 'id', label: 'Record Name' },
        { key: 'supporting', label: 'View Supporting' },
        { key: 'approve', label: 'Approve' }
    ];

    if (isLoading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container>
            <h1>Approve Records</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {columns.map(({ key, label }) => (
                            <th key={key}>{label}</th>
                        ))}

                    </tr>
                </thead>
                <tbody>
                    {filteredRecords.map((record, index) => (
                        <tr key={index}>
                            <td>{formatDate(record.createdAt)}</td>
                            <td>{record.formId}</td>
                            <td>{record.id}</td>
                            <td>
                                <Button size="sm" variant="success" onClick={() => handleSelectRecord(record)}>View Supporting</Button>
                            </td>
                            <td>
                                <Button size="sm" onClick={() => approveRecord(record.id, record.company)} disabled={approving[record.id]}>
                                    {approving[record.id] ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Approve'}
                                </Button>
                            </td>
                            
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Record Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRecord && (
                        <>
                            <p><strong>Form:</strong> {selectedRecord.formId}</p>
                            <p><strong>Record Number:</strong> {selectedRecord.id}</p>
                            <p><strong>Created:</strong> {formatDate(selectedRecord.createdAt)}</p>
                            <p><strong>Data:</strong></p>
                            <ul>
                                {Object.entries(selectedRecord)
                                    .filter(([key]) => !['id', 'formId', 'createdAt', 'seq', 'fileURLs'].includes(key))
                                    .map(([key, value], index) => (
                                        <li key={index}><strong>{key}:</strong> {value.toString()}</li>
                                    ))
                                }
                            </ul>
                            {selectedRecord.fileURLs && (
                                <div>
                                    <p><strong>Documents:</strong></p>
                                    {Object.entries(selectedRecord.fileURLs).map(([key, url], index) => (
                                        <Button
                                            variant="link"
                                            key={index}
                                            onClick={() => window.open(url, '_blank')}>
                                            Show Document {index + 1}
                                        </Button>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Button variant="outline-success" className="RecordBackButton" onClick={() => navigate('/recordlogging')} style={{ marginLeft: '10px', margin: '15px' }}>
                Back
            </Button>

        </Container>

    );
};

export default ApproveRecords;