import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { Button, Table, Container, Modal, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';

const ApproveScheduledMaintenance = () => {
    const [records, setRecords] = useState([]);
    const { role, hasRole } = useRole();
    const navigate = useNavigate();
    const [recordGroups, setRecordGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoadingReject, setIsLoadingReject] = useState({});


    const fetchUnapprovedRecords = async () => {
        setLoading(true);
        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(company);
                const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

                const snapshot = await maintenanceLogsRef
                    .where('approved', '==', false)
                    .where('scheduled', '==', true)
                    .where('fullyApproved', '==', false)
                    .where('logmaintenance', '==', true)
                    .where('ScheduledApproved', '==', false)
                    .where('rejected', '==', false)
                    .get();

                const fetchedRecords = snapshot.docs.map(doc => {
                    let record = doc.data();
                    if (record.plannedDate?.toDate) {
                        record.plannedDate = record.plannedDate.toDate().toLocaleDateString();
                    }
                    return { ...record, id: doc.id };
                });

                // Group records by equipmentNumber, activity, and routineInterval
                const groups = fetchedRecords.reduce((acc, record) => {
                    const key = `${record.equipmentNumber}_${record.activity}_${record.routineInterval || 'N/A'}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(record);
                    return acc;
                }, {});

                // Save the grouped records to the state
                setRecordGroups(Object.entries(groups));
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };


    

    const approveRecordGroup = async (groupKey) => {
        setIsLoadingApprove(prevState => ({ ...prevState, [groupKey]: true }));
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;

            const companyRef = firestore.collection('companies').doc(company);
            const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

            const batch = firestore.batch();

            // Get all records in the group
            const group = recordGroups.find(([key]) => key === groupKey)[1];
            group.forEach((record) => {
                const docRef = maintenanceLogsRef.doc(record.id);
                batch.update(docRef, { ScheduledApproved: true, rejected: false, comment: '' }); // Updated here
            });

            await batch.commit();
            fetchUnapprovedRecords();
        }
        setIsLoadingApprove(prevState => ({ ...prevState, [groupKey]: false }));
    };

    const approveAll = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;

            const companyRef = firestore.collection('companies').doc(company);
            const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

            const batch = firestore.batch();
            records.forEach((record) => {
                const docRef = maintenanceLogsRef.doc(record.id);
                batch.update(docRef, { approved: true });
            });

            await batch.commit();
            fetchUnapprovedRecords();
            setIsLoadingDelete(false);
        }
    };

    const rejectRecordGroup = async (groupKey, comment) => { // Updated here
        setIsLoadingReject(prevState => ({ ...prevState, [groupKey]: true }));
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;
            const companyRef = firestore.collection('companies').doc(company);
            const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

            const batch = firestore.batch();

            // Get all records in the group
            const group = recordGroups.find(([key]) => key === groupKey)[1];
            group.forEach((record) => {
                const docRef = maintenanceLogsRef.doc(record.id);
                batch.update(docRef, { ScheduledApproved: false, rejected: true, comment: comment }); // Updated here
            });

            await batch.commit();
            fetchUnapprovedRecords();
        }
        setIsLoadingReject(prevState => ({ ...prevState, [groupKey]: false }));
    };


    const deleteRecordGroup = async (groupKey) => {
        setIsLoadingDelete(prevState => ({ ...prevState, [groupKey]: true }));
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;

            const companyRef = firestore.collection('companies').doc(company);
            const maintenanceLogsRef = companyRef.collection('maintenanceLogs');

            const batch = firestore.batch();

            // Get all records in the group
            const group = recordGroups.find(([key]) => key === groupKey)[1];
            group.forEach((record) => {
                const docRef = maintenanceLogsRef.doc(record.id);
                batch.delete(docRef);
            });

            await batch.commit();
            fetchUnapprovedRecords();
        }
        setIsLoadingDelete(prevState => ({ ...prevState, [groupKey]: false }));
    };



    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isLoadingApprove, setIsLoadingApprove] = useState({});
    const [isLoadingDelete, setIsLoadingDelete] = useState({});
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectComment, setRejectComment] = useState('');
    const [selectedGroupKey, setSelectedGroupKey] = useState(null);

    const handleRejectModalClose = () => setShowRejectModal(false);
    const handleRejectModalOpen = (groupKey) => {
        setSelectedGroupKey(groupKey);
        setShowRejectModal(true);
    };
    const handleRejectConfirm = () => {
        if (selectedGroupKey) {
            setIsLoadingReject(prevState => ({ ...prevState, [selectedGroupKey]: true }));
            rejectRecordGroup(selectedGroupKey, rejectComment);
            setShowRejectModal(false); // This will close the modal after rejection
        }
    };



    const openEditModal = (record) => {
        setCurrentRecord(record);
        setIsEditModalOpen(true);
    };

    const handleEdit = async () => {
        if (currentRecord) {
            const recordRef = firestore
                .collection('companies')
                .doc(currentRecord.companyId)
                .collection('maintenanceLogs')
                .doc(currentRecord.id);

            await recordRef.update({
                activity: currentRecord.activity,
                // Add other fields to update here...
            });

            setIsEditModalOpen(false);
            fetchUnapprovedRecords();
        }
    };
    



    useEffect(() => {
        fetchUnapprovedRecords();
    }, []);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <>

    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                <h1 className="mb-5">Approve Scheduled Maintenance</h1>
          <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
            <Table striped bordered hover className="my-3">
                <thead>
                    <tr>
                        <th>Equipment Number</th>
                        <th>Frequency</th>
                                <th>Activity</th>
                                <th>Employee</th>
                            <th>Date</th>
                                <th>Unapproved Records</th>
                                
                        {hasRole('Admin') && <th>Approve</th>}
                            {hasRole('Admin') && <th>Delete</th>}
                            {hasRole('Admin') && <th>Reject</th>}


                    </tr>
                </thead>
                    <tbody>
                        {recordGroups.map(([key, group]) => (
                            <tr key={key}>
                                <td>{group[0].equipmentNumber}</td>
                                <td>{group[0].routineMaintenance ? group[0].routineInterval : 'N/A'}</td>
                                <td>{group[0].activity}</td>
                                <td>{group[0].employee || 'N/A'}</td>
                                <td>{group[0].date.toDate().toLocaleDateString()}</td>
                                <td>{group.length}</td>
                                {role === 'Admin' && (
                                    <td>
                                        <Button variant="success" onClick={() => approveRecordGroup(key)}>
                                            {isLoadingApprove[key] ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            ) : 'Approve'}
                                        </Button>
                                    </td>
                                )}
                                {role === 'Admin' && (
                                    <td>

                                        <Button variant="danger" onClick={() => deleteRecordGroup(key)}>
                                            {isLoadingDelete[key] ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    <span className="sr-only">Loading...</span>
                                                </>
                                            ) : 'Delete'}
                                        </Button>
                                    </td>
                                )}
                                {role === 'Admin' && (
                                    <td>
                                        <Button variant="danger" onClick={() => handleRejectModalOpen(key)}>
                                            Reject
                                        </Button>
                                    </td>
                                )}

                            </tr>
                    ))}
                            

                            {recordGroups.length == 0 && (
                                <tr>
                                    <td colSpan={8}>No unapproved records</td>
                                </tr>
                            )}
                </tbody>

                    </Table>
          </div>
                <Modal show={showRejectModal} onHide={handleRejectModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reject</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control type="text" placeholder="Enter comment here" onChange={e => setRejectComment(e.target.value)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleRejectConfirm}>
                            {isLoadingReject[selectedGroupKey] ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="sr-only">Loading...</span>
                                </>
                            ) : 'Confirm'}
                        </Button>
                    </Modal.Footer>

                </Modal>

            <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')}>
                Back to Maintenance
            </Button>
        </Container>
    



            

       </>
    );

};

export default ApproveScheduledMaintenance;
