import React, { useState, useEffect, useRef } from 'react';
import { firestore, firebaseApp } from '../utils/firebase';
import firebase from "firebase/compat/app";
import { Button, Table, Container, Form, Spinner, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import ReactToPrint from 'react-to-print';
import logoImage from '../../Nucleus Logo PPTX.png';
import './ShowPlannedMaintenance.css'



const ShowPlannedMaintenance = () => {
    const [records, setRecords] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [equipmentFilter, setEquipmentFilter] = useState('');
    const navigate = useNavigate();
    const [equipmentNumbers, setEquipmentNumbers] = useState([])
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeFilter, setEmployeeFilter] = useState('');


    // Initial fetch
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchEquipmentNumbers();
            await fetchEmployees();
            setLoading(false);
        }

        fetchData().catch(error => console.error(error));
    }, []);

    const fetchEquipmentNumbers = async () => {
        setLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        // Fetch the company related to the user
        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const equipmentRef = firestore.collection('companies').doc(company).collection('equipment');

        try {
            const snapshot = await equipmentRef.get();
            const fetchedEquipmentNumbers = snapshot.docs.map(doc => doc.data().equipmentNumber);  // update this line based on your Firestore data structure
            setEquipmentNumbers(fetchedEquipmentNumbers);  // update the state
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false when fetching is complete
        }
    };

    const fetchRecords = async (isFiltered) => {
        if (!isFiltered) {
            // If not explicitly filtering, do not fetch any records.
            console.log("No fetch due to lack of filtering");
            return;
        }

        setLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;
        let fetchedRecords = [];

        if (!currentUser) {
            setLoading(false);
            return;
        }

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;
        const companyRef = firestore.collection('companies').doc(company);
        const maintenanceRecordsRef = companyRef.collection('maintenanceLogs');
        let query = maintenanceRecordsRef
            .where('approved', '==', false)
            .where('scheduled', '==', true)
            .where('fullyApproved', '==', false)
            .where('ScheduledApproved', '==', true);

        if (isFiltered) {
            if (equipmentFilter === 'all') {
                // If 'all' is selected, do not filter by equipmentNumber
            } else if (equipmentFilter) {
                query = query.where('equipmentNumber', '==', equipmentFilter);
            }

            if (employeeFilter) {
                query = query.where('employeeId', '==', employeeFilter);  // Adjust according to your actual field name in Firestore
            }


            if (startDate) {
                const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate));
                query = query.where('date', '>=', startTimestamp);
            }

            if (endDate) {
                const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate));
                query = query.where('date', '<=', endTimestamp);
            }
        }

        try {
            const snapshot = await query.get();
            fetchedRecords = snapshot.docs.map(doc => {
                let record = doc.data();
                if (record.date && record.date.toDate) {
                    record.dDate = record.date.toDate();
                }
                return { ...record, id: doc.id };
            }).sort((a, b) => a.date - b.date);

        } catch (error) {
            console.error(error);
        } finally {
            setRecords(fetchedRecords);
            setLoading(false);
        }
        console.log('Query results:', fetchedRecords);
    };



    // This will run when Fetch Records button is clicked
    const fetchEmployees = async () => {
        setLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const userQuerySnapshot = await firestore.collection('users').where('company', '==', company).get();
        const employees = userQuerySnapshot.docs.map(doc => ({
            value: doc.id,
            label: `${doc.data().name} ${doc.data().surname}`
        }));

        console.log("Loaded Employees:", employees);  // Log loaded employee data
        setEmployeeList(employees);
        setLoading(false);
    };

    const filterRecords = (event) => {
        event.preventDefault();
        console.log("Filtering with:", startDate, endDate, equipmentFilter, employeeFilter);  // Log filter parameters
        if (startDate || endDate || equipmentFilter || employeeFilter) {
            fetchRecords(true);
        } else {
            console.log("Filter conditions are not fully specified.");
        }
    };


    useEffect(() => {
        const fetch = async () => {
            await fetchEquipmentNumbers();
            await fetchEmployees();  // add this line
            await fetchRecords(false);
        }

        fetch().catch(error => console.error(error));
    }, []);


    // The handleStartDateChange and handleEndDateChange will only set the dates without triggering the fetch function.
    const handleStartDateChange = (event) => {
        console.log('Start Date change event: ', event);  // Log event
        setStartDate(new Date(event.target.value + 'T00:00:00'));
    };

    const handleEndDateChange = (event) => {
        console.log('End Date change event: ', event);  // Log event
        setEndDate(new Date(event.target.value + 'T23:59:59'));
    };

    
    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div>

            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
                <h1 className="mb-5">View Planned Maintenance</h1>
                {loading && (
                    <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}
                {!loading && (
                    <>
                        <Form className="mb-3" onSubmit={filterRecords}>
                            {/* Start and End Date */}
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={startDate ? startDate.toISOString().substring(0, 10) : ''} // format the date to YYYY-MM-DD
                                            onChange={handleStartDateChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={endDate ? endDate.toISOString().substring(0, 10) : ''} // format the date to YYYY-MM-DD
                                            onChange={handleEndDateChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Equipment and Employee Filters */}
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Equipment Filter</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={equipmentFilter}  // Ensures the selected value remains after filtering
                                            onChange={e => setEquipmentFilter(e.target.value)}
                                        >
                                            <option value="">-- Select Equipment Number --</option>
                                            <option value="all">All</option>
                                            {equipmentNumbers.map((number, index) => (
                                                <option key={index} value={number}>
                                                    {number}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Employee Filter</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={employeeFilter}  // This makes the dropdown a controlled component
                                            onChange={e => setEmployeeFilter(e.target.value)}  // Correctly updates state on change
                                        >
                                            <option value="">-- Select Employee --</option>
                                            {employeeList.map((employee, index) => (
                                                <option key={index} value={employee.value}>
                                                    {employee.label}
                                                </option>
                                            ))}
                                        </Form.Control>

                                    </Form.Group>

                                </Col>
                            </Row>

                            {/* Buttons */}
                            <Row className="mb-4">
                                {/* Search Button */}
                                <Col xs={12} md={4} className="mb-3">
                                    <Button variant="success" type="submit" style={{ width: '175px' }}>
                                        Search
                                    </Button>
                                </Col>

                                {/* Print Report Button */}
                                <Col xs={12} md={4} className="mb-3">
                                    <ReactToPrint
                                        trigger={() => <Button variant="success" style={{ width: '175px' }}>Print Report</Button>}
                                        content={() => componentRef.current}
                                    />
                                </Col>

                                {/* Back to Maintenance Button */}
                                <Col xs={12} md={4} className="mb-3">
                                    <Button
                                        variant="outline-success"
                                        onClick={() => navigate('/equipment-maintenance')}
                                        style={{ width: '175px' }}
                                    >
                                        Back to Maintenance
                                    </Button>
                                </Col>

                                
                            </Row>

                        </Form>
                        <div style={{ overflowX: 'auto', maxWidth: '100vw' }} ref={componentRef}>
                                <div className="print-only">
                                    <Row className="align-items-center">
                                        <Col xs={1}>
                                            <img src={logoImage} alt="Company Logo" className="logo" />
                                        </Col>
                                    <Col xs={9}>
                                        <h1 className="mb-5">Planned Maintenance Report</h1>
                                        {equipmentFilter && !employeeFilter && <h2>Equipment Number: {equipmentFilter}</h2>}

                                        {!equipmentFilter && employeeFilter && <h2>Employee: {employeeFilter} - Scheduled work</h2>}

                                        {equipmentFilter && employeeFilter && (
                                            <>
                                                <h2>Equipment Number: {equipmentFilter}</h2>
                                                <h2>Employee: {employeeFilter} - Scheduled work</h2>
                                            </>
                                        )}


                                    </Col>
                                        <Col xs={2}>
                                            <p>Date from: {startDate ? startDate.toLocaleDateString() : null}</p>
                                            <p>Date to: {endDate ? endDate.toLocaleDateString() : null}</p>
                                        </Col>
                                    </Row>
                                </div>

                                <Table striped bordered hover className="mt-4">
                                    <thead>
                                        <tr>
                                            <th>Equipment Number</th>
                                            <th> Date</th>
                                            <th>Maintenance Type</th>
                                            <th>Activity</th>
                                        <th>Vendor</th>
                                        <th>Employee</th>
                                            <th>Routine Maintenance</th>
                                            <th>Maintenance Frequency</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    {records === null ? (
                                        <tr>
                                            <td colSpan="8" className="text-center">Select filters to view records</td>
                                        </tr>
                                    ) : records.length > 0 ? (
                                        records.map((record, index) => (
                                            <tr key={index}>
                                                <td>{record.equipmentNumber}</td>
                                                <td>{record.date ? record.date.toDate().toLocaleDateString() : 'N/A'}</td>
                                                <td>{record.maintenanceType}</td>
                                                <td>{record.activity}</td>
                                                <td>{record.vendor}</td>
                                                <td>{record.employee}</td>
                                                <td>{record.routineMaintenance ? "Yes" : "No"}</td>
                                                <td>{record.routineInterval}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">No records found</td>
                                        </tr>
                                    )}
                                </tbody>
                                    </Table>
                            </div>
                        
                    </>
                )}
                </Container>
        </div>

    );
};

export default ShowPlannedMaintenance;
