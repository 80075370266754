import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRole from '..//src/components/hooks/useRole';

const Welcome = () => {
    const navigate = useNavigate();
    const { role } = useRole(); // Use the hook

    // Watch for role change, if it's loaded and not null, then navigate user
    useEffect(() => {
        if (role) {
            if (role === 'Admin') {
                navigate("/welcome");
            } else if (role === 'User') {
                navigate("/welcome");
            }
        }
    }, [role, navigate]);

    return (
        <Modal.Dialog centered>
            <Modal.Header>
                <Modal.Title>Welcome to Nucleus</Modal.Title>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="success" onClick={() => navigate("/user-guide")}>View User Guide</Button>
                <Button variant="success" onClick={() => navigate("/dashboard")}>Use Nucleus Now</Button>
            </Modal.Footer>
        </Modal.Dialog>
    );
};

export default Welcome;
