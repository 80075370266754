import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Spinner, Col, Row } from 'react-bootstrap';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import useRole from './components/hooks/useRole';
import '../src/Spinner.css';
import logoImage from './Nucleus Logo.svg';
import './Dashboard.css';

function Dashboard() {

    const navigate = useNavigate();
    const { role, hasRole, loading } = useRole();

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    const handleSignOut = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            navigate("/"); // navigate to the homepage after successful logout
        } catch (error) {
            console.error("Error signing out", error);
        }
    };
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };

    return (
        <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "75vh" }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-2">Dashboard</h1>
            <div className="w-50 text-center mt-5">
                <Row className="mb-2">
                    <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px'}}>
                        <Link to="/equipment-maintenance">
                            <Button variant="outline-success" className="w-100 m-2" >Equipment Maintenance</Button>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                        <Link to="/lessonslearned">
                            <Button variant="outline-success" className="w-100 m-2"  >Lessons Learned</Button>
                        </Link>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                        <Link to="/recordlogging">
                            <Button variant="outline-success" className="w-100 m-2" >Record Logging</Button>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                        <Link to="/trainingmaterials">
                            <Button variant="outline-success" className="w-100 m-2" >Training Materials</Button>
                        </Link>
                    </Col>
                    
                </Row>
                <Row className="mb-2">
                    
                    <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                        <Link to="/employees">
                            <Button variant="outline-success" className="w-100 m-2">Employee Details</Button>
                        </Link>
                    </Col>
                    {role === 'Admin' && (
                        <>
                            <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                                <Link to="/add-equipment">
                                    <Button variant="outline-success" className="w-100 m-2" >Add Equipment</Button>
                                </Link>
                            </Col>
                        </>
                    )}


                </Row>

                {role === 'Admin' && (
                    <>
                        <Row className="mb-2">
                            
                            
                            <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                                <Link to="/approve-users">
                                    <Button variant="outline-success" className="w-100 m-2" >Approve Users</Button>
                                </Link>
                            </Col>
                            <Col xs={12} md={6} style={{ paddingRight: '5px', paddingLeft: '5px', marginBottom: '15px' }}>
                                <Button variant="outline-danger" className="w-100 m-2" onClick={handleSignOut} >Logout</Button>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            
                           
                        </Row>
                    </>
                )}
            </div>

        </Container>

    );
}

export default Dashboard;