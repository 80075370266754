import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Modal, Button, Spinner, Card } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './ViewForms.css'
import useRole from '../hooks/useRole'; // Assume this is the correct path



const ViewForms = () => {
    const [forms, setForms] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRecords, setIsLoadingRecords] = useState(false);
    const { role, hasRole, loading: isLoadingRole } = useRole();

    useEffect(() => {
        const fetchForms = async () => {
            setIsLoadingRecords(true);
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setIsLoading(false);
                return;
            }

            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const company = userSnapshot.data().company;

            if (!company) {
                setIsLoading(false);
                return;
            }

            try {
                const querySnapshot = await firestore.collection(`companies/${company}/customForms`).get();
                const fetchedForms = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    // Format the createdAt timestamp to a readable format
                    const createdAt = data.createdAt?.toDate().toLocaleDateString("en-US");
                    return { id: doc.id, ...data, createdAt };
                });                setForms(fetchedForms);
            } catch (error) {
                console.error("Error fetching forms:", error);
            }
            setIsLoadingRecords(false);
            setIsLoading(false);
        };

        fetchForms();
    }, []);


    const handleSelectForm = (form) => {
        setSelectedForm(form);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    if (isLoadingRole || isLoadingRecords) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <Container className="Container">
            

            <h1>View Custom Forms</h1>
            <div className="Cards ">
                {forms.map((form) => (
                    <Card
                        className="Forms"
                        key={form.id}
                        onClick={() => handleSelectForm(form)}
                    >
                        <Card.Body>
                            <Card.Title>{form.name}</Card.Title>
                            <Card.Text>
                            <Card.Text>
                                Created by: {form.createdBy}<br/>
                                Created: {form.createdAt}
                            </Card.Text>
                            </Card.Text>
                            <Button variant="success" onClick={() => navigate(`/logrecords/${form.id}`)}>Use Form</Button>
                        </Card.Body>
                    </Card>
                ))}
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedForm?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedForm && selectedForm.fields.map((field, index) => (
                        <div key={index}>
                            <strong>{field.name}:</strong> {field.type}
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={() => navigate(`/logrecords/${selectedForm.id}`)}>Use Form</Button>
                </Modal.Footer>
            </Modal>
            <Button variant="outline-success" className="RecordBackButton" onClick={() => navigate('/recordlogging')} style={{  margin: '15px' }}>
                Back
            </Button>
        </Container>
    );
};

export default ViewForms;
