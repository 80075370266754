import React, { useState, useEffect } from 'react';
import { Button, Form, Container, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { getAuth } from 'firebase/auth';
import './Customform.css'


const Customforms = ({ companyId }) => { // Assume companyId is passed as a prop or fetched based on user authentication
    const [formName, setFormName] = useState('');
    const [fields, setFields] = useState([{ name: '', type: 'text' }]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);  // New state variable for loading indicator
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [isApprovalRequired, setIsApprovalRequired] = useState('no'); // default to 'no'


    const addField = () => {
        setFields([...fields, { name: '', type: 'text' }]);
    };

    const deleteField = (indexToDelete) => {
        setFields(fields.filter((_, index) => index !== indexToDelete));
    };

    const updateFieldName = (index, name) => {
        const newFields = [...fields];
        newFields[index].name = name;
        setFields(newFields);
    };

    const updateFieldType = (index, type) => {
        const newFields = [...fields];
        newFields[index].type = type;
        setFields(newFields);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                if (userSnapshot.exists) {
                    const userData = userSnapshot.data();
                    setUserName(userData.name); // Assuming 'name' is the field for the user's name
                    setUserSurname(userData.surname); // Assuming 'surname' is the field for the user's surname
                    setLoading(false); // Indicate that loading is complete
                }
            }
        };

        fetchEmployees();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialize `auth` and `currentUser` at the beginning of your function
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser && userName && userSurname) {
            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            const companyName = userSnapshot.data().company;

            try {
                const createdAt = new Date(); // Capture the current date and time
                const createdBy = `${userName} ${userSurname}`; // Combine name and surname for the createdBy field

                const newForm = {
                    name: formName,
                    fields,
                    createdAt,
                    createdBy,
                    isApprovalRequired,
                };


                await firestore
                    .collection(`companies/${companyName}/customForms`)
                    .doc(formName) // Use formName as the document ID
                    .set(newForm);

                navigate('/viewcustomforms');
            } catch (error) {
                console.error('Error creating form:', error);
            }
        } else {
            console.log("No current user or user details found.");
        }
    };


    return (
        <Container className="Container">
            <h1>Create Custom Form</h1>
            <Form className="Form" onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3 ">
                <Row>
                        <Col md={6} sm={4} lg={6}>
                            <Form.Label className="FormName" required >Form Name:</Form.Label>
                    </Col>
                    

                        <Col md={6} sm={4} lg={6}>
                            <Form.Control className="FormNameEnter" type="text" placeholder="Enter form name" value={formName} onChange={(e) => setFormName(e.target.value)} required />
                    </Col>
                </Row>
                    <Row>
                        <Col md={6} sm={4} lg={6}>
                                <Form.Label className="FormName" required >
                                Is Approval Required:
                                </Form.Label>
                            </Col>
                        <Col md={6} sm={4} lg={6}>
                                <Form.Select className="FormYNSelect"
                                    value={isApprovalRequired}
                                    onChange={(e) => setIsApprovalRequired(e.target.value)}
                                    required
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </Form.Select>
                            </Col>
                    </Row>
                </Form.Group>
                {fields.map((field, index) => (
                    <InputGroup className="mb-2" key={index}>

                        <FormControl
                            className="FormControl"
                            placeholder="Field Name"
                            value={field.name}
                            onChange={(e) => updateFieldName(index, e.target.value)}
                            required
                        />
                        <Form.Select
                            className="FormSelect"
                            value={field.type}
                            onChange={(e) => updateFieldType(index, e.target.value)}
                            required
                        >
                            <option value="text">Text</option>
                            <option value="number">Number</option>
                            <option value="file">File</option>
                        </Form.Select>
                        <Button variant="outline-danger" className="Delete" onClick={() => deleteField(index)}>X</Button>
                    </InputGroup>
                ))}
                <Row>
                    <Col >
                        <Button variant="primary" onClick={addField} className="mb-3 w-30">Add Field</Button>
                    </Col>
                    <Col >
                        <Button variant="success" type="submit" className="mb-3 w-30">Create Form</Button>
                    </Col >
                </Row>

            </Form>
            <Button variant="outline-success" className="RecordBackButton" onClick={() => navigate('/recordlogging')} style={{ marginLeft: '10px', margin: '15px' }}>
                Back 
            </Button>
        </Container>

    );
};

export default Customforms;
