import OpenAI from 'openai';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true  // Only use this for development on localhost
});

export const askChatGPT = async (context, query, isConversational) => {
    try {
        let prompt = `${context} User Query: ${query}`;
        if (isConversational) {
            prompt += "\n\nPlease respond in a conversational manner, listing only the fault names without timestamps, solutions, or any special formatting.";
        } else {
            prompt += "\n\nPlease provide a concise and conversational response based on the provided data.";
        }

        const chatCompletion = await openai.chat.completions.create({
            model: "gpt-4o-2024-05-13",
            messages: [{ role: "user", content: prompt }],
            max_tokens: 500
        });
        return chatCompletion.choices[0].message.content.trim();
    } catch (error) {
        if (error instanceof OpenAI.APIError) {
            console.error(error.status);  // e.g., 401
            console.error(error.message); // e.g., "The authentication token you passed was invalid..."
            console.error(error.code);    // e.g., 'invalid_api_key'
            console.error(error.type);    // e.g., 'invalid_request_error'
        } else {
            // Non-API error handling
            console.error(error);
        }
    }
};



