import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { PieChart, Pie, Tooltip, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Cell, ScatterChart, Scatter, Label } from 'recharts';
import firebase from "firebase/compat/app";
import { Button, Container, Row, Col, Form, Table, Spinner } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import './LessonsLearntReport.css';
import logoImage from '../../Nucleus Logo PPTX.png';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';



// Helper function to count frequency
const countFrequency = (array) => {
    return array.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
    }, {});
};

const LessonsLearntReport = () => {
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [records, setRecords] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const componentRef = React.useRef();
    const role = useRole();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        fetchRecords();
    }, [startDate, endDate]);

    //fetchfunction

    const fetchRecords = async () => {
        setLoading(true);
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const companyRef = firestore.collection('companies').doc(company);
        let maintenanceLogsRef = companyRef.collection('maintenanceLogs');
        let faultRecordsRef = companyRef.collection('faultRecords');

        // Apply date filters to both collections if needed
        [faultRecordsRef, maintenanceLogsRef] = [faultRecordsRef, maintenanceLogsRef].map(ref => {
            if (startDate) {
                const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate));
                ref = ref.where('timestamp', '>=', startTimestamp);
            }
            if (endDate) {
                const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate));
                ref = ref.where('timestamp', '<=', endTimestamp);
            }
            return ref.where('approved', '==', true);
        });

        try {
            const faultRecordsSnapshot = await faultRecordsRef.get();
            const maintenanceLogsSnapshot = await maintenanceLogsRef.get();

            const faultRecords = faultRecordsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const maintenanceLogs = maintenanceLogsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            // Combining both fault and maintenance logs into the records state
            // Assuming you want them in the same array and will handle the difference in your logic later
            const allRecords = faultRecords.concat(maintenanceLogs);
            setRecords(allRecords);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // This will be executed no matter what, ensuring the spinner is always hidden.
        }
    };




    const handleDateChange = (event, setDate) => {
        setDate(new Date(event.target.value + 'T00:00:00'));
    };

    const handleEndDateChange = (event) => {
        setEndDate(new Date(event.target.value + 'T23:59:59'));
    };

    const lessonsData = Object.entries(countFrequency(records.filter(record => record.lessonType !== undefined).map(record => record.lessonType)))
        .map(([name, value]) => ({ name, value }));


    // Functional Code

    function countFrequency(arr) {
        const frequency = {};
        for (const item of arr) {
            frequency[item] = (frequency[item] || 0) + 1;
        }
        return frequency;
    }
    const filteredRecords = records.filter(record => record.lessonType !== undefined);
    const countOfAllLessons = filteredRecords.length;

    const lessonsPerEquipment = countFrequency(filteredRecords.map(record => record.equipmentNumber));

    const getTopUsers = (records) => {
        const usersCount = records.reduce((acc, record) => {
            if (typeof record.name === 'string' && record.name.trim() !== '') {
                const userKey = record.name + record.surname + record.employeeNumber;
                const userDetail = {
                    name: record.name,
                    surname: record.surname,
                    employeeNumber: record.employeeNumber,
                };
                acc[userKey] = {
                    count: (acc[userKey] ? acc[userKey].count : 0) + 1,
                    userDetail,
                };
            }
            return acc;
        }, {});

        return Object.entries(usersCount)
            .sort((a, b) => b[1].count - a[1].count)
            .slice(0, 5)
            .map(([key, { userDetail, count }]) => ({
                ...userDetail,
                lessons: count,
            }));
    };

    const topUsers = getTopUsers(records);

    // Prepare the data for the charts
    const COLORS = ['#0088FE', '#00C49F', '#ffa500 ', '#ff42c1', '#8884d8'];
    const [isPrinting, setIsPrinting] = useState(false);


    const CustomLegend = ({ equipmentColors, equipmentNumbers }) => (
        <div className="custom-legend-container" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {equipmentNumbers.map((equipmentNumber, index) => (
                <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                    <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: equipmentColors[equipmentNumber] }}></div>
                    <span style={{ fontSize: '14px' }}>{` ${equipmentNumber}`}</span>
                </div>
            ))}
        </div>
    );

    const CustomBarChartLegend = ({ lessonsPerEquipmentData }) => (
        <div className="custom-legend-container" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {lessonsPerEquipmentData.map((entry, index) => (
                    <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <div style={{ width: '10px', height: '10px', marginRight: '10px', backgroundColor: COLORS[index % COLORS.length] }}></div>
                        <span style={{ fontSize: '14px' }}>{`${entry.name}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );



    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }


    //Calculate Total Downtime 
    const totalDowntimePerEquipment = records
        .filter(record => record.downtime && !isNaN(record.downtime.quantity))
        .reduce((acc, curr) => {
            const equipmentNumber = curr.equipmentNumber;
            let downtime = parseFloat(curr.downtime.quantity);

            // Convert downtime to hours if it's in days
            if (curr.downtime.unit === 'days') {
                downtime *= 24;
            }

            if (!acc[equipmentNumber]) {
                acc[equipmentNumber] = 0;
            }

            acc[equipmentNumber] += downtime;
            return acc;
        }, {});

    const totalDowntimePerEquipmentArray = Object.entries(totalDowntimePerEquipment).map(
        ([equipmentNumber, totalDowntime]) => {
            return {
                equipmentNumber,
                totalDowntime
            };
        }
    );

    //Count Lessons Learnt per Equipement
    const lessonsPerEquipmentData = Object.entries(lessonsPerEquipment).map(([name, value]) => ({ name, value }));

    // Scatter plot data merge
    const scatterPlotData = totalDowntimePerEquipmentArray.map(item => {
        return {
            ...item,
            lessonsLearnt: lessonsPerEquipment[item.equipmentNumber] || 0
        };

    });

    console.log('Scatter Plot Data:', scatterPlotData); // Logging the data

    const dataByEquipment = scatterPlotData.reduce((acc, item) => {
        if (!acc[item.equipmentNumber]) {
            acc[item.equipmentNumber] = [];
        }
        acc[item.equipmentNumber].push(item);
        return acc;
    }, {});

    const equipmentNumbers = Object.keys(dataByEquipment);

    const equipmentColors = equipmentNumbers.reduce((acc, equipmentNumber, index) => {
        acc[equipmentNumber] = COLORS[index % COLORS.length];
        return acc;
    }, {});

    const legendPayload = equipmentNumbers.map((equipmentNumber, index) => ({
        value: equipmentNumber,
        color: equipmentColors[equipmentNumber],
        type: 'rect',
    }));




    return (
        <div>

            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '10vh' }}>
                <h1>Lessons Learned Report</h1>
                <Form className="mb-3">
                    <Form.Group className="mb-3">
                        <Row className="align-items-center ">
                            <Col>
                                <Form.Label>Start Date</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="date"
                                    value={startDate ? startDate.toISOString().split('T')[0] : ''}
                                    onChange={event => handleDateChange(event, setStartDate)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row className="align-items-center ">
                            <Col>
                                <Form.Label>End Date</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="date"
                                    value={endDate ? endDate.toISOString().split('T')[0] : ''}
                                    onChange={handleEndDateChange}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col>
                            <ReactToPrint
                                trigger={() => <Button variant="success" className="mr-3" >Print Report</Button>}
                                content={() => componentRef.current}  // <-- use ref here
                                onBeforeGetContent={() => setIsPrinting(true)}
                                onAfterPrint={() => setIsPrinting(false)}
                            />

                        </Col>
                        <Col>
                            <Button variant="outline-success" onClick={() => navigate('/lessonslearned')}>Back</Button>
                        </Col>
                    </Row>
                </Form>

            </Container>

            <Container className="heading" ref={componentRef}>
                <div mb-5>
                    <Row className="print-only align-items-center ">
                        <Col xs={1}>
                            <img src={logoImage} alt="Company Logo" className="print-only logo" />
                        </Col>
                        <Col xs={9}>
                            <h1 className="print-only mb-5">Lessons Learned Report</h1>

                        </Col>
                        <Col xs={2}>

                            <p className="print-only" >Date from: {startDate ? startDate.toLocaleDateString() : null}</p>
                            <p className="print-only" >Date to: {endDate ? endDate.toLocaleDateString() : null}</p>

                        </Col>
                    </Row>
                </div>

                <div className="chart-container">
                    <div className="chart-section">
                        <Row >
                            <Col xs={12} md={6}>
                                <div style={{ width: '90%', paddingTop: '5%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Lessons Learned by Type</h2>
                                    <PieChart
                                        className={isPrinting ? 'print-chart' : ''}
                                        width={Math.min(isPrinting ? 400 : window.innerWidth, 550)}
                                        height={300}
                                    >
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={lessonsData}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            label
                                            children={lessonsData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        />
                                        <Tooltip />
                                        <Legend
                                            layout="horizontal"
                                            verticalAlign="bottom"
                                            align="center"
                                            wrapperStyle={{ paddingTop: '20px' }} // Add top padding to the legend
                                        />

                                    </PieChart>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="chart-containerbar" style={{ width: '100%', paddingTop: '5%', paddingLeft: '0%', position: 'relative' }}>
                                    <h2 style={{ textAlign: 'center' }}>Total Number of Lessons Learned Per Equipment</h2>
                                    <BarChart
                                        data={lessonsPerEquipmentData}
                                        className={isPrinting ? "print-chart" : ""}
                                        width={Math.min(isPrinting ? 400 : window.innerWidth, 550)}
                                        height={300}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis domain={[0, 'dataMax+10']} />
                                        <Tooltip />

                                        <Bar dataKey="value">
                                            {lessonsPerEquipmentData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                    <CustomBarChartLegend lessonsPerEquipmentData={lessonsPerEquipmentData} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="table-section">
                        <Row >
                            <Col xs={12} md={6}>
                                <div style={{ width: '90%', paddingTop: '0%', paddingLeft: '10%', position: 'relative' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className="heading-print shorter-heading">Top 5 Users </h2>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        height: '250px' // match the height with your charts
                                    }}>
                                        <Table striped bordered hover className="mt-4">
                                            <thead>
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>Surname</th>
                                                    <th>Employee Number</th>
                                                    <th>Number of Lessons Learned</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topUsers.map((user, index) => (
                                                    <tr key={index}>
                                                        <td>{user.name}</td>
                                                        <td>{user.surname}</td>
                                                        <td>{user.employeeNumber}</td>
                                                        <td>{user.lessons}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={6}>
                                <div style={{ width: '90%', paddingTop: '0%', paddingLeft: '10%', position: 'relative' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className="heading-print shorter-heading">Statistics</h2>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        height: '250px' // match the height with your charts
                                    }}>
                                        <Table striped bordered hover className="mt-4">
                                            <thead>
                                                <tr>
                                                    <th>Statistic</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Count of All Lessons Learned</td>
                                                    <td>{countOfAllLessons}</td>
                                                </tr>
                                                {/* Add more statistics here as needed */}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="scatter-section">
                        <Row>
                            <div style={{ width: '90%', paddingTop: '0%', paddingLeft: '10%', position: 'relative' }}>
                                <h2 style={{ textAlign: 'center' }}>Total Downtime vs Lessons Learned</h2>
                            </div>
                            <div className="chart-container" style={{ width: '100%', paddingTop: '0%', paddingLeft: '0%', position: 'relative' }}>
                                <ScatterChart
                                    className={isPrinting ? 'print-chart' : ''}
                                    width={Math.min(isPrinting ? 800 : window.innerWidth, 1100)}
                                    height={300}
                                >
                                    <CartesianGrid />
                                    <XAxis dataKey="totalDowntime" name="Total Downtime" unit=" hours" type="number" domain={[0, 'dataMax+24']}>
                                        <Label value="Total Downtime (hours)" offset={-5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis dataKey="lessonsLearnt" name="Lessons Learned" domain={[0, 'dataMax+2']}>
                                        <Label value="Lessons Learnt" angle={-90} position='insideLeft' dy={50} offset={20} />
                                    </YAxis>

                                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                    <Scatter name="Equipment" data={scatterPlotData}>
                                        {scatterPlotData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={equipmentColors[entry.equipmentNumber]} />
                                        ))}
                                    </Scatter>
                                </ScatterChart>

                                <CustomLegend equipmentColors={equipmentColors} equipmentNumbers={equipmentNumbers} />
                            </div>
                        </Row>
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default LessonsLearntReport;
