import React, { useState, useEffect } from 'react';
import { Button, Form, Container, InputGroup, FormControl, Alert, Modal, ProgressBar } from 'react-bootstrap';
import { firestore } from '../utils/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, setDoc, serverTimestamp, query, orderBy, limit, collection, getDocs, addDoc, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './LogRecords.css';
import { storage } from '../utils/firebase';

const LogRecords = () => {
    const { formName } = useParams(); // Assuming formName is passed via URL params
    const navigate = useNavigate();
    const [form, setForm] = useState(null);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');
    const [company, setCompany] = useState('');
    const { formId } = useParams(); // Corrected from formName to formId to match routing and intended functionality
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileData, setFileData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [seq, setseq] = useState('');

    const handleModalClose = () => {
        setShowModal(false);
    };

   
    useEffect(() => {
        const fetchFormAndCompany = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setError('User not authenticated.');
                return;
            }

            const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
            if (!userSnapshot.exists) {
                setError('Unable to fetch user data.');
                return;
            }

            const company = userSnapshot.data().company;
            setCompany(company);

            // Ensure to use formId to fetch the correct form
            const formRef = doc(firestore, `companies/${company}/customForms/${formId}`);
            const formSnap = await getDoc(formRef);

            if (formSnap.exists()) {
                setForm(formSnap.data());
            } else {
                setError('Form does not exist.');
            }
        };

        fetchFormAndCompany();
    }, [formId]);

    const handleChange = (name, value, type = 'text') => {
        if (type === 'file') {
            // Handle file inputs separately
            setFileData({ ...fileData, [name]: value });
        } else {
            // Handle regular inputs
            setFormData({ ...formData, [name]: value });
        }
    };

    const resetForm = () => {
        setFormData({});
        setFileData({});
        setUploadProgress(0);
        setShowModal(false); // Close the modal
        // Add any additional state resets here
    };

    const fetchSeqNumber = async () => {
        if (!company || !formId) {
            console.error('Company or Form ID is not available.');
            return null;
        }

        const popCustomFormsCollectionPath = `companies/${company}/popCustomForms`;
        const q = query(collection(firestore, popCustomFormsCollectionPath), where("formId", "==", formId), orderBy("seq", "desc"), limit(1));
        const querySnapshot = await getDocs(q);
        let newSeq = 1; // Default to 0 if no records found
        querySnapshot.forEach((doc) => {
            newSeq = doc.data().seq + 1; // Increment seq by 1
        });

        return newSeq;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setUploadProgress(0);

        // Fetch the next sequence number
        const baseSeq = await fetchSeqNumber();
        if (!baseSeq) {
            setError('Failed to fetch sequence number.');
            return;
        }

        // Initialize an object to store URLs for uploaded files
        const uploadedFileURLs = {};

        // Handle file uploads with the base sequence number
        const uploadTasks = Object.entries(fileData).map(([fieldName, file], index) => new Promise(async (resolve, reject) => {
            if (!file) {
                resolve();
                return;
            }

            // Use the index to append to the filename to indicate the order of the file uploads
            const newFileName = `${formId}_${baseSeq}_Supporting_${index + 1}`;
            const storageRef = storage.ref(`${company}/${formId}_${baseSeq}/${newFileName}`);

            const uploadTask = storageRef.put(file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error('Error uploading file:', error);
                    setError(`Failed to upload file: ${fieldName}`);
                    reject(error);
                },
                async () => {
                    try {
                        const fileURL = await uploadTask.snapshot.ref.getDownloadURL();
                        uploadedFileURLs[fieldName] = fileURL;
                        resolve(fileURL); // Resolve the promise when the file is uploaded successfully
                    } catch (error) {
                        console.error('Error getting file URL:', error);
                        reject(error);
                    }
                }
            );
        }));

        try {
            // Wait for all file uploads to complete
            await Promise.all(uploadTasks);

            // Create the new record using the fetched sequence number
            const documentId = `${formId}_${baseSeq}`; // Document ID uses baseSeq since it represents the primary record
            const newRecord = {
                ...formData,
                fileURLs: uploadedFileURLs,
                createdAt: serverTimestamp(),
                seq: baseSeq,
                formId,
                approved: form.isApprovalRequired === 'yes' ? false : true
            };


            // Save the new record to Firestore
            const popCustomFormsCollectionPath = `companies/${company}/popCustomForms`;
            await setDoc(doc(firestore, popCustomFormsCollectionPath, documentId), newRecord);

            console.log('Record submitted successfully:', newRecord);
            resetForm();
            setShowModal(true); // Show success modal
        } catch (error) {
            console.error('Error submitting record:', error);
            setError('Failed to submit record.');
        }
    };



    if (error) return <Alert variant="danger">{error}</Alert>;
    if (!form) return <Container>Loading...</Container>;

    return (
        <Container>
            <h1>Log Record for "{formId}"</h1>
            <Form onSubmit={handleSubmit}>
                {form.fields.map((field, index) => (
                    <InputGroup className="mb-3" key={index}>
                        {field.type !== 'file' ? (
                            <FormControl
                                placeholder={field.name}
                                value={formData[field.name] || ''}
                                onChange={(e) => handleChange(field.name, e.target.value)}
                                required
                            />
                        ) : (
                            <FormControl
                                type="file"
                                onChange={(e) => handleChange(field.name, e.target.files[0], 'file')}
                                required
                            />
                        )}
                    </InputGroup>
                ))}
                <Button variant="success" type="submit">Submit Record</Button>
            </Form>
            {uploadProgress > 0 && (
                <ProgressBar className="progressbar" now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />
            )}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Record logged successfully!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={resetForm}>
                        Log another record
                    </Button>
                    <Button variant="success" onClick={() => navigate('/viewrecords')}>
                        View Records
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button variant="outline-success" className="RecordBackButton" onClick={() => navigate('/recordlogging')} style={{ marginLeft: '10px', margin: '15px' }}>
                Back
            </Button>
        </Container>

    );
};

export default LogRecords;


