import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Modal, Spinner, ProgressBar } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore, firebaseApp as firebase, storage } from '../utils/firebase';
import { getAuth } from 'firebase/auth';
import Select from 'react-select';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, Timestamp } from "firebase/firestore";

const LogMaintenanceRecord = () => {
    const [equipmentNumber, setEquipmentNumber] = useState(null);
    const [equipmentList, setEquipmentList] = useState([]);
    const [date, setDate] = useState('');
    const [maintenanceType, setMaintenanceType] = useState(null);
    const [activity, setActivity] = useState('');
    const [maintenanceDuration, setMaintenanceDuration] = useState({ quantity: '', unit: '' });
    const [downtime, setDowntime] = useState({ quantity: '', unit: '' });
    const [vendor, setVendor] = useState('');
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);  // New state variable for list of employees
    const [selectedEmployee, setSelectedEmployee] = useState(null);  // New state variable for selected employee
    const [loading, setLoading] = useState(true);  // New state variable for loading indicator

    useEffect(() => {
        const fetchEmployees = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const company = userSnapshot.data().company;
                const userQuerySnapshot = await firestore.collection('users').where('company', '==', company).get();

                const employees = userQuerySnapshot.docs.map(doc => ({ value: doc.id, label: `${doc.data().name} ${doc.data().surname}` }));
                setEmployeeList(employees);
                setLoading(false);  // Set loading to false once data has been loaded
            }
        };

        fetchEmployees();
    }, []);

    const maintenanceOptions = [
        { value: "Preventive Maintenance", label: "Preventive Maintenance - Routine checks and servicing" },
        { value: "Predictive Maintenance", label: "Predictive Maintenance - Real-time performance evaluation" },
        { value: "Corrective Maintenance", label: "Corrective Maintenance - Maintenance after fault detection" },
        { value: "Adaptive Maintenance", label: "Adaptive Maintenance - Changes to keep systems relevant" },
        { value: "Routine Maintenance", label: "Routine Maintenance - Regular cleaning, minor repairs, and inspections" },
        { value: "Emergency Maintenance", label: "Emergency Maintenance - Maintenance in response to urgent situations" },
        { value: "Reliability Centered Maintenance", label: "Reliability Centered Maintenance - Corporate-level maintenance strategy" },
        { value: "Condition-Based Maintenance", label: "Condition-Based Maintenance - Maintenance based on asset condition" },
        { value: "Risk-based Maintenance", label: "Risk-based Maintenance - Prioritize maintenance based on risk of failure" },
    ];

    const CustomOption = ({ innerProps, label, data }) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <div
                {...innerProps}
                onMouseOver={() => {
                    innerProps.onMouseOver && innerProps.onMouseOver();
                    setIsHovered(true);
                }}
                onMouseOut={() => {
                    innerProps.onMouseOut && innerProps.onMouseOut();
                    setIsHovered(false);
                }}
                style={{
                    cursor: 'pointer',
                    backgroundColor: innerProps.isFocused || isHovered ? '#f0f0f0' : null
                }}
            >
                <div>
                    <div>{data.value}</div>
                    <div style={{ color: 'grey', fontSize: '12px', textAlign: 'center' }}>{label}</div>
                </div>
            </div>
        );
    };




    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                    const company = userSnapshot.data().company;

                    const companyRef = firestore.collection('companies').doc(company);
                    const equipmentSnapshot = await companyRef.collection('equipment').get();

                    const equipments = equipmentSnapshot.docs.map((doc) => ({
                        value: doc.id,
                        label: doc.data().equipmentNumber,
                    }));

                    setEquipmentList(equipments);
                }
            } catch (error) {
                console.error("Error fetching equipments:", error);
            }
        };

        fetchEquipments();
    }, []);

    const resetForm = () => {
        setEquipmentNumber(null);
        setDate('');
        setMaintenanceType('');
        setActivity('');
        setMaintenanceDuration({ quantity: '', unit: '' });
        setDowntime({ quantity: '', unit: '' });
        setVendor('');
        setFile('');
        setUploadProgress(0);
        setShowModal(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
                const companyName = userSnapshot.data().company;

                const companyRef = firestore.collection('companies').doc(companyName);

                const storageRef = storage.ref();
                const fileRef = storageRef.child(`${equipmentNumber.value}-${activity}-${date}`);

                const uploadTask = fileRef.put(file);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        // Set upload progress
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error('Error uploading file:', error);
                        setIsSubmitting(false);
                    },
                    async () => {
                        const proofUrl = await fileRef.getDownloadURL();

                        const maintenanceRecord = {
                            activity,
                            approved: false,
                            date: Timestamp.fromDate(new Date(date)),
                            equipmentNumber: equipmentNumber.value,
                            fullyApproved: false,
                            logmaintenance: true,
                            maintenanceType,
                            maintenanceDuration,
                            downtime, // New field for downtime
                            scheduled: false,
                            vendor,
                            ScheduledApproved: false,
                            Confirmed: true,
                            rejected: false,
                            comment: 'none',
                            employee: selectedEmployee ? selectedEmployee.label : null,
                            proofUrl, // New field for proof of maintenance
                        };


                        await companyRef
                            .collection('maintenanceLogs')
                            .doc(`${equipmentNumber.value}-${activity}-${date}`)
                            .set(maintenanceRecord);

                        resetForm();
                        setIsSubmitting(false);
                        setShowModal(true);
                        setUploadProgress(0); // Reset the upload progress
                    }
                );

            } catch (error) {
                console.error('Error adding maintenance record: ', error);
                setIsSubmitting(false);
            }
        }
    };


    const handleModalClose = () => {
        setShowModal(false);
        navigate('/dashboard');
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-1">Log Maintenance Record</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="equipmentNumber" className="my-3">
                    <Form.Label>Equipment Number</Form.Label>
                    <Select
                        value={equipmentNumber}
                        onChange={(selectedOption) => setEquipmentNumber(selectedOption)}
                        options={equipmentList}
                        isSearchable
                        required
                    />
                </Form.Group>

                <Form.Group controlId="date" className="my-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" value={date} onChange={e => setDate(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="maintenanceType" className="my-3">
                    <Form.Label>Maintenance Type</Form.Label>
                    <Select
                        value={maintenanceOptions.find(option => option.value === maintenanceType)}
                        onChange={(selectedOption) => setMaintenanceType(selectedOption.value)}
                        options={maintenanceOptions}
                        isSearchable
                        required
                        components={{ Option: CustomOption }}
                    />
                </Form.Group>


                <Form.Group controlId="activity" className="my-3">
                    <Form.Label>Activity</Form.Label>
                    <Form.Control type="text" value={activity} onChange={e => setActivity(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="maintenanceDuration" className="my-3">
                    <Form.Label>Duration of Maintenance</Form.Label>
                    <div style={{ display: 'flex' }}>
                        <Form.Control
                            type="number"
                            min="0"
                            step="0.01"
                            style={{ marginRight: '10px' }}
                            value={maintenanceDuration.quantity}
                            onChange={e => setMaintenanceDuration({ ...maintenanceDuration, quantity: e.target.value })}
                            required
                        />
                        <Form.Control
                            as="select"
                            value={maintenanceDuration.unit}
                            onChange={e => setMaintenanceDuration({ ...maintenanceDuration, unit: e.target.value })}
                            required
                        >
                            <option value="" disabled selected>Select a unit</option>
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                        </Form.Control>
                    </div>
                </Form.Group>

                <Form.Group controlId="downtime" className="my-3">
                    <Form.Label>Downtime</Form.Label>
                    <div style={{ display: 'flex' }}>
                        <Form.Control
                            type="number"
                            min="0"
                            step="0.01"
                            style={{ marginRight: '10px' }}
                            value={downtime.quantity}
                            onChange={e => setDowntime({ ...downtime, quantity: e.target.value })}
                            required
                        />
                        <Form.Control
                            as="select"
                            value={downtime.unit}
                            onChange={e => setDowntime({ ...downtime, unit: e.target.value })}
                            required
                        >
                            <option value="" disabled selected>Select a unit</option>
                            <option value="days">Days</option>
                            <option value="hours">Hours</option>
                        </Form.Control>
                    </div>
                </Form.Group>

                <Form.Group controlId="employee" className="my-3">  {/* New Form Group for selecting employee */}
                    <Form.Label>Employee</Form.Label>
                    <Select
                        value={selectedEmployee}
                        onChange={(selectedOption) => setSelectedEmployee(selectedOption)}
                        options={employeeList}
                        isSearchable
                        isLoading={loading}  // Show loading indicator while fetching data
                        required
                    />
                </Form.Group>


                <Form.Group controlId="vendor" className="my-3">
                    <Form.Label>Vendor/Contractor</Form.Label>
                    <Form.Control type="text" value={vendor} onChange={e => setVendor(e.target.value)} required />
                </Form.Group>

                <Form.Group controlId="file">
                    <Form.Label>Upload Supporting Document</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        required
                    />
                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                    )}
                </Form.Group>

                <div className="my-3">
                    <Button variant="success" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                        ) : null}
                        <span style={{ visibility: isSubmitting ? 'hidden' : 'visible' }}>
                            Log Maintenance Record
                        </span>
                    </Button>
                    <Button variant="outline-success" onClick={() => navigate('/equipment-maintenance')} style={{ marginLeft: '10px', margin: '15px' }}>
                        Back to Maintenance
                    </Button>
                </div>
            </Form>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Maintenance record logged successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={resetForm}>
                        Log another record
                    </Button>
                    <Button variant="success" onClick={() => navigate('/equipment-maintenance')}>
                        Back to Maintenance
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default LogMaintenanceRecord;
