import { useLocation, Navigate, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './components/utils/firebase';
import HomePage from './HomePage';
import UserGuide from './components/UserGuide/UserGuide';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import Dashboard from './Dashboard';
import EquipmentMaintenance from './components/Equipment/EquipmentMaintenance';
import AddEquipment from './components/Equipment/AddEquipment';
import ViewMaintenanceRecord from './components/Equipment/ViewMaintenanceRecord';
import LogMaintenanceRecord from './components/Equipment/LogMaintenanceRecord';
import ScheduleMaintenance from './components/Equipment/ScheduleMaintenance';
import ApproveMaintenance from './components/Equipment/ApproveMaintenance';
import SearchEquipment from './components/Fault/SearchEquipment';
import ViewFaultRecord from './components/Fault/ViewFaultRecord';
import LogFaultRecord from './components/Fault/LogFaultRecord';
import ApproveRecords from './components/Admin/ApproveRecords';
import FaultSolution from './components/Fault/FaultSolution';
import ShowPlannedMaintenance from './components/Equipment/ShowPlannedMaintenance';
import Profile from './Profile';
import Nav from './Navigation';
import ApproveScheduledMaintenance from './components/Equipment/ApproveScheduledMaintenance';
import ApproveLessons from './components/Fault/ApproveLessons';
import AddEquipmentTraining from './components/Training/AddEquipmentTraining';
import ViewTrainingMaterial from './components/Training/ViewTrainingMaterial';
import ApprovalPending from './components/Admin/ApprovalPending';
import ApproveUsers from './components/Admin/ApproveUsers';
import UserContext from './/components/Admin/UserContext';
import { Spinner } from 'react-bootstrap';
import './App.css';
import RejectedMaintenance from './components/Equipment/RejectedMaintenance';
import Welcome from './Welcome';
import Reports from './components/reports/reports';
import MaintenanceReports from './components/reports/MaintenanceReports';
import LessonsLearntreport from './components/reports/LessonsLearntReport';
import AddEmployees from './components/Admin/addemployees';
import MultiLog from './components/Multilog/multilog';
import MultiView from './components/Multilog/multiview';
import Customform from './components/Custom/Customform';
import LogRecords from './components/Custom/LogRecords';
import Viewforms from './components/Custom/ViewForms';
import Viewrecords from './components/Custom/ViewRecords';
import Recordlogging from './components/Custom/Recordlogging';
import Training from './components/Training/TrainingDash';
import ApproveCustomRecords from './components/Custom/ApproveRecords';
import ChatBot from './components/Chatbot/ChatBot';
import ConfirmMaint from './components/Equipment/ConfirmMaintenance';

const CheckApproval = ({ children }) => {
    const userData = useContext(UserContext);
    if (!userData) {
        return <Navigate to="/signin" replace />;
    }
    if (userData?.role === 'User' && !userData?.approved) {
        return <Navigate to="/approval-pending" replace />;
    }
    return children;
};

function App() {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isUserApproved, setIsUserApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [isNewUser, setIsNewUser] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                setIsUserAuthenticated(true);
                const userRef = doc(firestore, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                const userData = userSnap.data();
                setIsUserApproved(userData?.approved || false);
                setUserData(userData);
                setIsNewUser(false);
            } else {
                setIsUserAuthenticated(false);
                setIsUserApproved(false);
                setUserData(null);
                setIsNewUser(false);
            }
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Replace this with your loading spinner
    }

    const noNavPaths = ["/", "/signin", "/signup"];
    const showNav = !noNavPaths.includes(location.pathname);

    // Define the paths where the chatbot should be visible
    const chatbotPaths = [
        "/lessonslearned",
        "/log-lesson",
        "/view-lessons",
        "/ApproveLessons",
        "/lessonslearned-report"
    ];

    const showChatBot = chatbotPaths.includes(location.pathname);

    return (
        <UserContext.Provider value={userData}>
            <div className="App">
                {showNav && <Nav />}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/approval-pending" element={<ApprovalPending />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/user-guide" element={<UserGuide />} />
                    <Route path="/welcome" element={<Welcome />} />

                    <Route
                        path="/logrecords/:formId"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <LogRecords />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/createcustomform"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Customform />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/logrecords"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <LogRecords />
                            </CheckApproval>
                        }
                    /> <Route
                        path="/viewcustomforms"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Viewforms />
                            </CheckApproval>
                        }
                    /> <Route
                        path="/viewrecords"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Viewrecords />
                            </CheckApproval>
                        }
                    />
                    <Route path="/viewrecords/:formId" element={<Viewrecords />} />
                    <Route
                        path="/approvecustomrecords"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <ApproveCustomRecords />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/multilog"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <MultiLog />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/multiview"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <MultiView />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/dashboard"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Dashboard />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/recordlogging"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Recordlogging />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/employees"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <AddEmployees />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/equipment-maintenance"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <EquipmentMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/lessonslearned"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <FaultSolution />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/trainingmaterials"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Training />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/reports"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <Reports />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/maintenance-reports"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <MaintenanceReports />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/lessonslearned-report"
                        element={
                            <CheckApproval
                                userData={userData}
                                isApproved={isUserApproved}
                                isAuthenticated={isUserAuthenticated}
                            >
                                <LessonsLearntreport />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/add-equipment"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <AddEquipment />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/approve-users"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveUsers />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/approverecords"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveCustomRecords />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/view-maintenance-record"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ViewMaintenanceRecord />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/log-maintenance-record"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <LogMaintenanceRecord />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/schedule-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ScheduleMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/approve-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/rejected-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <RejectedMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/confirm-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ConfirmMaint />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/search-equipment"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <SearchEquipment />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/view-lessons"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ViewFaultRecord />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/log-lesson"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <LogFaultRecord />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/approve-records"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveRecords />
                            </CheckApproval>
                        }
                    />

                    <Route
                        path="/show-planned-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ShowPlannedMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/approve-scheduled-maintenance"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveScheduledMaintenance />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/ApproveLessons"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ApproveLessons />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/AddEquipTraining"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <AddEquipmentTraining />
                            </CheckApproval>
                        }
                    />
                    <Route
                        path="/Viewtrainingmat"
                        element={
                            <CheckApproval isApproved={isUserApproved} isAuthenticated={isUserAuthenticated}>
                                <ViewTrainingMaterial />
                            </CheckApproval>
                        }
                    />
                </Routes>
                {showChatBot && <ChatBot />}
            </div>
        </UserContext.Provider>
    );
}

export default App;
