import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import useRole from '../hooks/useRole';
import '..//..//Spinner.css';
import ReactGA4 from 'react-ga4';
import logoImage from '../../Nucleus Logo.svg';


const EquipmentMaintenance = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };

    const location = useLocation();

    useEffect(() => {
        ReactGA4.initialize('G-FGPF61TG81');
        ReactGA4.send('page_view'); // Record the initial pageview
    }, []);

    useEffect(() => {
        // Combine pathname and search into a single string
        const pagePath = `${location.pathname}${location.search}`;
        ReactGA4.send({ hitType: 'page_view', page_path: pagePath });
    }, [location.pathname, location.search]);



    const { role, hasRole, loading } = useRole();

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <Spinner animation="border" role="status" variant="success" className="loading-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }    

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
            <img src={logoImage} alt="Company Logo" style={logoStyle} />
            <h1 className="mb-5">Equipment Maintenance</h1>
            <Row xs={1} md={2} lg={3} className="g-4">
                
                <Col>
                    <Link to="/log-maintenance-record">
                        <Button variant="success" className="mb-3 w-100" title="Log maintenance record">
                            Log Maintenance Record
                        </Button>
                    </Link>
                </Col>
                
                <Col>
                    <Link to="/schedule-maintenance">
                        <Button variant="success" className="mb-3 w-100" title="Schedule maintenance">
                            Schedule Maintenance
                        </Button>
                    </Link>
                </Col>
                
                <Col>
                    <Link to="/show-planned-maintenance">
                        <Button variant="success" className="mb-3 w-100" title="Show planned maintenance">
                            View Planned Maintenance
                        </Button>
                    </Link>
                </Col>
                
                <Col>
                    <Link to="/view-maintenance-record">
                        <Button variant="success" className="mb-3 w-100" title="View maintenance record: Filter and view all maintenance records of a specific piece of equipment">
                            View Maintenance Record
                        </Button>
                    </Link>
                </Col>
                
                {role === 'Admin' && (<Col>
                    <Link to="/approve-Scheduled-maintenance">
                        <Button variant="info" className="mb-3 w-100" title="Approve scheduled maintenance">
                            Approve Scheduled Maintenance
                        </Button>
                    </Link>
                </Col>)}
                {role === 'Admin' && (<Col>
                    <Link to="/approve-maintenance">
                        <Button variant="info" className="mb-3 w-100" title="Approve maintenance">
                            Approve Maintenance
                        </Button>
                    </Link>
                </Col>)}
                <Col>
                    <Link to="/rejected-maintenance">
                        <Button variant="danger" className="mb-3 w-100" title="Rejected Maintenance records: Filter and view all maintenance records of a specific piece of equipment">
                            Rejected Maintenance Records
                        </Button>
                    </Link>
                </Col><Col>
                    <Link to="/confirm-maintenance">
                        <Button variant="warning" className="mb-3 w-100" title="Rejected Maintenance records: Filter and view all maintenance records of a specific piece of equipment">
                            Confirm Scheduled Maintenance 
                        </Button>
                    </Link>
                </Col>
                <Col>
                    <Link to="/maintenance-reports">
                        <Button variant="primary" className="mb-3 w-100">Maintenance report</Button>
                    </Link>
                </Col>
                <Col>
                    <Link to="/dashboard">
                        <Button variant="outline-success" className="mb-3 w-100" title="Back to dashboard">
                            Back to Dashboard
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default EquipmentMaintenance;
