import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Card, Button,Container } from 'react-bootstrap';
import { firestore } from './components/utils/firebase'; // Ensure you import Firestore from your Firebase utility file.
import { useNavigate, useLocation } from 'react-router-dom';
import './Profile.css';
import logoImage from './Nucleus Logo PPTX.png';


const Profile = () => {
    const logoStyle = {
        maxWidth: '200px', // Adjust the size as needed
        margin: '10px',
        padding: '0',
    };
    const [userData, setUserData] = useState(null);
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const navigate = useNavigate();


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userRef = doc(firestore, "users", userId);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error getting document:", error);
            }
        };

        fetchUserData();
    }, [userId]);

    return (
        <div className="profile-container" >
            <>
                <img src={logoImage} alt="Company Logo" style={logoStyle} />
            </>
            {userData && (
                <>
                    <Card style={{ width: '18rem' }} className="text-center">
                        <Card.Body>
                            <Card.Title>{userData.name} {userData.surname}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Role: {userData.role}</Card.Subtitle>
                            <Card.Subtitle className="mb-2 text-muted">Approved: {userData.approved ? 'Yes' : 'No'}</Card.Subtitle>

                            <Card.Text>
                                Email: {userData.email} <br />
                                Employee Number: {userData.employeeNumber} <br />
                                Company: {userData.company}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Button className="back-button" variant="success" onClick={() => navigate('/dashboard')}>
                        Back to Dashboard
                    </Button>
                </>
            )}
        </div>
    );
};


export default Profile;
