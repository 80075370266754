import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, collection, query, where, onSnapshot } from "firebase/firestore";
import { firestore } from '..//src/components/utils/firebase';
import { useNavigate, Link } from 'react-router-dom';
import './Navigation.css';
import { FiBell, FiMenu } from 'react-icons/fi';
import logoImage from './Nucleus Logo PPTX.png'
import useRole from './/components/hooks/useRole';

const logoStyle = {
    maxWidth: '40px', // Adjust the size as needed
    margin: '0px',
    padding: '0px',
};



const Navigation = () => {

    const auth = getAuth();
    const [user, setUser] = useState(auth.currentUser);
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const [unapprovedUsersCount, setUnapprovedUsersCount] = useState(0);
    const [maintenanceRecordsCount, setMaintenanceRecordsCount] = useState(0);
    const [CustomCount, setCustomCount] = useState(0);
    const [scheduledMaintenanceCount, setScheduledMaintenanceCount] = useState(0);
    const [lessonsLearntCount, setLessonsLearntCount] = useState(0);
    const [rejectedMaintenanceCount, setRejectedMaintenanceCount] = useState(0);
    const [ConfirmMaintenanceCount, setConfirmMaintenanceCount] = useState(0);
    const { role, hasRole, loading } = useRole();


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
            if (userAuth) {
                const userRef = doc(firestore, "users", userAuth.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUser({ ...userAuth, role: userDoc.data().role });
                } else {
                    setUser(userAuth);
                }
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        const fetchUnapprovedCounts = async () => {
            if (auth.currentUser) {
                const userSnapshot = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
                const company = userSnapshot.data().company;

                const companyRef = doc(firestore, 'companies', company);
                const maintenanceLogsRef = collection(companyRef, 'maintenanceLogs');
                const faultRecordsRef = collection(companyRef, 'faultRecords');
                const usersRef = collection(firestore, 'users');
                const customLogsRef = collection(companyRef, 'popCustomForms');

                const qMaintenance = query(maintenanceLogsRef, where('approved', '==', false), where('scheduled', '==', false), where('logmaintenance', '==', true), where('fullyApproved', '==', false));
                const qScheduledMaintenance = query(maintenanceLogsRef, where('approved', '==', false), where('scheduled', '==', true), where('rejected', '==', false), where('ScheduledApproved', '==', false))
                const qLessonsLearnt = query(faultRecordsRef, where('approved', '==', false));
                const qUsers = query(usersRef, where('approved', '==', false), where('company', '==', company));
                const qRejectedMaintenance = query(maintenanceLogsRef, where('rejected', '==', true));
                const qCustom = query(customLogsRef, where('approved', '==', false));
                const qConfirmMaintenance = query(maintenanceLogsRef, where('fullyApproved', '==', false), where('Confirmed', '==', false), where('ScheduledApproved', '==', true))


                onSnapshot(qMaintenance, snapshot => {
                    console.log(`Maintenance Records: ${snapshot.size}`);
                    setMaintenanceRecordsCount(snapshot.size);
                });
                onSnapshot(qScheduledMaintenance, snapshot => {
                    console.log(`Scheduled Maintenance Records: ${snapshot.size}`);
                    setScheduledMaintenanceCount(snapshot.size);
                });
                onSnapshot(qLessonsLearnt, snapshot => {
                    console.log(`Lessons Learnt Records: ${snapshot.size}`);
                    setLessonsLearntCount(snapshot.size);
                });
                onSnapshot(qUsers, snapshot => {
                    console.log(`Unapproved Users: ${snapshot.size}`);
                    setUnapprovedUsersCount(snapshot.size);
                });
                onSnapshot(qRejectedMaintenance, snapshot => {
                    console.log(`Rejected Maintenance Records: ${snapshot.size}`);
                    setRejectedMaintenanceCount(snapshot.size);
                });
                onSnapshot(qCustom, snapshot => {
                    console.log(`Custom Records: ${snapshot.size}`);
                    setCustomCount(snapshot.size);
                });
                onSnapshot(qConfirmMaintenance, snapshot => {
                    console.log(`Confirm Maintenance Records: ${snapshot.size}`);
                    setConfirmMaintenanceCount(snapshot.size);
                });
            }
        };
        fetchUnapprovedCounts();
    }, [auth.currentUser]);

    const handleSignOut = async () => {
        try
{
            await signOut(auth);
            navigate("/");
        } catch (error) {
            console.error("Error signing out", error);
        }
    };

    const totalNotifications =
        (role === 'Admin' && unapprovedUsersCount > 0 ? unapprovedUsersCount : 0) +
        (role === 'Admin' && maintenanceRecordsCount > 0 ? maintenanceRecordsCount : 0) +
        (role === 'Admin' && scheduledMaintenanceCount > 0 ? scheduledMaintenanceCount : 0) +
        (role === 'Admin' && lessonsLearntCount > 0 ? lessonsLearntCount : 0) +
        (role === 'Admin' && CustomCount > 0 ? CustomCount : 0) +
        (rejectedMaintenanceCount > 0 ? rejectedMaintenanceCount : 0) +
        (ConfirmMaintenanceCount > 0 ? ConfirmMaintenanceCount : 0);
;

    const handleLinkClick = () => {
        setExpanded(false);
    };
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar-custom"
            variant="dark"
            expanded={expanded}
        >
            <Navbar.Brand as={Link} to="/dashboard" className="nav-link-dash">
                <img src={logoImage} alt="Logo" style={logoStyle} />
            </Navbar.Brand>

            {totalNotifications > 0 && (
                <NavDropdown
                    title={
                        <div className="notification-icon-container">
                            <div className="notification-icon">
                                <FiBell />
                                <Badge variant="danger">{totalNotifications}</Badge>
                            </div>
                        </div>
                    }
                    renderMenuOnMount={true}
                    menuRole={'navigation'}
                    className={'dropdown-center'}
                >
                    {role === 'Admin' && unapprovedUsersCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/approve-users"
                            onClick={handleLinkClick}
                        >
                            You have {unapprovedUsersCount} User{unapprovedUsersCount > 1 ? 's' : ''} to approve
                        </NavDropdown.Item>
                    )}
                    {role === 'Admin' && maintenanceRecordsCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/approve-maintenance"
                            onClick={handleLinkClick}
                        >
                            You have {maintenanceRecordsCount} Maintenance Record{maintenanceRecordsCount > 1 ? 's' : ''} to approve
                        </NavDropdown.Item>
                    )}
                    {role === 'Admin' && scheduledMaintenanceCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/approve-scheduled-maintenance"
                            onClick={handleLinkClick}
                        >
                            You have {scheduledMaintenanceCount} Scheduled Maintenance Record{scheduledMaintenanceCount > 1 ? 's' : ''} to approve
                        </NavDropdown.Item>
                    )}
                    {role === 'Admin' && lessonsLearntCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/ApproveLessons"
                            onClick={handleLinkClick}
                        >
                            You have {lessonsLearntCount} Lesson{lessonsLearntCount > 1 ? 's' : ''} Learned to approve
                        </NavDropdown.Item>
                    )}
                    {role === 'Admin' && CustomCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/approverecords"
                            onClick={handleLinkClick}
                        >
                            You have {CustomCount} Record{CustomCount > 1 ? 's' : ''} to approve
                        </NavDropdown.Item>
                    )}
                    {rejectedMaintenanceCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/rejected-maintenance"
                            onClick={handleLinkClick}
                        >
                            You have {rejectedMaintenanceCount} Rejected Maintenance record{rejectedMaintenanceCount > 1 ? 's' : ''} to approve
                        </NavDropdown.Item>
                    )}
                    {ConfirmMaintenanceCount > 0 && (
                        <NavDropdown.Item
                            as={Link}
                            to="/confirm-maintenance"
                            onClick={handleLinkClick}
                        >
                            You have {ConfirmMaintenanceCount} Maintenance record{ConfirmMaintenanceCount > 1 ? 's' : ''} to confirm
                        </NavDropdown.Item>
                    )}
                </NavDropdown>
            )}

            <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setExpanded(!expanded)}
            />

            <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
            >
                <Nav className="mr-auto d-none d-lg-flex">
                    <Nav.Link
                        as={Link}
                        to="/equipment-maintenance"
                        className="nav-link-custom"
                        onClick={handleLinkClick}
                    >
                        Equipment Maintenance
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/lessonslearned"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        Lessons Learned
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/recordlogging"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        Record Logging
                    </Nav.Link>
                    {/*<Nav.Link
                        as={Link}
                        to="/user-guide"
                        className="nav-link-custom"
                        onClick={handleLinkClick}
                    >
                        User Guide
                    </Nav.Link>*/}

                    {user && (
                        <Nav.Link
                            as={Link}
                            to="/profile"
                            className="nav-link-custom"
                            onClick={handleLinkClick}
                        >
                            Logged in as: {user.displayName} ({user.email})
                        </Nav.Link>
                    )}

                    {user && (
                        <Nav.Link
                            className="nav-link-custom logout-custom"
                            onClick={handleSignOut}
                        >
                            Logout
                        </Nav.Link>
                    )}
                </Nav>

                {/* Mobile nav */}
                <Nav className="mr-auto d-lg-none">
                    <Nav.Link
                        as={Link}
                        to="/equipment-maintenance"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        Equipment Maintenance
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/lessonslearned"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        Lessons Learned
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/recordlogging"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        Record Logging
                    </Nav.Link>
                    {/*<Nav.Link
                        as={Link}
                        to="/user-guide"
                        className="nav-link-custom"
                        onClick={() => setExpanded(false)}
                    >
                        User Guide
                    </Nav.Link>*/}

                    {user && (
                        <Nav.Link
                            as={Link}
                            to="/profile"
                            className="nav-link-custom"
                            onClick={() => setExpanded(false)}
                        >
                            Logged in as: {user.displayName} ({user.email})
                        </Nav.Link>
                    )}

                    {user && (
                        <Nav.Link
                            className="nav-link-custom logout-custom"
                            onClick={() => {
                                setExpanded(false);
                                handleSignOut();
                            }}
                        >
                            Logout
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigation;