import React, { useState } from 'react';
import { Table, Container } from 'react-bootstrap';
import './UserGuide.css'; // Import the external stylesheet


const UserGuide = () => {
    const sections = [
        {
            title: "Signing Up to an existing company",
            body: [
                "1. Fill in all the required fields",
                "2. Select the company from the dropdown menu under company",
                "3. Enter the unique company code provided to your orginisation",
                "4. Your profile will be created and must then be approved by an Admin user of your company before you can start to use Nucleus."
            ]
        },
        {
            title: "Signing Up to a new company",
            body: [
                "1. Fill in all the required fields",
                "2. Select add new company under the company dropdown menu",
                "3. Enter the new company name",
            ]
        },
        {
            title: "Logging in",
            body: [
                "When you open the Nucleus app, you'll be taken to the login screen.",
                "Enter your username and password and click Login ."
            ]
        },
        {
            title: "Navigating the App",
            body: [
                "Once you're logged in, you'll be taken to Dashboard. The Dashboard displays a variety of Nucleus features.",
                "The navigation bar at the top of the screen allows you to move through the app. You can go to your profile, The Maintenance Page and the Lessons Learnt page."
            ]
        },
        {
            title: "Logging Maintenance",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Log Maintenance Record' where you will be taken to a form where you can enter the details of the maintenance.",
                "3. Fill in the equipment number, the maintenance performed, and any other required fields. Remember to include a scanned copy of the filled in Job Card or Photo of the Maintenance.",
                "4. Click the 'Log Maintenance' button to log the maintenance. The maintenance record will be pending approval by an admin."
            ]
        },
        {
            title: "Schedule Maintenance",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Schedule Maintenance' where you will be taken to a form where you can enter the details of the Scheduled maintenance.",
                "3. Fill in the equipment number, the maintenance performed, and any other required fields. The Nucleus App allows users to schedule Routine Maintenance on Daily, Weekly, Monthly, Bi-Annually and Yearly intervals.",
                "4. Click the 'Submit' button to Schedule the maintenance. The maintenance record will be pending approval by an admin."
            ]
        },
        {
            title: "Viewing Maintenance Records",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'View Maintenance Records'.",
                "3. Select the Equipment Number in Question.",
                "4. Click the 'Search' button which will show all approved maintenance records for the applicable equipment.",
                "5. You'll see a list of all approved maintenance records, organized by equipment number."
            ]
        },
        {
            title: "Viewing Planned Maintenance",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'View Planned Maintenance'.",
                "3. Select the date range for which you would like to view the maintenance activities planned",
                "4. Select the Equipment Number in question, should you .",
                "5. Click the 'Fetch Records' button which will show all approved planned maintenance records for the applicable equipment."
            ]
        },
        {
            title: "Rejected Maintenance Records",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Rejected Maintenance Records'.",
                "3. The reason for Rejection will be under the comment column.",
                "4. You are able to edit, resubmit or delete the rejected records. Remember to always fill in the date of the maintenance, even if it has not changed. "
            ]
        },
        {
            title: "To log a Lessons learnt",
            body: [
                "1. Navigate to the Lessons Learnt Page.",
                "2. Click on 'Log Lessons Learnt' where you will be taken to a form where you can enter the details of the lessons learnt.",
                "3. Fill in the equipment number, the Lesson Learnt, and any other required fields.",
                "4. Click the 'Log Lesson Learnt' button to log the record. The Lessons Learnt record will be pending approval by an admin."
            ]
        },
        {
            title: "View Lessons Learnt",
            body: [
                "1. Navigate to the Lessons Learnt Page.",
                "2. Click on 'View Lessons Learnt'.",
                "3. Select the Equipment Number in Question.",
                "4. Click the 'Search' button which will show all approved lessons learnt for the applicable equipment."
            ]
        },
        {
            title: "Add Equipment Training",
            body: [
                "1. Navigate to the Lessons Learnt Page.",
                "2. Click on 'Add Equipment Training Material'.",
                "3. Select the Equipment Number you wish to add equipment training material for",
                "4. Click the 'Submit' button."
            ]
        },
        {
            title: "View Lessons Learnt",
            body: [
                "1. Navigate to the Lessons Learnt Page.",
                "2. Click on 'View Training Material'.",
                "3. Select the Equipment Number in Question.",
                "4. Click the 'Search' button which will show all training material for the applicable equipment."
            ]
        },
    ]
        const sections1 = [
        {
            title1: "Approving pending maintenance records (Admin Users Only)",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Approve Maintenance'.",
                "3. You'll see a list of all pending maintenance records.",
                "4. Nucleus gives you the ability to view the supporting documents before approving maintenance records, ensuring maintenance records are auditable and accurate. Click the 'View' file button to view supporting files.",
                "5. To approve a record, press the 'Approve' button.",
                "6. To reject a record, click 'Reject', and remember to add a comment as to why you are rejecting the record, allowing users to amend the record before resubmitting.",
                "7. You can also approve all the records of an applicable equipment by clicking 'Approve All for this Equipment'"
            ]
        },
        {
            title1: "Approving scheduled maintenance records (Admin Users Only)",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Approve Scheduled Maintenance'.",
                "3. You'll see a list of all scheduled maintenance records.",
                "4. To approve a record, press the 'Approve' button.",
                "5. To delete a record, press the 'Approve' button.",
                "6. To reject a record, click 'Reject', and remember to add a comment as to why you are rejecting the record, allowing users to amend the record before resubmitting."
            ]
        },
        {
            title1: "Approving Lessons Learnt (Admin Users Only):",
            body: [
                "1.	Navigate to the Lessons Learnt Page",
                "2.	Click on Approve Lessons Learnt. You'll see a list of all pending Lessons Learnt records.",
                "3.	To approve a record, press the Approve button",
                "4.	To delete a record, click Delete, and remember to add a comment as to why you are rejecting the record, allowing users to amend the record before resubmitting ",
                "5.	You can also approve all the records of an applicable equipment by clicking Approve All for this Equipment",
            ]
        },
            {
                title1: "Approving Users (Admin Users Only)",
                body: [
                    "To ensure your company's information is secure, Nucleus allows access to its functionality only after a secondary user has been approved by an Admin user'.",
                    "1. Navigate to the Dashboard,",
                    "2. Click on Approve Users.",
                    "3. You'll see a list of all secondary users which need to be approved.",
                    "4. To approve a new user, press the 'Approve' button.",
                    "5. To reject a new user, press the 'Reject' button.",
                ]
            },
    ];
    const sections2 = [
        {
            title2: "View Maintenance report",
            body: [
                "1. Navigate to the Equipment Maintenance Page.",
                "2. Click on 'Maintenance Report'.",
                "3. Here you will see key approved Maintenance metrics.",
                "4. Nucleus gives you the ability to filter the maintenance report based on a date range of your choosing",
                "5. To print the report press 'Print report",
            ]
        },
        {
            title2: "View Lessons Learnt report", 
            body: [
                "1. Navigate to the Lessons Learnt Page.",
                "2. Click on 'Maintenance Report'.",
                "3. Here you will see key lessons learnt metrics.",
                "4. Nucleus gives you the ability to filter the lessons learnt report based on a date range of your choosing",
                "5. To print the report press 'Print report",
            ]
        },
    ];
    const [openSectionIndex, setOpenSectionIndex] = useState(null);
    const [openSectionIndex1, setOpenSectionIndex1] = useState(null);
    const [openSectionIndex2, setOpenSectionIndex2] = useState(null);

    const toggleSection = (index, sectionNumber) => {
        if (sectionNumber === 1) {
            setOpenSectionIndex(openSectionIndex === index ? null : index);
        } else if (sectionNumber === 2) {
            setOpenSectionIndex1(openSectionIndex1 === index ? null : index);
        } else if (sectionNumber === 3) {
            setOpenSectionIndex2(openSectionIndex2 === index ? null : index);
        }
    }

    return (
        <Container style={{ width: '95%' }}>
            <h1>Nucleus User Guide</h1>
            <Table striped bordered hover style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <tbody>
                    {sections.map((section, index) => (
                        
                        <React.Fragment key={index} style={{ borderRadius: '10px'}} >
                            <tr onClick={() => toggleSection(index, 1)} style={{ cursor: 'pointer', backgroundColor: '#188754'}}>
                                    <td style={{ color: 'white' }}><b>{section.title}</b></td>

                                </tr>
                                {openSectionIndex === index && (
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                        <td>
                                            {section.body.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                    ))}
                </tbody>
            </Table>
            <Table striped bordered hover style={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <tbody>
                        {sections1.map((section, index) => (
                            <React.Fragment key={index}>
                                <tr onClick={() => toggleSection(index, 2)} style={{ cursor: 'pointer', backgroundColor: '#5bc0de' }}>
                                    <td style={{ color: 'white' }}><b>{section.title1}</b></td>

                                </tr>
                                {openSectionIndex1 === index && (
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                        <td>
                                            {section.body.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
            </Table>
            <Table striped bordered hover style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <tbody>
                    {sections2.map((section, index) => (
                        <React.Fragment key={index}>
                            <tr onClick={() => toggleSection(index, 3)} style={{ cursor: 'pointer', backgroundColor: '#0275d8' }}>
                                <td style={{ color: 'white' }}><b>{section.title2}</b></td>

                            </tr>
                            {openSectionIndex2 === index && (
                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                    <td>
                                        {section.body.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>

        </Container>
    );
}

export default UserGuide;
