import React, { useState, useEffect } from 'react';
import { Button, Form, Container, InputGroup, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, doc, getDoc, setDoc } from 'firebase/firestore'; // Updated imports
import { firestore } from '../utils/firebase';
import { getAuth } from 'firebase/auth';

const MultiLog = () => {
    const [fields, setFields] = useState([{ name: '', type: 'text' }]);
    const navigate = useNavigate();
    const [userCompany, setUserCompany] = useState('');
    const [description, setDescription] = useState(''); // Separate state for the description

    useEffect(() => {
        const fetchUserCompany = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) return;
            const userRef = doc(firestore, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                setUserCompany(userSnap.data().company);
            }
        };

        fetchUserCompany();
    }, []);

    const addField = () => {
        setFields([...fields, { name: '', type: 'text' }]);
    };

    const handleFieldNameChange = (index, value) => {
        const newFields = [...fields];
        newFields[index].name = value;
        setFields(newFields);
    };

    const handleFieldTypeChange = (index, value) => {
        const newFields = [...fields];
        newFields[index].type = value;
        setFields(newFields);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value.slice(0, 20)); // Limit description to 20 characters
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {};
        fields.forEach((field, index) => {
            if (field.name !== 'description') { // Exclude the initial description field
                formData[field.name] = e.target[field.name].value;
            }
        });

        formData.description = description; // Ensure description is included in formData

        if (userCompany) {
            const multilogQuery = query(collection(firestore, `companies/${userCompany}/multilog`));
            const querySnapshot = await getDocs(multilogQuery);
            let maxRecordNumber = 0;

            querySnapshot.forEach((docSnapshot) => {
                const recordNumber = parseInt(docSnapshot.id.replace(/[^\d]/g, ''), 10);
                if (recordNumber > maxRecordNumber) {
                    maxRecordNumber = recordNumber;
                }
            });

            const nextRecordNumber = maxRecordNumber + 1;
            const fullRecordName = `${description}-${nextRecordNumber}`; // Construct record name with description and number

            await setDoc(doc(firestore, `companies/${userCompany}/multilog`, fullRecordName), formData);

            navigate('/multiview'); // Navigate to view records
        }
    };


    return (
        <Container>
            <h2>Log Record</h2>
            <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Description (max 20 characters)"
                        maxLength="20"
                        value={description}
                        onChange={handleDescriptionChange}
                        required
                    />
                </InputGroup>

                {fields.map((field, index) => (
                    <InputGroup className="mb-3" key={index}>
                        <FormControl
                            placeholder="Field Name"
                            aria-label="Field Name"
                            value={field.name}
                            onChange={(e) => handleFieldNameChange(index, e.target.value)}
                            required
                        />
                        <Form.Select
                            aria-label="Field Type"
                            value={field.type}
                            onChange={(e) => handleFieldTypeChange(index, e.target.value)}
                            required
                        >
                            <option value="text">Text</option>
                            <option value="file">File</option>
                        </Form.Select>
                        {field.type === 'text' ? (
                            <FormControl
                                name={field.name}
                                placeholder="Field Value"
                                aria-label="Field Value"
                                required
                            />
                        ) : (
                            <Form.Control
                                type="file"
                                name={field.name}
                                required
                            />
                        )}
                    </InputGroup>
                ))}
                <Button variant="primary" onClick={addField}>Add Field</Button>
                <Button variant="success" type="submit" className="ms-2">Submit</Button>
            </Form>
        </Container>
    );
};

export default MultiLog;
