// ViewFaultRecord.js
import React, { useState, useEffect } from 'react';
import { firestore } from '../utils/firebase';
import { Table, Button, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import useRole from '../hooks/useRole';
import { getAuth } from 'firebase/auth';
import './ViewFaultRecord.css';


const ViewFaultRecord = () => {
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [records, setRecords] = useState([]);
    const [equipmentList, setEquipmentList] = useState([]);
    const navigate = useNavigate();
    const role = useRole();

    const [filters, setFilters] = useState({
        equipmentNumber: '',
        fault: '',
        solution: '',
        vendor: '',
        vendorContactNumber: '',
        vendorContactEmail: '',
        approved: ''
    });

    const [showSearch, setShowSearch] = useState({
        equipmentNumber: false,
        fault: false,
        solution: false,
        vendor: false,
        vendorContactNumber: false,
        vendorContactEmail: false,
        approved: false
    });

    const fetchFaultRecords = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const companyRef = firestore.collection('companies').doc(company);
        const faultRecordsRef = companyRef.collection('faultRecords');

        try {
            const snapshot = await faultRecordsRef
                .where('equipmentNumber', '==', equipmentNumber)
                .where('approved', '==', true)
                .get();
            const fetchedRecords = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setRecords(fetchedRecords);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchEquipmentNumbers = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userSnapshot = await firestore.collection('users').doc(currentUser.uid).get();
        const company = userSnapshot.data().company;

        const faultRecordsRef = firestore.collection('companies').doc(company).collection('faultRecords');

        try {
            const snapshot = await faultRecordsRef.get();
            const uniqueEquipments = new Set(snapshot.docs.map(doc => doc.data().equipmentNumber));
            const fetchedEquipment = Array.from(uniqueEquipments).map(equipmentNumber => ({ value: equipmentNumber, label: equipmentNumber }));
            setEquipmentList(fetchedEquipment);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEquipmentNumbers();
    }, []);


    const handleSelectChange = (selectedOption) => {
        setEquipmentNumber(selectedOption.value);
    };

    const filteredRecords = records.filter(record =>
        Object.keys(filters).every(key =>
            record[key].toString().toLowerCase().includes(filters[key].toLowerCase())
        )
    );

    const columns = [
        { key: 'equipmentNumber', title: 'Equipment Number' },
        { key: 'fault', title: 'Fault' },
        { key: 'solution', title: 'Lesson Learned' },
        { key: 'vendor', title: 'Vendor Used' },
        { key: 'vendorContactNumber', title: 'Vendor Contact Number' },
        { key: 'vendorContactEmail', title: 'Vendor Contact Email' },
        { key: 'approved', title: 'Approved' },
    ];
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Close all search boxes if clicked outside
            if (!event.target.closest('th')) {
                setShowSearch({
                    equipmentNumber: false,
                    fault: false,
                    solution: false,
                    vendor: false,
                    vendorContactNumber: false,
                    vendorContactEmail: false,
                    approved: false
                });
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);


    return (

        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '40vh' }}>
            <h1 className="mb-5">View Lesson Learned</h1>

            <Row>
                <Col xs={12} md={8} >
                    <Select
                        options={equipmentList}
                        value={equipmentList.find(option => option.value === equipmentNumber)}
                        onChange={handleSelectChange}
                        placeholder="Select Equipment Number"
                        isSearchable
                    />
                </Col>
                <Col xs={12} md={4} >
                    <Button variant="success"
                    className="Search-but"
                        style={{
                            width: '150px',
                        }}
                        onClick={fetchFaultRecords}
                    >
                        Search
                    </Button>

                </Col>
            </Row>
            <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
            <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.key}>
                                    <div className="table-header">
                                        <span style={{ opacity: showSearch[column.key] ? 0 : 1 }}>
                                            {column.title}
                                        </span>
                                        <i
                                            className="fas fa-search"
                                            onClick={() => setShowSearch({ ...showSearch, [column.key]: !showSearch[column.key] })}
                                        />
                                        {showSearch[column.key] && (
                                            <input
                                                type="text"
                                                value={filters[column.key]}
                                                onChange={(e) => setFilters({ ...filters, [column.key]: e.target.value })}
                                                placeholder={`Filter by ${column.title}`}
                                                className="table-search-input"
                                            />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredRecords.map((record, index) => (
                            <tr key={index}>
                                <td>{record.equipmentNumber}</td>
                                <td>{record.fault}</td>
                                <td>{record.solution}</td>
                                <td>{record.vendor}</td>
                                <td>{record.vendorContactNumber}</td>
                                <td>{record.vendorContactEmail}</td>
                                <td>{record.approved ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Button variant="outline-success" onClick={() => navigate('/lessonslearned')} className="mt-4">
                Back to lessons Learned
            </Button>
        </Container>
    );
};

export default ViewFaultRecord;
